import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
  TextInput,
} from '@gsa/afp-component-library';
import { emDashUnicode } from '@gsa/afp-shared-form-utils';
import { useDispatch, useSelector } from 'react-redux';
import { useGetVehicleReferralList } from '../../requests/vehicle-referral';
import {
  setVehicleReferralList,
  setPaginationState,
} from '../../reducers/vehicle-referral';
import ReferralRequestStatusBadge from './referral-request-status-badge';
import {
  ReferralAction,
  VehicleReferralSortMapping,
} from './utils/vehicle-referral-util';

const tableRef = React.createRef();

const VehicleReferralTable = () => {
  const dispatch = useDispatch();
  const { vehicleReferralList, vehicleReferralFilters, paginationState } =
    useSelector((state) => state.vehicleReferralReducer);
  const [getVehicleReferralList, { loading: vehicleReferralListLoading }] =
    useGetVehicleReferralList();
  const [order, setOrder] = useState(
    paginationState.order || [['tag_number', 'ASC']],
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  // apply backend sort only when fetching the initial time
  const modifiedOrder = useMemo(() => {
    if (!paginationState?.order) return [['tag_number', 'ASC']];
    const [sortColumnName, sortIndicator] = paginationState.order.split(' ');
    const sortKey =
      VehicleReferralSortMapping[sortColumnName.trim().replace(/`/g, '')];
    return [[sortKey, sortIndicator]];
  }, [paginationState?.order]);

  // Fetch data from backend only when filters change
  useEffect(() => {
    if (vehicleReferralFilters.length === 0) {
      setFilteredData([]);
    } else {
      getVehicleReferralList({
        variables: {
          limit: paginationState.limit,
          offset: paginationState.offset,
          order: modifiedOrder,
          filters: {
            conditions: vehicleReferralFilters,
            operator: '$and',
          },
        },
      }).then(({ data }) => {
        dispatch(setVehicleReferralList(data));
        setFilteredData(data?.rows);
      });
    }
  }, [vehicleReferralFilters]);

  // Handle global search
  useEffect(() => {
    if (!vehicleReferralList?.rows) return;

    if (globalFilter === '') {
      setFilteredData(vehicleReferralList.rows);
      return;
    }

    const searchTerm = globalFilter.toLowerCase();
    const columnsToInclude = [
      'tagNumber',
      'boac',
      'oldSIN',
      'newSIN',
      'lawEnforcementDesignation',
      'mileage',
    ];

    const searchResults = vehicleReferralList.rows.filter((row) =>
      columnsToInclude.some((key) => {
        const value = row[key];
        return value && value.toString().toLowerCase().includes(searchTerm);
      }),
    );

    setFilteredData(searchResults);
    handlePaginationChange(1, paginationState.itemsPerPage);
  }, [globalFilter, vehicleReferralList]);

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'License plate number',
        accessor: 'tagNumber',
        sortable: true,
        Cell: ({ value, row: { original } }) => (
          <Link
            to={`/lease/vehicle-referral/${encodeURIComponent(
              original.assetId,
            )}`}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'BOAC',
        accessor: 'boac',
        sortable: true,
      },
      {
        Header: 'Old SIN',
        accessor: 'oldSIN',
        sortable: true,
      },
      {
        Header: 'New SIN',
        accessor: 'newSIN',
        sortable: true,
      },
      {
        Header: 'Vehicle odometer',
        accessor: 'mileage',
        sortable: true,
      },
      {
        Header: 'Law enforcement designation',
        accessor: 'lawEnforcementDesignation',
        sortable: true,
      },
      {
        Header: 'Selection type',
        accessor: 'selectionType',
        sortable: true,
        Cell: ({ row: { original } }) => {
          return original?.selectionType ? (
            <span>{ReferralAction[original.selectionType]}</span>
          ) : (
            emDashUnicode
          );
        },
      },
      {
        Header: 'Request status',
        accessor: 'referralStatus',
        sortable: true,
        Cell: ({ row: { original } }) => {
          return original?.referralStatus ? (
            <ReferralRequestStatusBadge
              requestStatus={original.referralStatus}
            />
          ) : (
            emDashUnicode
          );
        },
      },
    ];

    return columnList;
  }, []);

  const paginatedData = useMemo(() => {
    const { currentPage, itemsPerPage } = paginationState;
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return {
      rows: filteredData.slice(start, end),
      count: filteredData.length,
    };
  }, [filteredData, paginationState]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    dispatch(
      setPaginationState({
        ...paginationState,
        currentPage,
        itemsPerPage,
      }),
    );
  };

  const sortDataByColumn = (data, sortValue) => {
    const updatedSortValue = sortValue.split(' ');
    if (!updatedSortValue?.length) return;

    const sortColumnName = updatedSortValue[0].trim().replace(/`/g, '');
    const sortIndicator = updatedSortValue[1];

    const sortedData = [...data].sort((a, b) => {
      const aValue = a[sortColumnName] || '';
      const bValue = b[sortColumnName] || '';

      if (sortColumnName === 'mileage') {
        const aMileage = aValue || 0;
        const bMileage = bValue || 0;
        return sortIndicator === 'ASC'
          ? aMileage - bMileage
          : bMileage - aMileage;
      }

      return sortIndicator === 'ASC'
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });
    return sortedData;
  };

  const onSort = (sortValue) => {
    const sortedData = sortDataByColumn(filteredData, sortValue);
    dispatch(
      setPaginationState({
        ...paginationState,
        order: sortValue,
      }),
    );
    setOrder(sortValue);
    setFilteredData(sortedData);
  };

  return (
    <div className="padding-top-5">
      <div className="grid-row">
        <div className="tablet:grid-col-4 margin-left-auto">
          <TextInput
            type="text"
            onChange={(e) => setGlobalFilter(e.target.value)}
            value={globalFilter}
            name="table-search"
            placeholder="Search within results"
            data-testid="referral-table-search"
            inputClass="description-textarea"
          />
        </div>
      </div>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="vehicle-referrals-listing-table"
        columns={columns}
        defaultSort={order}
        onSort={onSort}
        data={!vehicleReferralListLoading ? paginatedData.rows : []}
      />
      {vehicleReferralListLoading && <Spinner className="padding-y-9" />}
      {paginatedData.rows.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10,15,20,25,50,100]}
          itemsCount={filteredData.length}
          itemsPerPage={paginationState.itemsPerPage}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {!paginatedData.rows.length && !vehicleReferralListLoading && (
        <div className="bg-gray-3 padding-y-5">
          <div className="text-center padding-y-4">
            <EmptyState alt="Image not available" hasBackground />
          </div>
          <div
            className="text-center text-bold"
            data-testid="vehicles-referrals-no-data"
          >
            No vehicle referrals available
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleReferralTable;
