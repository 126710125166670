import _ from 'lodash';
import { validateAddressFields } from '../pages/VehicleRequisition/utils/VehicleRequisitionUtils';

export const validateAddress = (
  addressContext,
  actionType = null,
  dispatch = null,
  isExport = false,
) => {
  let errorsToUpdate = [];

  // FOR REQUISITION ADDRESS
  let addressKey = Object.keys(addressContext);
  let forDeletion = [];
  if (addressContext.isMilitary) {
    forDeletion = [
      'countryCode',
      'city',
      'stateCode',
      'addressLine1',
      'firstName',
      'lastName',
    ];
  } else if (!addressContext.isMilitary) {
    forDeletion = ['entityName', 'rankAndFullName', 'postOffice'];
  }

  addressKey = addressKey.filter((item) => !forDeletion.includes(item));

  const internationalAddress = addressContext?.countryCode && addressContext?.countryCode !== 'US';

  addressKey.forEach((key) => {
    const { errors } = validateAddressFields(
        key,
        addressContext[key],
        true,
        isExport,
        internationalAddress,
        addressContext.phoneCountryCode,
        addressContext.isMilitary,
    );
    errorsToUpdate = errorsToUpdate.concat(errors);
  });
  // commented out temporarily because this was causing errors to not be cleared
  // off the ui
  // errorsToUpdate = errorsToUpdate.filter((i) => i.text !== false);

  if (actionType && dispatch) {
    dispatch({
      type: actionType,
      payload: errorsToUpdate,
    });
  }
  return errorsToUpdate;
};

export const updateCurrentAddressState = (
  receivedState,
  updateDraftRequisition,
  setCountrySelected,
  selectedField,
  requisitionDraftId,
  requisitionHasError,
  enteredText,
) => {
  const currentState = receivedState;

  if (selectedField === 'countryCode') {
    currentState.countryCode = enteredText;
    setCountrySelected(currentState.countryCode);
    if (requisitionDraftId && requisitionHasError.length === 0) {
      updateDraftRequisition(requisitionDraftId);
    }
  } else if (selectedField === 'addressLine1') {
    currentState.addressLine1 = enteredText;
  } else if (selectedField === 'addressLine2') {
    currentState.addressLine2 = enteredText;
  } else if (selectedField === 'city') {
    currentState.city = enteredText;
  } else if (selectedField === 'stateCode') {
    currentState.stateCode = enteredText;
  } else if (selectedField === 'zipcode') {
    currentState.zipcode = enteredText;
  } else if (selectedField === 'zipcodePlusFour') {
    currentState.zipcodePlusFour = enteredText;
  } else if (selectedField === 'firstName') {
    currentState.firstName = enteredText;
  } else if (selectedField === 'lastName') {
    currentState.lastName = enteredText;
  } else if (selectedField === 'email') {
    currentState.email = enteredText;
  } else if (selectedField === 'phoneCountryCode') {
    currentState.phoneCountryCode = enteredText;
  } else if (selectedField === 'phoneNumber') {
    currentState.phoneNumber = enteredText;
  } else if (selectedField === 'phoneExtension') {
    currentState.phoneExtension = enteredText;
  } else if (selectedField === 'faxCountryCode') {
    currentState.faxCountryCode = enteredText;
  } else if (selectedField === 'faxNumber') {
    currentState.faxNumber = enteredText;
  } else if (selectedField === 'faxExtension') {
    currentState.faxExtension = enteredText;
  } else if (selectedField === 'entityName') {
    currentState.entityName = enteredText;
  } else if (selectedField === 'postOffice') {
    currentState.postOffice = enteredText;
  } else if (selectedField === 'militaryOrDiplomaticAddress') {
    currentState.militaryOrDiplomaticAddress = enteredText;
  } else if (selectedField === 'rankAndFullName') {
    currentState.rankAndFullName = enteredText;
  } else if (selectedField === 'isDealershipDelivery') {
    currentState.isDealershipDelivery = enteredText;
  } else if (selectedField === 'dealerId') {
    currentState.deliveryId = parseInt(enteredText, 10);
  }

  return currentState;
};

export const validateSelection = (selAddress, validatedAddress) => {
  let updatedWithChecks = validatedAddress;
  if (selAddress === 'USPS') {
    updatedWithChecks = { ...updatedWithChecks, isChecked: true };
  } else {
    updatedWithChecks = { ...updatedWithChecks, isChecked: false };
  }

  return updatedWithChecks;
};

export const getStateCodeOnly = (countryState) => {
  return countryState?.slice(countryState?.indexOf('-') + 1);
};

const matchableAddressKeys = ['addressLine1', 'city', 'stateCode', 'zipcode']; // ignoring address2 since it is not shown in the modal
export const addressesMatch = (addressA, addressB) =>
  _.isEqual(
    _.pick(
      { ...addressA, state: getStateCodeOnly(addressA?.stateCode) },
      matchableAddressKeys,
    ),
    _.pick(
      { ...addressB, state: getStateCodeOnly(addressB?.stateCode) },
      matchableAddressKeys,
    ),
  );

// We need to have same comparable keys since the structure in placed initially does not match
// https://stackoverflow.com/a/50101979
export const adaptKeys = (validAddress) => {
  const newObj = {};
  delete Object.assign(newObj, validAddress, {
    addressLine1: validAddress?.address,
  }).address;
  delete Object.assign(newObj, validAddress, { zipcode: validAddress?.zip })
    .zipcode;
  return newObj;
};

export const countriesListValidation = (data) => {
  const domesticOptions = ['US', 'PR', 'GU', 'VI', 'MP', 'AS'];
  // data filtered for US States and Territories
  const filteredCountriesData = data.filter((item) => {
    const checkTerritories = domesticOptions.find((li) => {
      return item.value === li;
    });
    if (!checkTerritories) {
      return item;
    }
    return null;
  });

  return filteredCountriesData;
};
