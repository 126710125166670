import z from 'zod';

export const marshallingAddressSchema = z.object({
    marshallingVendorId: z.number().optional(),
    zone: z.string().optional(),
    state: z.string().optional(),
    location: z.coerce.number().gt(0, "Please select a marshalling location"),
    marshallingAddress: z.object({
        location: z.string().optional(),
        deliveryAddress: z.object({
            title: z.string().nullish(),
            address1: z.string().nullish(),
            address2: z.string().nullish(),
            address3: z.string().nullish(),
            city: z.string().nullish(),
            state: z.string().nullish(),
            zip: z.string().nullish(),
            country: z.string().nullish(),
        }),
        pointOfContact: z.object({
            firstName: z.string().nullish(),
            lastName: z.string().nullish(),
            email: z.string().nullish(),
            phone: z.object({
                countryCode: z.string().nullish(),
                number: z.coerce.string().nullish(),
                extension: z.string().nullish(),
            })
        }),
        telematicsInstaller: z.coerce.boolean().nullish(),
        acceptingElectricVehicles: z.coerce.boolean().nullish(),
    }),
    licenseDelivery: z.string().nullish(),
    fmcLocation: z.coerce.number().optional(),
    fieldOffice: z.string().optional(),
    fmcOfficeLocationDetails: z.object({
        location: z.string().nullish(),
        deliveryAddress: z.object({
            address1: z.string().nullish(),
            address2: z.string().nullish(),
            address3: z.string().nullish(),
            city: z.string().nullish(),
            state: z.string().nullish(),
            zip: z.string().nullish(),
            country: z.string().nullish(),
        }).optional(),
        fieldOffice: z.string().optional(),
        fieldOfficeDeliveryAddress: z.object({
            address1: z.string().nullish(),
            address2: z.string().nullish(),
            address3: z.string().nullish(),
            city: z.string().nullish(),
            state: z.string().nullish(),
            zip: z.string().nullish(),
        }).optional(),
    }),
    licenseDeliverPoC: z.object({
        firstName: z.string().optional(),
        lastName: z.string().optional(),
        email: z.string().optional(),
        phone: z.object({
            countryCode: z.string().optional(),
            number: z.string().optional(),
            extension: z.string().optional(),
        }),
        fax: z.object({
            countryCode: z.string().optional(),
            number: z.string().optional(),
            extension: z.string().optional(),
        })
    })
}).superRefine((data, ctx) => {
    if(data.marshallingAddress.deliveryAddress.country === 'US' || data.marshallingAddress.deliveryAddress.country === 'PR'){
    if(!data.licenseDelivery){
        ctx.addIssue({
            message: 'Please select one address',
            code: z.ZodIssueCode.custom,
            path: ['licenseDelivery']
        })
    }
    if (data.licenseDelivery === 'ship') {
        if (!data.licenseDeliverPoC.firstName) {
            ctx.addIssue({
                message: 'First name is required',
                code: z.ZodIssueCode.custom,
                path: ['licenseDeliverPoC', 'firstName']
            })
        }

        if (!data.licenseDeliverPoC.lastName) {
            ctx.addIssue({
                message: 'Last name is required',
                code: z.ZodIssueCode.custom,
                path: ['licenseDeliverPoC', 'lastName']
            })
        }

        if (!data.licenseDeliverPoC.email) {
            ctx.addIssue({
                message: 'Email is required',
                code: z.ZodIssueCode.custom,
                path: ['licenseDeliverPoC', 'email']
            })
        } else if (!data.licenseDeliverPoC.email.match(
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )) {
            ctx.addIssue({
                message: 'Email must be a valid email address',
                code: z.ZodIssueCode.custom,
                path: ['licenseDeliverPoC', 'email']
            })
        }

        if (!data.licenseDeliverPoC.phone.countryCode) {
            ctx.addIssue({
                message: 'Country code is required',
                code: z.ZodIssueCode.custom,
                path: ['licenseDeliverPoC', 'phone', 'countryCode']
            });
        }

        if (!data.licenseDeliverPoC.phone.number) {
            ctx.addIssue({
                message: 'Phone number is required',
                code: z.ZodIssueCode.custom,
                path: ['licenseDeliverPoC', 'phone', 'number']
            });
        }
    }
}
})