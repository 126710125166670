export const buildSelectedEngine = (requisition) => {
  if (!requisition?.vehicles?.length) {
    return null;
  }
  const vehicle = requisition.vehicles[0];
  return {
    fuelType: vehicle.fuelType,
    fuelTypeText: vehicle.fuelTypeText,
    convMpgCity: vehicle.convMpgCity,
    convMpgHighway: vehicle.convMpgHighway,
    convMpgCombined: vehicle.convMpgCombined,
    convGpm: vehicle.convGpm,
    altMpgCity: vehicle.altMpgCity,
    altMpgHighway: vehicle.altMpgHighway,
    altMpgCombined: vehicle.altMpgCombined,
    altGpm: vehicle.altGpm,
    elecMpgCity: vehicle.elecMpgCity,
    elecMpgHighway: vehicle.elecMpgHighway,
    elecMpgCombined: vehicle.elecMpgCombined,
    isLowGreenhouseGas: vehicle.isLowGreenhouseGas,
  }
}