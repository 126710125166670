import React, { useState, useMemo } from 'react';
import { Modal, Button } from "@gsa/afp-component-library";
import { GiCheckMark } from 'react-icons/gi';
import { useDispatch, useSelector } from "react-redux";
import CheckedSVG from '../../../../../assets/images/conflict-checked.svg';
import './conflicts-modal.scss';
import { formatCurrency } from "../../../../../utilities/CompareVehicleUtils.jsx";
import { cloneDeep } from "lodash";
import { setResolvedOptions } from "../../../../../reducers/leasing.jsx";
import { connectModal } from "@gsa/afp-component-library";

const RequiredConflictOptions = ({
    item,
    radioHandler,
    selectedOptionItem,
    requiredOptions,
    allVendorExcludedOptions,
    allVendorIncludedOptions,
}) => {
    const [selectedRequiredOption, setSelectedRequiredOption] = useState(
        item.affectedRequiredOptions.length === 1
            ? item.affectedRequiredOptions[0]
            : null,
    );
    const handleRequiredSelection = (
        currentOption,
        requiredOption,
        newSelectedOptionItem,
        index,
    ) => {
        radioHandler(currentOption, requiredOption, newSelectedOptionItem);
        setSelectedRequiredOption(currentOption.affectedRequiredOptions[index]);
    };
    const collisionLabel = (requiredOption) => {
        let collidedOptionsLabel = '';
        requiredOption.collidedOptions.forEach((option, index, arr) => {
            collidedOptionsLabel = `${collidedOptionsLabel} ${option.optionCode} - ${option.optionDescription
                }${requiredOptions.indexOf(option.optionCode) > -1 ? '^' : ''} ${arr.length - 1 === index ? '' : '&'
                }`;
        });
        return collidedOptionsLabel;
    };

    return (
        <>
            <div className="required-options">
                <div className="required-section text-base-light">
                    <div className="required-title">+ REQUIRED</div>
                </div>
                {item.affectedRequiredOptions.length > 1 && (
                    <div className="required-sub-title">
                        Choose between the following required options:
                    </div>
                )}
                {item.affectedRequiredOptions.map((requiredOption, index) => {
                    const collidedOptionsLabel = collisionLabel(requiredOption);
                    const modifiedReqdOption = cloneDeep(requiredOption);
                    const checkCondition =
                      item?.affectedRequiredOptions?.length === 1 ||
                      JSON.stringify(requiredOption) ===
                        JSON.stringify(selectedRequiredOption);
                    return (
                        <>
                            <div className="required-options-list" key={`requiredOption-${item.contractLineId}-${modifiedReqdOption.optionCode}-${index}`}>
                                <span className="conflict-radio">
                                    <input
                                        data-testid="radio-conflict-input"
                                        type="radio"
                                        className="radio-button"
                                        checked={checkCondition}
                                        name={item.contractLineId}
                                        onChange={() => {
                                            modifiedReqdOption.isChecked = !modifiedReqdOption.isChecked;
                                            handleRequiredSelection(
                                                item,
                                                modifiedReqdOption,
                                                selectedOptionItem,
                                                index,
                                            )
                                        }
                                        }
                                    />
                                </span>

                                <span className="conflict-option-list">
                                    {collidedOptionsLabel}
                                </span>
                                <span>
                                    {formatCurrency(modifiedReqdOption.requiredOptionPrice)}
                                </span>
                            </div>
                        </>
                    );
                })}
                {selectedRequiredOption &&
                    (selectedRequiredOption.includedCollidedOptions.length > 0 ||
                        selectedRequiredOption.excludedCollidedOptions.length > 0) && (
                        <div className="modal-body-req-sub-title">
                            Based on your selection of{' '}
                            <span className="selected-option">
                                {collisionLabel(selectedRequiredOption)}
                            </span>
                            , this vendor <span className="include">INCLUDES</span> and/or{' '}
                            <span className="exclude">EXCLUDES</span> additional options
                            below:
                        </div>
                    )}
                {selectedRequiredOption &&
                    selectedRequiredOption.includedCollidedOptions &&
                    selectedRequiredOption.includedCollidedOptions.length !== 0 && (
                        <div className="included-options">
                            <div className="includes-section">
                                <div className="include-title">+ INCLUDES</div>
                            </div>
                            {selectedRequiredOption.includedCollidedOptions.map(
                                (affectedInOption) => {
                                    return (
                                        <li className="option-description" key={affectedInOption.optionCode}>
                                            {affectedInOption.optionCode} -{' '}
                                            {affectedInOption.optionDescription}
                                            {allVendorIncludedOptions.indexOf(
                                                affectedInOption.optionCode,
                                            ) > -1
                                                ? '^'
                                                : ''}
                                        </li>
                                    );
                                },
                            )}
                        </div>
                    )}
                {selectedRequiredOption &&
                    selectedRequiredOption.excludedCollidedOptions &&
                    selectedRequiredOption.excludedCollidedOptions.length !== 0 && (
                        <div className="excluded-options">
                            <div className="excludes-section">
                                <div className="exclude-title">– EXCLUDES</div>
                            </div>
                            {selectedRequiredOption.excludedCollidedOptions.map(
                                (affectedExOption) => {
                                    return (
                                        <li className="option-description" key={affectedExOption.optionCode}>
                                            {affectedExOption.optionCode} -{' '}
                                            {affectedExOption.optionDescription}
                                            {allVendorExcludedOptions.indexOf(
                                                affectedExOption.optionCode,
                                            ) > -1
                                                ? '*'
                                                : ''}
                                        </li>
                                    );
                                },
                            )}
                        </div>
                    )}
            </div>
        </>
    );
};
const ConflictsModal = connectModal(({
    noSelectionHandler,
    optionSelectionClick,
    selectedOptionItem
}) => {
    const { compareSelect, resolvedOptions } = useSelector(
        (state) => state.leasingReducer,
    );
    const dispatch = useDispatch();
    const {
        collisionState
    } = compareSelect;
    let isLowestCount = 0;
    let isBtnDisabled = false;
    const affectedRequired = collisionState?.vendorCollisionsPerOption?.filter(
      (contract) => contract.affectedRequiredOptions.length > 1,
    );
    // Button validation check
    if (affectedRequired?.length && resolvedOptions?.length === 0) {
      isBtnDisabled = true;
    }

    const conflictModalRadioHandler = (receivedItem,
        requiredOption,
        choosedItem) => {
        let resolvedVendor = resolvedOptions;
        if (resolvedVendor.length) {
            const optionsExist = resolvedVendor.filter(
                (item) => item.contractLineId === receivedItem.contractLineId,
            );
            if (optionsExist && optionsExist.length) {
                resolvedVendor.forEach((item) => {
                    const resolvedItem = cloneDeep(item);
                    if (resolvedItem.contractLineId === receivedItem.contractLineId) {
                        resolvedItem.selectedIndex = requiredOption.index;
                    }
                    return resolvedItem;
                });
            } else {
                resolvedVendor = [
                    ...resolvedVendor,
                    {
                        contractLineId: receivedItem.contractLineId,
                        optionCode: choosedItem.optionCode,
                        selectedIndex: requiredOption.index,
                    },
                ];
            }
        } else {
            resolvedVendor = [
                ...resolvedVendor,
                {
                    contractLineId: receivedItem.contractLineId,
                    optionCode: choosedItem.optionCode,
                    selectedIndex: requiredOption.index,
                },
            ];
        }
        dispatch(setResolvedOptions([...resolvedVendor]));

    }
    return (
        <Modal
            title="Your selection requires the following changes"
            className="collision-found-modal"
            onClose={() => {
                noSelectionHandler();
            }}
            actions={
                <>
                    <Button
                        type="button"
                        className="cancel-button"
                        onClick={() => {
                            noSelectionHandler();
                        }}
                        label="No, cancel my selection"
                    />
                    <Button
                        type="button"
                        onClick={optionSelectionClick}
                        disabled={isBtnDisabled}
                        label="Yes, apply changes"
                    />
                </>
            }
        >
            <div className="modal-body-title">
                Based on your selection of the following option:{' '}
            </div>
            <h2 data-testid="selected-option-description">
                {selectedOptionItem.optionCode} - {selectedOptionItem.optionDescription}
            </h2>
            <div className="modal-body-sub-title">
                The following vendors <span className="include">INCLUDE</span>,{' '}
                <span className="exclude">EXCLUDE</span> and/or{' '}
                <span className="required">REQUIRE</span> additional options listed
                below:
            </div>

            {collisionState?.unselectOptions?.length ? (
                <div className="unselected-desc">
                    *This option is excluded by all vendors and therefore will no longer
                    be selected within the underlying Compare page
                </div>
            ) : (
                ''
            )}

            {collisionState?.selectOptions?.included?.length > 0 ? (
                <div className="unselected-desc">
                    ^This option is included by all vendors and therefore will be selected
                    within the underlying Compare page.
                </div>
            ) : (
                ''
            )}

            {collisionState?.selectOptions?.required?.length > 0 ? (
                <div className="unselected-desc">
                    ^This option is required by all vendors and therefore will be selected
                    within the underlying Compare page.
                </div>
            ) : (
                ''
            )}

            <div className="conflict-modal-body">
                {collisionState?.vendorCollisionsPerOption &&
                    collisionState?.vendorCollisionsPerOption?.length &&
                    collisionState?.vendorCollisionsPerOption?.map((item) => {
                        const isVendorResolved = resolvedOptions.find(
                            (option) => option.contractLineId === item.contractLineId,
                        );
                        const checkIsConflictedVendor =
                            (item.affectedExcludedOptions &&
                                item.affectedExcludedOptions.length) ||
                            (item.affectedIncludedOptions &&
                                item.affectedIncludedOptions.length) ||
                            (item.affectedRequiredOptions &&
                                item.affectedRequiredOptions.length);
                        if (!checkIsConflictedVendor) {
                            return '';
                        }
                        isLowestCount += 1;
                        return (
                            <div className="affected-vendor-card" key={item.contractLineId}>
                                {isLowestCount === 1 ? (
                                    <div className="lowest-price-block">
                                        <div className="parallelogram">
                                            <span className="lowest-price-title">
                                                <GiCheckMark />
                                                Current Lowest Price
                                            </span>
                                        </div>
                                        <div className="triangle-bottomleft" />
                                    </div>
                                ) : (
                                    ''
                                )}
                                { }
                                <div className="vendor-card-inner">
                                    <div className="collided-vendor-name">
                                        {item.vendorName} - {item.modelName}
                                        {isVendorResolved && (
                                            <span className="conflict-resolved-check">
                                                <img src={CheckedSVG} alt="conflict resolved" />
                                            </span>
                                        )}
                                    </div>
                                    <div className="options-section">
                                        {item.affectedIncludedOptions &&
                                            item.affectedIncludedOptions.length !== 0 && (
                                                <div className="included-options">
                                                    <div className="includes-section text-base-light">
                                                        <div className="include-title">+ INCLUDES</div>
                                                    </div>
                                                    {item.affectedIncludedOptions.map(
                                                        (affectedInOption) => {
                                                            return (
                                                                <li className="option-description" key={affectedInOption.optionCode}>
                                                                    {affectedInOption.optionCode} -{' '}
                                                                    {affectedInOption.optionDescription}
                                                                    {collisionState?.selectOptions.included.indexOf(
                                                                        affectedInOption.optionCode,
                                                                    ) > -1
                                                                        ? '^'
                                                                        : ''}
                                                                </li>
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            )}

                                        {item.affectedExcludedOptions &&
                                            item.affectedExcludedOptions.length !== 0 && (
                                                <div className="excluded-options">
                                                    <div className="excludes-section text-base-light">
                                                        <div className="exclude-title">– EXCLUDES</div>
                                                    </div>
                                                    {item.affectedExcludedOptions.map(
                                                        (affectedExOption) => {
                                                            return (
                                                                <li className="option-description" key={affectedExOption.optionCode}>
                                                                    {affectedExOption.optionCode} -{' '}
                                                                    {affectedExOption.optionDescription}
                                                                    {affectedExOption.isAnUnselectOption
                                                                        ? '*'
                                                                        : ''}
                                                                </li>
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            )}

                                        {item.affectedRequiredOptions &&
                                            item.affectedRequiredOptions.length !== 0 && (
                                                <RequiredConflictOptions
                                                    item={item}
                                                    selectedOptionItem={selectedOptionItem}
                                                    radioHandler={conflictModalRadioHandler}
                                                    requiredOptions={collisionState?.selectOptions?.required}
                                                    allVendorIncludedOptions={
                                                        collisionState?.selectOptions?.included
                                                    }
                                                    allVendorExcludedOptions={collisionState?.unselectOptions}
                                                />
                                            )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className="conflict-explanation-msg">
                When you have resolved conflicts with your vendors of interest, click on{' '}
                <strong>Apply changes </strong>
                to continue. <strong>PLEASE NOTE:</strong> Unresolved conflicts will
                result in nonbuildable models.
            </div>
        </Modal>)
});

export default ConflictsModal;