import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  Label,
  TextInput,
  RequiredFieldIndicator,
  Checkbox,
  Button,
  AFPTable,
  useModal,
  connectModal,
  Alert,
  EmptyState,
  AFPTableRowAction,
  ComboBox,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import VendorAcceptance from '../VendorAcceptance/VendorAcceptance';
import {
  GET_CONTRACT_LINE_MODEL_COLORS,
  GET_STANDARD_CODES,
  SUBMIT_CUSTOMER_RESPONSE,
} from '../../services/data-layer';
import { RequisitionStatus } from '../../pages/ReviewDetails/RequisitionDetailsUtils';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { getFullVendorQuote, getLowestPrice } from '../MultipleVendorsAcceptance/vendorQuoteUtil';
import './SelectedVendorAcceptance.scss';
import UploadNewFileModal from '../MultipleAdditionalRequirements/UploadNewFileModal/UploadNewFileModal';
import { ROW_ACTIONS } from '../../pages/non-standard-purchase/constants';
import AttachmentDownload from '../../pages/ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import DeleteAdditionalReqFileModal from '../MultipleAdditionalRequirements/DeleteAdditionalReqFileModal/DeleteAdditionalReqFileModal';
import EditFileDocumentationModal from '../MultipleAdditionalRequirements/EditFileDocumentationModal/EditFileDocumentationModal';
import { getAreqCustomerResponsePayload } from './areqVendorQuoteUtils';
import { StoreOperations, StoreSubjects } from '../../constants/constants';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { TRANSACTION_TYPES } from '../../utilities/CompareVehicleUtils';
import { useDispatch } from 'react-redux';
import { setContractsCostBreakdown, setRequisitionColor } from '../../reducers/review';
import { useGetContractsCostBreakdown } from '../../requests/common-requests';
import { withoutAREQOption } from '../../constants/utils';
import { setCostSummary } from '../../reducers/purchase';
import { useSelector } from 'react-redux';

export const CONTRACTING_APPROVAL = 'CONTRACTING_APPROVAL';

const SelectedVendorAcceptance = ({
  visible,
  requisitionId,
  requisitionName,
  vendorQuotes,
  reference,
  status,
  requisition,
}) => {
  const ability = useAppAbility();
  const rDispatch = useDispatch();

  const {
    sinContracts,
    } = useSelector((state) => state.reviewReducer);

  const isApprover = useMemo(
    () => ability?.can(StoreOperations.Approve, StoreSubjects.Requisition),
    [ability],
  );
  const isCPTSelected = useMemo(() => {
    return requisition?.clientData?.selectedOptions?.selectedOptions?.some(
      ({ optionCode }) => optionCode === 'CPT',
    );
  }, [requisition]);

  const navigate = useNavigate();
  const { selectedVendorQuote, state, dispatch, setSelectedVendorQuote } = useContext(
    VehicleRequisitionContext,
  );

  const { availableColors = [], areqPendingCustomerResponse } = state;

  const [getColors] = useLazyQuery(GET_STANDARD_CODES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const colors = data?.getStandardCodes?.rows;
      if (!colors?.length) {
        return;
      }

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_AVAILABLE_COLORS,
        payload: colors.map((x) => ({
          label: x.title,
          value: x.code,
          price: 0,
          vendorPrice: 0,
        })),
      });
    },
  });

  const [getModelStandardItemColors] = useLazyQuery(
    GET_CONTRACT_LINE_MODEL_COLORS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const colors = data?.storeGetContractLineColors;
        if (colors?.length > 0) {
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_AVAILABLE_COLORS,
            payload: colors.map((x) => ({
              label: x.description,
              value: x.colorCode,
              price: x.price,
              vendorPrice: x.vendorPrice,
            })),
          });
        } else {
          getColors({
            variables: {
              filters: {
                operator: 'EQ',
                key: 'code_metadata_id',
                value: 1,
              },
            },
          });
        }
      },
    },
  );

  const [getContractsCostBreakdown] =
    useGetContractsCostBreakdown(setContractsCostBreakdown);
  
  const { selectedContract } = state || {};
  useEffect(() => {
    if (selectedContract?.id) {
      getModelStandardItemColors({
        variables: {
          contractLineId: parseInt(selectedContract?.id, 10),
          transactionType: TRANSACTION_TYPES.PURCHASING,
        },
      });
    }
  }, [selectedContract]);

  const [deleteRowIndex, setDeleteRowIndex] = useState(-1);
  const [rowToUpdate, setRowToUpdate] = useState(null);

  const deleteFileModal = useModal();
  const DisplayDeleteFileModal = connectModal(DeleteAdditionalReqFileModal);

  const updateFileModal = useModal();
  const DisplayUpdateFileModal = connectModal(EditFileDocumentationModal);

  const handleTableActions = (event, row) => {
    setRowToUpdate(row);
    if (event === 'Edit') {
      updateFileModal.openModal();
    } else {
      setDeleteRowIndex(row.index);
      deleteFileModal.openModal();
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'File name',
        accessor: 'filename',
        sortable: false,
        /* eslint-disable react/prop-types */
        Cell: ({ row: { original } }) => {
          return (
            <AttachmentDownload
              name={original?.name}
              metadataId={original?.metadataId}
            />
          );
        },
      },
      {
        Header: 'Note',
        accessor: 'note',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => original?.description,
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        /* eslint-disable react/prop-types */
        Cell: (props) => {
          const { row } = props;
          return (
            <AFPTableRowAction
              actions={ROW_ACTIONS}
              onSelectAction={(evt) => {
                handleTableActions(evt, row);
              }}
              {...props}
            />
          );
        },
      },
    ],
    [],
  );

  const uploadNewFileModal = useModal();
  const DisplayUploadFileModal = connectModal(UploadNewFileModal);

  const formProps = useForm({
    defaultValues: areqPendingCustomerResponse,
    reValidateMode: 'onChange',
  });

  const { watch, reset, getValues, setValue } = formProps;
  useEffect(() => {
    reset({ ...areqPendingCustomerResponse });
  }, [selectedVendorQuote]);

  const initialAreqPendingCustomerResponse = watch();
  useEffect(() => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_AREQ_PENDING_CUSTOMER_RESPONSE,
      payload: initialAreqPendingCustomerResponse,
    });
  }, [JSON.stringify(initialAreqPendingCustomerResponse)]);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = formProps;

  const {
    fields: fundingAttachments,
    append: quoteAppend,
    remove,
    update,
  } = useFieldArray({
    control: formProps.control,
    name: 'fundingDocs',
  });
  const handleDelete = () => {
    remove(deleteRowIndex);
    deleteFileModal.closeModal();
  };

  const [submitCustomerResponseMutation] = useMutation(
    SUBMIT_CUSTOMER_RESPONSE,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const selectColor = async (value) => {
    if (value) {
      const newColor = availableColors?.find((x) => x.value === value) || null;
      setValue('areqVehicleColor', value);
      setValue('areqVehicleColorName', newColor?.label || '');
      setValue('makeColorPriceToCustomer', newColor?.price || 0);
      setValue('makeColorPriceToGsa', newColor?.vendorPrice || 0);

      const colorPriceInfo = {
        contractLineId: selectedVendorQuote.contractLineId,
        makeColorCode: newColor?.value,
        makeColorName: newColor?.label,
        priceToCustomer: newColor?.price || 0,
        priceToGsa: newColor?.vendorPrice || 0,
      };

      const currentColorPrice =
        requisition.vehicles[0]?.makeColorPriceToCustomer || 0;
      // console.log('updateRequisitionColor', {
      //   name: colorPriceInfo?.makeColorName,
      //   price: colorPriceInfo?.priceToCustomer,
      //   currentColorPrice,
      // });
      rDispatch(
        setRequisitionColor({
          makeColorCode: colorPriceInfo.makeColorCode,
          makeColorName: colorPriceInfo.makeColorName,
          makeColorPriceToCustomer: colorPriceInfo.priceToCustomer,
          makeColorPriceToGsa: colorPriceInfo.priceToGsa,
        }),
      );

      if (currentColorPrice !== colorPriceInfo.priceToCustomer) {
        const getContractsCostBreakdownResult = await getContractsCostBreakdown(
          {
            variables: {
              standardItemId: requisition.standardItemId,
              quantity: requisition.quantity,
              selectedOptions: withoutAREQOption(
                requisition.clientData.selectedOptions.selectedOptions,
              ),
              ...(colorPriceInfo && {
                colorPriceInfo: {
                  contractLineId: colorPriceInfo.contractLineId,
                  priceToCustomer: colorPriceInfo.priceToCustomer,
                  priceToGsa: colorPriceInfo.priceToGsa,
                },
              }),
              transactionType: requisition.transactionType,
            },
          },
        );
        if (
          getContractsCostBreakdownResult.data?.modelCostBreakDown &&
          selectedContract
        ) {
          const { modelCostBreakDown } = getContractsCostBreakdownResult.data;
          const costBreakdown = modelCostBreakDown?.find(
            (x) => x.contractLineId === selectedContract.id,
          );
          if (costBreakdown) {
            const vendorQuote = requisition.vendorQuotes.find(
              (x) => x.contractLineId === selectedVendorQuote.contractLineId,
            );
            const newSelectedVendorQuote = getFullVendorQuote(
              vendorQuote,
              sinContracts,
              modelCostBreakDown,
            );

            rDispatch(
              setCostSummary({
                _source: 'SelectedVendorAcceptance',
                areqPriceWithFee: newSelectedVendorQuote.areqPriceWithFee,
                baseAndOptionsPrice: costBreakdown.baseAndOptionsPrice || 0,
                basePrice: costBreakdown.basePrice || 0,
                perOrderOptionsPrice: costBreakdown.perOrderOptionsPrice || 0,
                perVehicleColorPrice: costBreakdown.perVehicleColorPrice || 0,
                perVehicleOptionsPrice:
                  costBreakdown.perVehicleOptionsPrice || 0,
                purchaseRate: requisition.purchaseRate || 0,
                quantity: requisition?.quantity || 0,
                totalSellingPrice: newSelectedVendorQuote.totalSellingPrice,
              }),
            );
            setSelectedVendorQuote(newSelectedVendorQuote);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (fundingAttachments.length > 0) {
      clearErrors('fundingDocumentation');
    }
  }, [fundingAttachments]);

  const uploadedFundingAttachments = fundingAttachments;

  const setFundingDocError = () => {
    return setError('fundingDocumentation', {
      type: 'required',
      message: 'Funding documentation is required',
    });
  };

  const onSubmit = async (data) => {
    if (uploadedFundingAttachments.length === 0) {
      setFundingDocError();
    } else {
      const variables = getAreqCustomerResponsePayload(
        data,
        selectedVendorQuote.requisitionVendorQuoteId,
        requisitionId,
        selectedContract,
      );

      await submitCustomerResponseMutation({
        variables,
      });

      if (!isApprover) {
        navigate(
          `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${RequisitionStatus.FINAL_APPROVAL}`,
        );
      }
    }
  };

  useImperativeHandle(reference, () => ({
    ...reference.current,
    submitCustomerResponse: async () => {
      await handleSubmit(onSubmit)();
      const formErrorsCount = Boolean(Object.keys(errors).length);
      const zeroDocs = uploadedFundingAttachments.length === 0;

      return formErrorsCount || zeroDocs;
    },
  }));

  if (!visible) {
    return null;
  }

  const lowestPrice = getLowestPrice(vendorQuotes);
  const nonLowBid = lowestPrice !== selectedVendorQuote.totalPrice;

  const buildColorList = () => {
    return availableColors.map((x) => ({
      value: x.value,
      label: `${x.label} ${x.price > 0 ? `+$${x.price}` : ''}`,
    }));
  };

  return (
    <>
      <VendorAcceptance
        vendorQuote={selectedVendorQuote}
        key={selectedVendorQuote.id}
        status={status}
        lowestPrice={lowestPrice}
        requisitionCartState={requisition}
      />

      {status !== RequisitionStatus.FINAL_APPROVAL && (
        <>
          <FormProvider {...formProps}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {nonLowBid && (
                <div className="container">
                  <Controller
                    rules={{
                      required: 'This is a required field',
                    }}
                    // control={control}
                    name="lowBidJustification"
                    render={({ field: { value, onChange, ref } }) => (
                      <TextInput
                        type="textarea"
                        label={
                          <>
                            <div className="non-low-bid-title">
                              Non low bid justification{' '}
                              <RequiredFieldIndicator />
                            </div>
                            <div className="subtitle-content">
                              <span className="subtitle-strong">
                                You have selected the non low bid vendor.
                              </span>{' '}
                              Please provide justification as to why the low bid
                              is not the lowest bid technically acceptable. (If
                              you have supporting documents, you may upload them
                              along with your funding confirmation documents
                              below.)
                            </div>
                          </>
                        }
                        data-testid="lowBidJustification"
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                        name="lowBidJustification"
                        errorMessage={errors?.lowBidJustification?.message}
                        characterLimit={1000}
                        inputClass="non-low-bid-text-area"
                      />
                    )}
                  />
                </div>
              )}

              {
                (availableColors.length > 0 && (
                  <div className="top-padding">
                    <div
                      className={
                        errors?.vehicleType?.message &&
                        'usa-form-group usa-form-group--error'
                      }
                    >
                      <Label required>Select color</Label>
                      <div className="usa-error-message">
                        {errors?.areqVehicleColor?.message}
                      </div>
                      <Controller
                        rules={{ required: 'This is a required field' }}
                        name="areqVehicleColor"
                        render={({ field: { value, onChange, ref } }) => (
                          <ComboBox
                            data-testid="areq-vehicle-color-dropdown"
                            id="areqVehicleColor"
                            options={buildColorList()}
                            value={value}
                            className={
                              errors?.areqVehicleColor?.message &&
                              'usa-input--error'
                            }
                            defaultValue={value}
                            onChange={(value) => selectColor(value)}
                            inputRef={ref}
                          />
                        )}
                      />
                    </div>
                  </div>
                ))}

              <div className="top-padding-70">
                <div className="title">
                  Confirm funding <RequiredFieldIndicator />{' '}
                </div>
                <div className="title-desc">
                  Attach relevant funding documents demonstrating that funding
                  is obligated to fulfill this AREQ requisition.
                </div>
                <div className="title-desc">
                  <b>Note:</b> The government&apos;s payment terms are always
                  net 30 calendar days after receipt of a proper invoice.
                </div>
                {errors?.fundingDocumentation?.message && (
                  <Alert type="error">
                    <div>Please upload at least one document</div>
                  </Alert>
                )}
                <div className="upload-btn">
                  <Button
                    data-testid="funding-upload-btn"
                    onClick={() => {
                      uploadNewFileModal.openModal();
                    }}
                    type="button"
                    variant="outline"
                    leftIcon={{ name: 'add', className: 'plus-icon' }}
                    label="Upload file"
                  />
                </div>
                <div>
                  <AFPTable
                    testId="funding-doc-attachments-table"
                    columns={columns}
                    data={fundingAttachments || []}
                  />
                  {!fundingAttachments.length && (
                    <EmptyState
                      hasBackground
                      containerStyles="margin-top-neg-2 padding-y-10"
                      topText="No files uploaded"
                    />
                  )}
                </div>
              </div>

              <div className="error-text-desc">
                {errors?.certifyCheckBox?.message}
              </div>
              <Controller
                rules={{
                  required:
                    'Please certify that you have uploaded all required documents ',
                }}
                name="certifyCheckBox"
                render={({ field: { value, onChange } }) => {
                  return (
                    <Checkbox
                      className="supporting-docs-checkbox"
                      name="certifyCheckBox"
                      // disabled={!returnedFundingDoc}
                      checked={value}
                      onChange={onChange}
                      label={
                        <>
                          I hereby certify that I have uploaded all required
                          documents for funding confirmation
                          <RequiredFieldIndicator />
                        </>
                      }
                    />
                  );
                }}
              />
            </form>

            <DisplayUploadFileModal
              isOpen={uploadNewFileModal.isOpen}
              handleClose={uploadNewFileModal.closeModal}
              append={quoteAppend}
              reqType="URGENT_REQ_CUSTOMER_FUNDING_DOC"
              draftId={requisitionId}
            />

            <DisplayDeleteFileModal
              isOpen={deleteFileModal.isOpen}
              handleClose={deleteFileModal.closeModal}
              handleDelete={handleDelete}
              filename={rowToUpdate?.original?.name}
            />

            <DisplayUpdateFileModal
              isOpen={updateFileModal.isOpen}
              handleClose={updateFileModal.closeModal}
              rowToEdit={rowToUpdate}
              update={update}
            />
          </FormProvider>
        </>
      )}
    </>
  );
};

SelectedVendorAcceptance.propTypes = {
  reference: PropTypes.instanceOf(Object).isRequired,
  requisitionId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
  status: PropTypes.string,
  visible: PropTypes.bool,
  vendorQuotes: PropTypes.instanceOf(Array).isRequired,
  requisition: PropTypes.instanceOf(Object).isRequired,
};

SelectedVendorAcceptance.defaultProps = {
  status: '',
  visible: true,
};

export default SelectedVendorAcceptance;
