import { useContext } from 'react';
import { applyFormErrorsRecursively } from '@gsa/afp-shared-form-utils';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { emDashUnicode } from '../../../../constants/constants';
import handleAorJ from './change-helpers/handle-a-or-j';
import handleBorK from './change-helpers/handle-b-or-k';
import handleCorL from './change-helpers/handle-c-or-l';
import validateRequisitionNumber from './change-helpers/validate-requisition-number';
import { feedback } from '../../utils/VehicleRequisitionUtils';

let lastSignalCode = null;
const signalCodeGroups = [
  ['A', 'J'],
  ['B', 'K'],
  ['C', 'L'],
];

/* eslint consistent-return: off */
export function handleAgencyInformationChange({
  agencyCode,
  bureauCode,
  isDoD,
  formGeneratorRef,
  requisitionDraftId,
}) {
  const {
    validateBoacInWallet,
    validateBoacInWalletForAddressCode,
    getBoacForSignalCodeCOrL,
    validateRequisitionNumber: validateRequisitionNumberEndpoint,
  } = useContext(VehicleRequisitionContext);

  return async (formContext, changedFieldId) => {
    const {
      getValues,
      setValue,
      setError,
      clearErrors,
      formState: { isSubmitted },
    } = formContext; // this is the same as useFormContext() from react-hook-form
    const agencyInfoFormValues = getValues();

    if (changedFieldId === 'additionalAreqFunds') {
      const total =
        agencyInfoFormValues.unitPrice +
        agencyInfoFormValues.additionalAreqFunds;
      setValue('unitPriceWithAreqs', total);

      return;
    }

    if (changedFieldId !== null) {
      let hasErrors = false;
      const agencyInfoFormValueKeys = ['requisition.boac'];

      if (!isSubmitted) {
        if (agencyInfoFormValues.requisition.boac.length === 0) {
          const index = agencyInfoFormValueKeys.indexOf('requisition.boac');
          if (index > -1) agencyInfoFormValueKeys.splice(index, 1);
        }
      }

      const { errors } = await formGeneratorRef.current.validate(
        agencyInfoFormValues,
        {},
        {},
        true,
      );

      if (Object.keys(errors).length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of agencyInfoFormValueKeys) {
          if (applyFormErrorsRecursively(key, errors, setError, clearErrors)) {
            hasErrors = true;
          }
        }
      }

      if (hasErrors) return false;
    }

    const signalCode = agencyInfoFormValues?.signalCode;

    if (changedFieldId === 'signalCode') {
      if (
        lastSignalCode &&
        signalCodeGroups.some(
          (group) =>
            group.includes(lastSignalCode) && group.includes(signalCode),
        )
      ) {
        lastSignalCode = signalCode;
        return {};
      }

      lastSignalCode = signalCode;

      if (signalCode === '-1') {
        setValue('treasuryAccountSymbol', emDashUnicode);
        return {};
      }

      let serviceCode = '';
      if (['C', 'L'].includes(signalCode)) {
        if (isDoD) {
          serviceCode = agencyInfoFormValues?.requisition?.boac.substring(0, 1);
        } else {
          serviceCode = sessionStorage.getItem('oldAgencyCode');
        }
      }

      setValue('serviceCode', serviceCode);
      setValue('fundCode', '00');
      setValue('supplementaryAddressCode', '');
      setValue('treasuryAccountSymbol', emDashUnicode);
      clearErrors('supplementaryAddressCode');
    }

    const requisitionNumber = await validateRequisitionNumber({
      validateRequisitionNumberEndpoint,
      requisitionDraftId,
      boac: agencyInfoFormValues?.requisition?.boac,
      julian: agencyInfoFormValues?.requisition?.julian,
      serialNumber: agencyInfoFormValues?.requisition?.serialNumber,
      force: changedFieldId === null,
    });

    if (requisitionNumber === false) {
      setError('requisition.boac', {
        message: feedback.requisitionBOAC[6],
      });
      setError('requisition.julian', {
        message: feedback.requisitionBOAC[6],
      });
      setError('requisition.serialNumber', {
        message: feedback.requisitionBOAC[6],
      });

      return {
        requisition: {
          boac: {
            message: feedback.requisitionBOAC[6],
          },
          julian: {
            message: feedback.requisitionBOAC[6],
          },
          serialNumber: {
            message: feedback.requisitionBOAC[6],
          },
        },
      };

      // eslint-disable-next-line no-else-return
    } else {
      clearErrors('requisition.boac');
      clearErrors('requisition.julian');
      clearErrors('requisition.serialNumber');
    }

    if (agencyCode.startsWith('N')) {
      return {};
    }

    if (['A', 'J'].includes(signalCode)) {
      return handleAorJ({
        validateBoacInWallet,
        agencyInfoFormValues,
        agencyCode,
        bureauCode,
        setValue,
        setError,
        clearErrors,
        changedFieldId,
        formGeneratorRef
      });
    }

    if (['B', 'K'].includes(signalCode)) {
      return handleBorK({
        validateBoacInWalletForAddressCode,
        agencyInfoFormValues,
        agencyCode,
        bureauCode,
        setValue,
        setError,
        clearErrors,
        changedFieldId,
        formGeneratorRef
      });
    }

    if (['C', 'L'].includes(signalCode)) {
      return handleCorL({
        validateBoacInWalletForAddressCode,
        getBoacForSignalCodeCOrL,
        agencyInfoFormValues,
        isSubmitted,
        agencyCode,
        bureauCode,
        setValue,
        setError,
        clearErrors,
        isDoD,
        changedFieldId,
        formGeneratorRef
      });
    }

    return {};
  };
}
