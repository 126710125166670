import { useQuery } from '@apollo/client';
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { GET_ALL_VEHICLE_GROUPS } from '../../services/data-layer/standards';

export const MyOrdersContext = createContext({});

export default function MyOrdersProvider({ children }) {
  const [orderRows, setOrderRows] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReset, setResetPagination] = useState(false);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [orderDirection, setOrderDirection] = useState('DESC');

  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  });

  const { data: allVehicleGroups } = useQuery(GET_ALL_VEHICLE_GROUPS, {
    fetchPolicy: 'c',
    variables: {
      limit: 500,
      filters: {
        key: "code_metadata_id",
        operator: "EQ",
        value: "10"
      }
    }
  });
  const allVehicleTypes = allVehicleGroups?.getStandardCodes?.rows || [];

  const value = {
    orderRows,
    orderCount,
    allVehicleTypes,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    isReset,
    setResetPagination,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    paginationState,
    setPaginationState,
  };

  return (
    <MyOrdersContext.Provider value={value}>
      {children}
    </MyOrdersContext.Provider>
  );
}

MyOrdersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
