import { applyFormErrorsRecursively } from '@gsa/afp-shared-form-utils';
import { emDashUnicode } from '../../../../../constants/constants';
import { feedback } from '../../../utils/VehicleRequisitionUtils';
import validateBOAC from './validate-boac';

export default async function handleAorJ({
  validateBoacInWallet,
  agencyInfoFormValues,
  agencyCode,
  bureauCode,
  setValue,
  setError,
  clearErrors,
  changedFieldId,
  formGeneratorRef
}) {
  const errors = {};
  let treasuryAccountSymbol =
    changedFieldId === 'signalCode'
      ? emDashUnicode
      : agencyInfoFormValues?.treasuryAccountSymbol;

  if (agencyInfoFormValues?.requisition?.boac.length === 0) return {};

  // undefined = OK, but not valid | null = ERROR
  const boac = await validateBOAC({
    endpoint: validateBoacInWallet,
    boac: agencyInfoFormValues?.requisition?.boac,
    signalCode: agencyInfoFormValues?.signalCode,
    agencyCode,
    bureauCode,
    force: changedFieldId === null,
  });

  if (boac !== undefined && boac !== null) {
    if (boac.tas && !boac.tas.includes('null')) {
      treasuryAccountSymbol = boac.tas;
    } else {
      treasuryAccountSymbol = emDashUnicode;
    }
  } else {
    treasuryAccountSymbol = emDashUnicode;
  }

  setValue('treasuryAccountSymbol', treasuryAccountSymbol);
  formGeneratorRef.current.setFocus('treasuryAccountSymbol');

  if (boac === undefined) {
    if (agencyInfoFormValues?.requisition?.boac.length !== 6) {
      if (!errors.requisition) errors.requisition = {};
      errors.requisition.boac = {
        message: feedback.requisitionBOAC[3],
      };
    }
  } else if (boac === null) {
    if (!errors.requisition) errors.requisition = {};
    errors.requisition.boac = {
      message: feedback.requisitionBOAC[1],
    };
  } else if (!boac.tiedToAgency) {
    if (!errors.requisition) errors.requisition = {};
    errors.requisition.boac = {
      message: feedback.requisitionBOAC[2],
    };
  } else if (!boac.isValid) {
    if (!errors.requisition) errors.requisition = {};
    errors.requisition.boac = {
      message: feedback.requisitionBOAC[4],
    };
  } else if (!boac.tas || boac.tas.includes('null')) {
    errors.treasuryAccountSymbol = {
      message: feedback.tas[0],
    };
  } else {
    clearErrors('requisition.boac');
    clearErrors('treasuryAccountSymbol');
  }

  if (Object.keys(errors).length > 0) {
    applyFormErrorsRecursively(
      'requisition.boac',
      errors,
      setError,
      clearErrors,
    );
    applyFormErrorsRecursively(
      'treasuryAccountSymbol',
      errors,
      setError,
      clearErrors,
    );
  }

  return errors;
}
