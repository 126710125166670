import React from 'react';
import PropTypes from 'prop-types';
import AgencyInformation from '../../../VehicleRequisition/components/AgencyInformation/AgencyInformation';

const UrgentRequisitionAgencyInfo = ({ handlePageNavigation }) => {
  return (
    <div>
      <div tabIndex="0" className="margin-bottom-5">
        <strong>Provide agency information</strong>
        <div>Please provide information for agency and financials.</div>
      </div>
      <AgencyInformation
        reqType="NonSop"
        handlePageNavigation={handlePageNavigation}
      />
    </div>
  );
};

UrgentRequisitionAgencyInfo.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
};

export default UrgentRequisitionAgencyInfo;
