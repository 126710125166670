import { applyFormErrorsRecursively } from '@gsa/afp-shared-form-utils';
import { emDashUnicode } from '../../../../../constants/constants';
import { feedback } from '../../../utils/VehicleRequisitionUtils';
import validateBOAC from './validate-boac';

export default async function handleBorK({
  validateBoacInWalletForAddressCode,
  agencyInfoFormValues,
  agencyCode,
  bureauCode,
  setValue,
  setError,
  clearErrors,
  changedFieldId,
  formGeneratorRef
}) {
  const errors = {};
  let treasuryAccountSymbol =
    changedFieldId === 'signalCode'
      ? emDashUnicode
      : agencyInfoFormValues?.treasuryAccountSymbol;
  const signalCode = agencyInfoFormValues?.signalCode;
  const supplementaryAddressCode =
    changedFieldId === 'signalCode'
      ? ''
      : agencyInfoFormValues?.supplementaryAddressCode;

  if (supplementaryAddressCode.length > 0) {
    // undefined = OK, but not valid | null = ERROR
    const suppAddressBoac = await validateBOAC({
      endpoint: validateBoacInWalletForAddressCode,
      boac: supplementaryAddressCode,
      signalCode,
      agencyCode,
      bureauCode,
      force: changedFieldId === null,
    });

    if (suppAddressBoac !== undefined && suppAddressBoac !== null) {
      if (suppAddressBoac.tas && !suppAddressBoac.tas.includes('null')) {
        treasuryAccountSymbol = suppAddressBoac.tas;
      } else {
        treasuryAccountSymbol = emDashUnicode;
      }
    } else {
      treasuryAccountSymbol = emDashUnicode;
    }

    setValue('treasuryAccountSymbol', treasuryAccountSymbol);
    formGeneratorRef.current.setFocus('treasuryAccountSymbol');

    if (suppAddressBoac === undefined) {
      if (supplementaryAddressCode.length !== 6) {
        errors.supplementaryAddressCode = {
          message: feedback.signalSupplementaryAddress[1],
        };
      }
    } else if (suppAddressBoac === null) {
      errors.supplementaryAddressCode = {
        message: feedback.signalSupplementaryAddress[1],
      };
    } else if (!suppAddressBoac.tiedToAgency) {
      errors.supplementaryAddressCode = {
        message: feedback.signalSupplementaryAddress[2],
      };
    } else if (!suppAddressBoac.isValid) {
      errors.supplementaryAddressCode = {
        message: feedback.signalSupplementaryAddress[11],
      };
    } else if (!suppAddressBoac.tas || suppAddressBoac.tas.includes('null')) {
      errors.treasuryAccountSymbol = {
        message: feedback.tas[0],
      };
    } else {
      clearErrors('supplementaryAddressCode');
      clearErrors('treasuryAccountSymbol');
    }
  } else {
    clearErrors('supplementaryAddressCode');
    clearErrors('treasuryAccountSymbol');
  }

  if (Object.keys(errors).length > 0) {
    applyFormErrorsRecursively(
      'requisition.boac',
      errors,
      setError,
      clearErrors,
    );
    applyFormErrorsRecursively(
      'supplementaryAddressCode',
      errors,
      setError,
      clearErrors,
    );
    applyFormErrorsRecursively(
      'treasuryAccountSymbol',
      errors,
      setError,
      clearErrors,
    );
  }

  return errors;
}
