import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Spinner,
  TextInput,
  DatePicker,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { GET_CATALOGS_FOR_CATEGORY } from '../../../../services/data-layer';
import AddressComponent from '../../../VehicleRequisition/components/DeliveryAddress/AddressComponent';
import UspsAddressedComp from '../../../VehicleRequisition/components/DeliveryAddress/UspsModalComponent';
import FieldFeedback from '../../../../components/FieldFeedback/FieldFeedback';
import {
  updateCurrentAddressState,
  validateSelection,
  addressesMatch,
  adaptKeys,
} from '../../../../utilities/deliveryAddressUtils';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import {
  requisitionSection,
  deliverySection,
  mailingSection,
} from '../../../VehicleRequisition/components/DeliveryAddress/DeliveryAddress';
import './ReqDeliveryAddress.scss';
import {
  ADDRESS_FEEDBACK,
  addressValidations,
} from '../../../VehicleRequisition/utils/VehicleRequisitionUtils';
import moment from 'moment/moment';

const UrgentRequisitionDeliveryAddress = ({ handlePageNavigation }) => {
  const {
    state,
    dispatch,
    updateDraftRequisition,
    showUspsModal,
    setShowUspsModal,
    loadingValidatedRequisitionAddress,
    getAddressValidation,
    setAddressValidationCompleted,
  } = useContext(VehicleRequisitionContext);

  const {
    draftRequisition,
    requisitionStateContext,
    deliveryStateContext,
    mailingStateContext,
    statesDeliveryFetchedFrmContext,
    statesMailingFetchedFrmContext,
    deliveryAddressErrorContext,
    requisitionAddressErrorContext,
    mailingAddressErrorContext,
    requisitionHasError,
    reqValidatedAddress,
    mailingValidatedAddress,
    deliveryValidatedAddress,
    validatedAddressNotFound,
    uspsModalOptionsSelectedState,
    deliveryDate,
    deliveryOptions,
    specialDeliveryInstructions,
  } = state;

  const requisitionDraftId = draftRequisition?.requisitionId;
  const [reqCountrySelected, setReqCountrySelected] = useState('');
  const [deliveryCountrySelected, setDeliveryCountrySelected] = useState('');
  const [mailingCountrySelected, setMailingCountrySelected] = useState('');
  const [requisitionStates, setRequisitionStates] = useState(
    statesDeliveryFetchedFrmContext,
  );
  const [initialLoad, initialLoadTo] = useState(true);
  const [mailingStates, setMailingStates] = useState(
    statesMailingFetchedFrmContext,
  );
  const [deliveryStates, setDeliveryStates] = useState(
    statesDeliveryFetchedFrmContext,
  );
  const [showConfirmationModal, showConfirmationModalTo] = useState(false);
  const [notFoundAddresses, notFoundAddressesTo] = useState('');
  const [deliveryDateError, setDeliverDateError] = useState(false);
  const [deliveryDateErrorMessage, setDeliveryDateErrorMessage] = useState(null)

  const deliveryAddressOption = deliveryOptions
    .flatMap((option) => option.options)
    .find((c) => c.isChecked === true);

  const agency =
    sessionStorage.getItem('orderingAgency') ||
    deliveryStateContext?.agencyOrganizationName ||
    deliveryStateContext.entityName;
  const formattedDate = deliveryDate?.replace(
    /(\d\d)\/(\d\d)\/(\d{4})/,
    '$3-$1-$2',
  );

  const [focus, setFocus] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );
  const [visibilityRequisition, setVisibilityRequisition] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );
  const [feedbackTextRequisition, setFeedbackTextRequisition] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  const [getCountriesData, { data: fetchCountriesData }] = useLazyQuery(
    GET_CATALOGS_FOR_CATEGORY,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getCountriesCallingCode, { data: fetchCountriesCallingCode }] =
    useLazyQuery(GET_CATALOGS_FOR_CATEGORY, {
      fetchPolicy: 'network-only',
    });

  const [getCountryRelatedStatesData] = useLazyQuery(
    GET_CATALOGS_FOR_CATEGORY,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    initialLoadTo(false);
    getCountriesData({ variables: { category: 'ISOCountryCode2' } });
    getCountriesCallingCode({ variables: { category: 'CountryCallingCode' } });
    if (deliveryStateContext?.country) {
      setReqCountrySelected(deliveryStateContext.country);
    }
    // This is a failsafe code to initialize the entity name.
    if (
      requisitionStateContext.entityName === 'INIT' ||
      Object.keys(requisitionStateContext)?.length < 5
    ) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_REQUISITION_STATE,
      });
    }
    if (
      deliveryStateContext.entityName === 'INIT' ||
      Object.keys(deliveryStateContext)?.length < 5
    ) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_DELIVERY_STATE,
      });
    }
    if (
      mailingStateContext.entityName === 'INIT' ||
      Object.keys(mailingStateContext)?.length < 5
    ) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_MAILING_STATE,
      });
    }
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
      payload: {
        ...deliveryStateContext,
        agencyOrganizationName: agency,
      },
    });
  }, []);

  useEffect(() => {
    if (reqCountrySelected) {
      const fetchStates = async () => {
        const results = await getCountryRelatedStatesData({
          variables: { category: `ISOStateCode-${reqCountrySelected}` },
        });
        const states = results?.data?.getCatalogsForCategory.map((s) => ({
          ...s,
          code: s.code.split('-')[1],
        }));
        setRequisitionStates(states);
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_REQUISITION_FETCH_STATES,
          payload: states,
        });
      };

      fetchStates();
    }
  }, [reqCountrySelected]);

  useEffect(() => {
    if (mailingCountrySelected) {
      const fetchStates = async () => {
        const results = await getCountryRelatedStatesData({
          variables: { category: `ISOStateCode-${mailingCountrySelected}` },
        });
        const states = results?.data?.getCatalogsForCategory.map((s) => ({
          ...s,
          code: s.code.split('-')[1],
        }));
        setMailingStates(states);
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAILING_FETCH_STATES,
          payload: states,
        });
      };

      fetchStates();
    }
  }, [mailingCountrySelected]);

  useEffect(() => {
    if (deliveryCountrySelected) {
      const fetchStates = async () => {
        const results = await getCountryRelatedStatesData({
          variables: { category: `ISOStateCode-${deliveryCountrySelected}` },
        });
        const states = results?.data?.getCatalogsForCategory.map((s) => ({
          ...s,
          code: s.code.split('-')[1],
        }));
        setDeliveryStates(states);
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_FETCH_STATES,
          payload: states,
        });
      };

      fetchStates();
    }
  }, [deliveryCountrySelected]);

  useEffect(() => {
    if (requisitionStateContext && requisitionStateContext.countryCode) {
      setReqCountrySelected(requisitionStateContext.countryCode);
    }
    if (mailingStateContext && mailingStateContext.countryCode) {
      setMailingCountrySelected(mailingStateContext.countryCode);
    }
    if (deliveryStateContext && deliveryStateContext.countryCode) {
      setDeliveryCountrySelected(deliveryStateContext.countryCode);
    }
  }, [
    requisitionStateContext.countryCode,
    mailingStateContext.countryCode,
    deliveryStateContext.countryCode,
  ]);

  const [visibilityMailing, setVisibilityMailing] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );
  const [feedbackTextMailing, setFeedbackTextMailing] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  const [visibilityDelivery, setVisibilityDelivery] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  const handleMailingErrors = (errors) => {
    setVisibilityMailing({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.visibilityMailing,
    });
    setFeedbackTextMailing({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.feedbackTextMailing,
    });
  };

  const handleRequisitionErrors = (errors) => {
    setVisibilityRequisition({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.visibilityRequisition,
    });
    setFeedbackTextRequisition({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.feedbackTextRequisition,
    });
  };

  const [feedbackTextDelivery, setFeedbackTextDelivery] = useState(
    Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {}),
  );

  const handleDeliveryErrors = (errors) => {
    setVisibilityDelivery({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.visibilityDelivery,
    });
    setFeedbackTextDelivery({
      ...Object.keys(ADDRESS_FEEDBACK).reduce((acc, cur) => {
        acc[cur] = false;
        return acc;
      }, {}),
      ...errors.feedbackTextDelivery,
    });
  };
  const validateDeliveryDate = (deliveryDate) => {
    setDeliveryDateErrorMessage(null);

    if(!deliveryDate){
      setDeliveryDateErrorMessage("Please provide delivery date");
      return false;
    }
    const today = new Date().setHours(0, 0, 0, 0);
    const deliveryDateCheck = moment(deliveryDate, 'MM/DD/YYYY', true).isValid();
    const dateToCheck = new Date(deliveryDate).setHours(0, 0, 0, 0);
    const isPastDate = !(dateToCheck >= today);
    if (!deliveryDateCheck || isPastDate) {
        setDeliveryDateErrorMessage("Please provide a valid delivery date");
        return false;
    }
      return true;
  }
  useEffect(() => {
    if (deliveryAddressErrorContext && deliveryAddressErrorContext.length > 0) {
      const errors = {
        visibilityDelivery: deliveryAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: 'error' };
          }, {}),
        feedbackTextDelivery: deliveryAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: cur.text };
          }, {}),
      };
      handleDeliveryErrors(errors);
    } else {
      handleDeliveryErrors([]);
    }
  }, [deliveryAddressErrorContext]);

  useEffect(() => {
    if (mailingAddressErrorContext && mailingAddressErrorContext.length > 0) {
      const errors = {
        visibilityMailing: mailingAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: 'error' };
          }, {}),
        feedbackTextMailing: mailingAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: cur.text };
          }, {}),
      };

      handleMailingErrors(errors);
    } else {
      handleMailingErrors([]);
    }
  }, [mailingAddressErrorContext]);

  useEffect(() => {
    if (
      requisitionAddressErrorContext &&
      requisitionAddressErrorContext.length > 0
    ) {
      const errors = {
        visibilityRequisition: requisitionAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: 'error' };
          }, {}),
        feedbackTextRequisition: requisitionAddressErrorContext
          .filter((entry) => entry.visibility)
          .reduce((acc, cur) => {
            return { ...acc, [cur.key]: cur.text };
          }, {}),
      };

      handleRequisitionErrors(errors);
    } else {
      handleRequisitionErrors([]);
    }
  }, [requisitionAddressErrorContext]);

  const handleOnBlur = (value) => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SPECIAL_DELIVERY_INSTRUCTIONS,
      payload: value,
    });
  };

  useEffect(() => {
    if (deliveryCountrySelected) {
      const fetchStates = async () => {
        const results = await getCountryRelatedStatesData({
          variables: { category: `ISOStateCode-${deliveryCountrySelected}` },
        });
        const states = results?.data?.getCatalogsForCategory.map((s) => ({
          ...s,
          code: s.code.split('-')[1],
        }));
        setDeliveryStates(states);
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_FETCH_STATES,
          payload: states,
        });
      };

      fetchStates();
    }
  }, [deliveryCountrySelected]);

  useEffect(() => {
    if (requisitionStateContext && requisitionStateContext.countryCode) {
      setReqCountrySelected(requisitionStateContext.countryCode);
    }
    if (mailingStateContext && mailingStateContext.countryCode) {
      setMailingCountrySelected(mailingStateContext.countryCode);
    }
    if (deliveryStateContext && deliveryStateContext.countryCode) {
      setDeliveryCountrySelected(deliveryStateContext.countryCode);
    }
  }, [
    requisitionStateContext.countryCode,
    mailingStateContext.countryCode,
    deliveryStateContext.countryCode,
  ]);

  const handleAddressCheckbox = (section) => {
    if (
      section === 'Mailing Address' &&
      !mailingStateContext.isMailingSameAsRequisition
    ) {
      const updatedState = {
        ...requisitionStateContext,
        id: 'Mailing Address',
        isMailingSameAsRequisition: true,
        isMilitary: !!requisitionStateContext.isMilitary,
      };
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
        payload: updatedState,
      });
    } else if (
      section === 'Delivery address' &&
      !deliveryStateContext.isDeliverySameAsRequisition
    ) {
      const updatedState = {
        ...requisitionStateContext,
        id: 'Delivery address',
        isDeliverySameAsRequisition: true,
      };

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
        payload: updatedState,
      });
    } else if (
      section === 'Mailing Address' &&
      mailingStateContext.isMailingSameAsRequisition
    ) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_MAILING_STATE,
      });
    } else if (
      section === 'Delivery address' &&
      deliveryStateContext.isDeliverySameAsRequisition
    ) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_DELIVERY_STATE,
      });
    }
  };

  const handleFieldChange = (enteredText, selectedField, sectionId) => {
    if (typeof enteredText !== 'undefined') {
      if (sectionId === 'Requisition Address') {
        const requisitionAddressData = updateCurrentAddressState(
          requisitionStateContext,
          updateDraftRequisition,
          setReqCountrySelected,
          selectedField,
          requisitionDraftId,
          requisitionHasError,
          enteredText,
        );
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_REQUISITION_STATE,
          payload: { id: 'Requisition Address', ...requisitionAddressData },
        });

        if (mailingStateContext.isMailingSameAsRequisition) {
          const updatedState = {
            ...requisitionAddressData,
            id: 'Mailing Address',
            isMailingSameAsRequisition: true,
          };
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
            payload: updatedState,
          });
        }

        if (deliveryStateContext.isDeliverySameAsRequisition) {
          const updatedState = {
            ...requisitionAddressData,
            id: 'Delivery address',
            isDeliverySameAsRequisition: true,
          };

          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
            payload: updatedState,
          });
        }
      } else if (sectionId === 'Mailing Address') {
        const mailingAddressData = updateCurrentAddressState(
          mailingStateContext,
          updateDraftRequisition,
          setMailingCountrySelected,
          selectedField,
          requisitionDraftId,
          requisitionHasError,
          enteredText,
        );

        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
          payload: { ...mailingAddressData, id: 'Mailing Address' },
        });
      } else if (sectionId === 'Delivery address') {
        const deliveryAddressData = updateCurrentAddressState(
          deliveryStateContext,
          updateDraftRequisition,
          setDeliveryCountrySelected,
          selectedField,
          requisitionDraftId,
          requisitionHasError,
          enteredText,
        );

        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
          payload: { ...deliveryAddressData, id: 'Delivery address' },
        });
      }
    }
  };

  const handleInputValidations = (
    e,
    element,
    focused = null,
    sectionId = '',
  ) => {
    const elementValue = e && e.target ? e.target.value : e;

    const isFocused = focused === null ? focus[element] : focused;

    setFocus({ [element]: isFocused });

    if (sectionId === 'Requisition Address') {
      const updatedReqState = {
        ...requisitionStateContext,
        [element]: elementValue,
      };

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_STATE,
        payload: updatedReqState,
      });

      if (mailingStateContext?.isMailingSameAsRequisition) {
        const updatedState = {
          ...requisitionStateContext,
          [element]: elementValue,
          id: 'Mailing Address',
          isMailingSameAsRequisition: true,
        };
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
          payload: updatedState,
        });
      }

      if (deliveryStateContext.isDeliverySameAsRequisition) {
        const updatedState = {
          ...requisitionStateContext,
          [element]: elementValue,
          id: 'Delivery address',
          isDeliverySameAsRequisition: true,
        };

        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
          payload: updatedState,
        });
      }
    } else if (sectionId === 'Mailing Address') {
      const updatedMailingState = {
        ...mailingStateContext,
        [element]: elementValue,
      };

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
        payload: updatedMailingState,
      });
    } else if (sectionId === 'Delivery address') {
      const updatedDeliveryState = {
        ...deliveryStateContext,
        [element]: elementValue,
      };

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
        payload: updatedDeliveryState,
      });
    }
  };

  const handleAddressedChange = (selected, selectedType) => {
    const data = uspsModalOptionsSelectedState;
    data.forEach((li) => {
      const item = li;
      if (selected === 'USPS' && selectedType === item.id && !item.isChecked) {
        item.isChecked = true;
        item.selected = true;
      } else if (selected === 'ENTERED' && selectedType === item.id) {
        item.isChecked = false;
        item.selected = true;
      }
    });
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_USPS_MODAL_OPTIONS_SELECTED_STATE,
      payload: data,
    });

    if (
      selectedType === 'Requisition Address' &&
      !requisitionStateContext.isMilitary
    ) {
      const returned = validateSelection(selected, reqValidatedAddress);
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_VALIDATED_ADDRESS,
        payload: returned,
      });
    }

    if (selectedType === 'Mailing Address' && !mailingStateContext.isMilitary) {
      const returned = validateSelection(selected, mailingValidatedAddress);
      if (returned?.address?.length > 0) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAILING_VALIDATED_ADDRESS,
          payload: returned,
        });
      }
    }

    if (selectedType === 'Delivery address') {
      const returned = validateSelection(selected, deliveryValidatedAddress);
      if (returned?.address?.length > 0) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_VALIDATED_ADDRESS,
          payload: returned,
        });
      }
    }
  };

  const closeModal = () => {
    setShowUspsModal(false);
  };

  const closeConfirmationModal = () => {
    showConfirmationModalTo(false);
  };

  const handleSaveClick = (
    actionType,
    isFromModal,
    preceedWithoutValidation = false,
  ) => {
    if (validatedAddressNotFound.length && !preceedWithoutValidation) {
      notFoundAddressesTo(validatedAddressNotFound?.join(', '));
      setShowUspsModal(false);
      showConfirmationModalTo(true);
      return;
    }

    uspsModalOptionsSelectedState.forEach((item) => {
      if (item.id === 'Requisition Address' && item.isChecked) {
        const updatedState = {
          ...requisitionStateContext,
          addressLine1: requisitionStateContext.isMilitary
            ? requisitionStateContext.militaryOrDiplomaticAddress
            : reqValidatedAddress.address,
          city: reqValidatedAddress.city,
          stateCode: reqValidatedAddress.state
            ? `US-${reqValidatedAddress?.state}`
            : '',
          zipcode: requisitionStateContext.isMilitary
            ? requisitionStateContext.zipcode
            : reqValidatedAddress.zip,
        };
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_REQUISITION_STATE,
          payload: updatedState,
        });
        if (mailingStateContext.isMailingSameAsRequisition) {
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
            payload: {...updatedState, isMailingSameAsRequisition: true},
          });
        }
        if (deliveryStateContext.isDeliverySameAsRequisition) {
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
            payload:  {...updatedState, isDeliverySameAsRequisition: true},
          });
        }
      } else if (item.id === 'Mailing Address' && item.isChecked) {
        const updatedState = {
          ...mailingStateContext,
          addressLine1: mailingStateContext.isMilitary
            ? mailingStateContext.militaryOrDiplomaticAddress
            : mailingValidatedAddress.address,
          city: mailingValidatedAddress.city,
          stateCode: mailingValidatedAddress.state
            ? `US-${mailingValidatedAddress.state}`
            : '',
          zipcode: mailingStateContext.isMilitary
            ? mailingStateContext.zipcode
            : mailingValidatedAddress.zip,
        };
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_MAILING_STATE,
          payload: updatedState,
        });
      } else if (item.id === 'Delivery address' && item.isChecked) {
        const updatedState = {
          ...deliveryStateContext,
          addressLine1: deliveryValidatedAddress.address,
          city: deliveryValidatedAddress.city,
          stateCode: deliveryValidatedAddress.state
            ? `US-${deliveryValidatedAddress.state}`
            : '',
          zipcode: deliveryValidatedAddress.zip,
        };
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_DELIVERY_STATE,
          payload: updatedState,
        });
      }
    });

    // eslint-disable-next-line no-unused-expressions
    handlePageNavigation('continue');
  };

  const initAddresValidation = async () => {
    setShowUspsModal(false);
    setAddressValidationCompleted(false);
    await dispatch({
      type: VehicleRequisitionContextActions.RESET_USPS_VALIDATION,
    });
  };

  const performAddressValidation = () => {
    if (!requisitionStateContext.isMilitary) {
      getAddressValidation(
        'REQUISITION_ADDRESS',
        addressValidations.getAddressValidationVariables(
          requisitionStateContext,
        ),
      );
    }
    if (!mailingStateContext.isMilitary) {
      getAddressValidation(
        'MAILING_ADDRESS',
        addressValidations.getAddressValidationVariables(mailingStateContext),
      );
    }
    if (deliveryStateContext && deliveryStateContext?.countryCode === 'US') {
      getAddressValidation(
        'DELIVERY_ADDRESS',
        addressValidations.getAddressValidationVariables(deliveryStateContext),
      );
    }
  };

  const isValidationPending = (id, addressContext, validatedAddress) => {
    const checkCondition = !addressesMatch(
      adaptKeys(validatedAddress),
      addressContext,
    );

    if (checkCondition) {
      if (addressContext.isMilitary) {
        dispatch({
          type: VehicleRequisitionContextActions.INIT_USPS_MODAL_OPTIONS_SELECTED_STATE,
          payload: {
            id,
            isChecked: true,
            selected: true,
          },
        });
      } else {
        dispatch({
          type: VehicleRequisitionContextActions.INIT_USPS_MODAL_OPTIONS_SELECTED_STATE,
          payload: {
            id,
            isChecked: false,
            selected: false,
          },
        });
      }
    }

    if (addressContext.isMilitary) {
      return false;
    }
    return checkCondition;
  };

  useEffect(() => {
    if (!initialLoad) {
      showConfirmationModalTo(
        Array.isArray(validatedAddressNotFound) &&
          validatedAddressNotFound.length === 3,
      );
      notFoundAddressesTo(validatedAddressNotFound?.join(', '));
    }
  }, [validatedAddressNotFound]);

  const handleNext = async () => {
    const reqErrors = addressValidations.getAddressErrors(
      requisitionStateContext,
      false,
    );
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_REQUISITION_ADDRESS_HAS_ERROR,
      payload: reqErrors,
    });

    const mailingErrors = addressValidations.getAddressErrors(
      mailingStateContext,
      false,
    );
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_REQUISITION_MAILING_HAS_ERROR,
      payload: mailingErrors,
    });

    const deliveryErrors = addressValidations.getAddressErrors(
      deliveryStateContext,
      false,
    );
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_REQUISITION_DELIVERY_HAS_ERROR,
      payload: deliveryErrors,
    });

   if(!validateDeliveryDate(deliveryDate)) {
     return;
   }

    const hasError =
      reqErrors.length > 0 ||
      mailingErrors.length > 0 ||
      deliveryErrors.length > 0;

    if (hasError) return;

    initAddresValidation();

    const reqToBeValidated =
      requisitionStateContext.countryCode === 'US' &&
      !requisitionStateContext?.isMilitary;
    const mailingToBeValidated =
      mailingStateContext.countryCode === 'US' &&
      !mailingStateContext?.isMilitary;
    const deliveryToBeValidated = deliveryStateContext.countryCode === 'US';
    if (reqToBeValidated || mailingToBeValidated || deliveryToBeValidated) {
      performAddressValidation();
      const isReqValidationPending = isValidationPending(
        'Requisition Address',
        requisitionStateContext,
        reqValidatedAddress,
      );

      const isMailingValidationPending = isValidationPending(
        'Mailing Address',
        mailingStateContext,
        mailingValidatedAddress,
      );

      const isDeliveryValidationPending = isValidationPending(
        'Delivery Address',
        deliveryStateContext,
        deliveryValidatedAddress,
      );

      if (
        isReqValidationPending ||
        isMailingValidationPending ||
        isDeliveryValidationPending
      ) {
        setShowUspsModal(true);
      } else {
        setAddressValidationCompleted(true);
        handlePageNavigation('continue');
      }
    } else {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ADDRESS_VERIFICATION_MODAL,
        payload: false,
      });
      // International address will skip validation and proceed next here
      handlePageNavigation('continue');
    }
  };

  const getCurrentDateFormatted = () => {
    const date = new Date();
    // Get year, month, and day part from the date
    const year = date.toLocaleString('default', { year: 'numeric' });
    const month = date.toLocaleString('default', { month: '2-digit' });
    const day = date.toLocaleString('default', { day: '2-digit' });

    // Generate yyyy-mm-dd date string
    return `${year}-${month}-${day}`;
  };

  const isDeliveryAddressValidated =
    deliveryValidatedAddress?.address?.length > 0;
  const isReqAddressValidated = reqValidatedAddress?.address?.length > 0;
  const isMailingAddressValidated =
    mailingValidatedAddress?.address?.length > 0;

  return (
    <>
      <div className="delivery-address">
        <div role="tab" tabIndex="0">
          <h2 className="usa-h2">
            Provide delivery and point of contact information
          </h2>
          <p>
            Please provide information as to where the vehicle will be delivered
            and who GSA should list as the delivery point of contact.
          </p>
        </div>
        {fetchCountriesData && fetchCountriesCallingCode && (
          <>
            <AddressComponent
              section={requisitionSection}
              countriesData={fetchCountriesData.getCatalogsForCategory}
              callingCodes={fetchCountriesCallingCode.getCatalogsForCategory}
              statesData={requisitionStates}
              handleFieldChange={handleFieldChange}
              handleAddressCheckbox={handleAddressCheckbox}
              addressFieldState={requisitionStateContext}
              handleInputValidations={handleInputValidations}
              visibility={visibilityRequisition}
              feedbackText={feedbackTextRequisition}
              handleOnBlur={handleOnBlur}
            />

            <AddressComponent
              section={mailingSection}
              countriesData={fetchCountriesData.getCatalogsForCategory}
              callingCodes={fetchCountriesCallingCode.getCatalogsForCategory}
              statesData={mailingStates}
              handleFieldChange={handleFieldChange}
              handleAddressCheckbox={handleAddressCheckbox}
              addressFieldState={mailingStateContext}
              handleInputValidations={handleInputValidations}
              visibility={visibilityMailing}
              feedbackText={feedbackTextMailing}
              handleOnBlur={handleOnBlur}
            />

            <AddressComponent
              section={deliverySection}
              countriesData={fetchCountriesData.getCatalogsForCategory}
              callingCodes={fetchCountriesCallingCode.getCatalogsForCategory}
              statesData={deliveryStates}
              handleFieldChange={handleFieldChange}
              handleAddressCheckbox={handleAddressCheckbox}
              addressFieldState={deliveryStateContext}
              handleInputValidations={handleInputValidations}
              visibility={visibilityDelivery}
              feedbackText={feedbackTextDelivery}
              handleOnBlur={handleOnBlur}
              deliveryAddressOption={deliveryAddressOption}
            />

            <div className="address-main-section">
              <div className="address-title">DELIVERY INFORMATION</div>
              <div className="delivery-instruction-input">
                <div className="usa-label">Special delivery instructions</div>
                <TextInput
                  type="textarea"
                  aria-label="Special delivery instructions"
                  data-testid="delivery-instructions-input"
                  onChange={(e) => {
                    handleOnBlur(e.target.value);
                  }}
                  value={specialDeliveryInstructions || ''}
                  name="justification"
                  inputClass="urg-req-input-text"
                />
              </div>

              <div>
                <div className="delivery-address-date-section">
                  <FieldFeedback
                    visible={deliveryDateErrorMessage?.length}
                    id="deliveryDate"
                    applyTo={(child) => child.props.type === 'text'}
                    isVehicleContext={false}
                  >
                    <DatePicker
                      id="deliveryDate"
                      name="deliveryDate"
                      data-testid="deliveryDate"
                      defaultValue={formattedDate || ''}
                      minDate={getCurrentDateFormatted()}
                      errorMessage={deliveryDateErrorMessage}
                      hint="mm/dd/yyyy"
                      label={
                        <>
                          Delivery date
                          <RequiredFieldIndicator />
                        </>
                      }
                      onChange={(e) => {
                        console.log({e});
                        validateDeliveryDate(e);
                        dispatch({
                          type: VehicleRequisitionContextActions.UPDATE_URG_REQUIREMENT_DELIVERY_DATE,
                          payload: e || '',
                        });
                      }}
                    />
                  </FieldFeedback>
                </div>
              </div>
            </div>

            <div>
              <Button
                variant="outline"
                type="button"
                data-testid=""
                onClick={() => {
                  handlePageNavigation('previous');
                }}
                leftIcon={{ name: 'arrow_back' }}
                label="Previous"
              />
              <Button
                variant="default"
                data-testid="urg-requirement-delivery-page-next-btn"
                type="button"
                onClick={() => {
                  handleNext();
                }}
                rightIcon={{ name: 'arrow_forward' }}
                label="Next"
              />
            </div>
          </>
        )}
        {(loadingValidatedRequisitionAddress ||
          !fetchCountriesData ||
          !fetchCountriesCallingCode) && (
          <div className="afp-modal-overlay requisition-loading">
            <Spinner size="large" className="margin-y-8" />
          </div>
        )}
        {showUspsModal &&
          (!_.isEmpty(reqValidatedAddress) ||
            !_.isEmpty(mailingValidatedAddress) ||
            !_.isEmpty(deliveryValidatedAddress)) && (
            <>
              <div className="afp-modal-overlay modalContainer">
                <Modal
                  title={
                    <>
                      <h1>Suggested addresses</h1>
                      <span>
                        You&apos;ve entered new addresses on this page. Please
                        confirm desired address formats <br />
                        before continuing*
                      </span>
                    </>
                  }
                  className="usps-validation-modal"
                  onClose={closeModal}
                  actions={
                    <>
                      <Button
                        type="button"
                        className="cancel-button"
                        onClick={closeModal}
                        label="Cancel"
                      />
                      <Button
                        id="modal-save-button"
                        type="button"
                        data-testid="modal-save-button"
                        onClick={() => {
                          handleSaveClick('continue', 'true');
                        }}
                        onKeyDown={(event) => {
                          if (event.keyCode === 13)
                            handleSaveClick('continue', 'true');
                        }}
                        label="Save and continue"
                      />
                    </>
                  }
                >
                  {!requisitionStateContext.isMilitary &&
                    isReqAddressValidated && (
                      <UspsAddressedComp
                        title="REQUISITION ADDRESS"
                        addressAsEntered={requisitionStateContext}
                        uspsVerifiedAddress={reqValidatedAddress}
                        handleAddressedChange={handleAddressedChange}
                      />
                    )}
                  {!mailingStateContext.isMilitary &&
                    isMailingAddressValidated && (
                      <UspsAddressedComp
                        title="MAILING ADDRESS"
                        addressAsEntered={mailingStateContext}
                        uspsVerifiedAddress={mailingValidatedAddress}
                        handleAddressedChange={handleAddressedChange}
                        isSameAsRequisition={
                          mailingStateContext?.isMailingSameAsRequisition
                        }
                      />
                    )}
                  {isDeliveryAddressValidated && (
                    <UspsAddressedComp
                      title="DELIVERY ADDRESS"
                      addressAsEntered={deliveryStateContext}
                      uspsVerifiedAddress={deliveryValidatedAddress}
                      handleAddressedChange={handleAddressedChange}
                      isSameAsRequisition={
                        deliveryStateContext?.isDeliverySameAsRequisition
                      }
                    />
                  )}
                </Modal>
              </div>
            </>
          )}
        {showConfirmationModal && (
          <>
            <div className="afp-modal-overlay modalContainer">
              <Modal
                title={
                  <>
                    <h1>Address Not Found</h1>
                  </>
                }
                onClose={closeConfirmationModal}
                actions={
                  <>
                    <Button
                      type="button"
                      className="cancel-button"
                      onClick={closeConfirmationModal}
                      label="Cancel"
                    />
                    <Button
                      id="modal-save-button"
                      type="button"
                      data-testid="modal-save-button"
                      onClick={() => {
                        handleSaveClick('continue', 'true', true);
                      }}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13)
                          handleSaveClick('continue', 'true', true);
                      }}
                      label="Save and continue"
                    />
                  </>
                }
              >
                <span>
                  {`${notFoundAddresses} Address not verified in the USPS database. By selecting to
                  continue with this address, you may incur additional time and
                  effort in receiving your vehicle or documents.`}
                </span>
              </Modal>
            </div>
          </>
        )}
      </div>
    </>
  );
};

UrgentRequisitionDeliveryAddress.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
};

UrgentRequisitionDeliveryAddress.defaultProps = {};

export default UrgentRequisitionDeliveryAddress;
