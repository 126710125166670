import React from 'react';
import { useSelector } from 'react-redux';
import DealershipDelivery from './dealership-delivery/dealership-delivery';
import RequisitionLayout from '../../components/requisition-layout/requisition-layout';
import VehicleInformation from '../../components/vehicle-information/vehicle-information';
import MarshallingAddress from './marshalling-address/marshalling-address';

const DeliveryAddress = () => {
  const {
    selectedStandardItem,
    vehicleQuantity,
    compareSelect: {
      addOptions,
      selectedContract,
      calculatedPriceBreakdown,
      selectedDeliveryOption,
      selectedEngine,
    }
  } = useSelector((state) => state.leasingReducer);

  const hasDealerTag = selectedStandardItem?.vehicleTypeCode?.tags?.value?.includes('DEALER');
  const showDealershipDelivery = (
    selectedContract?.makeCode &&
    !selectedDeliveryOption[0]?.optionCode?.startsWith('CNS') &&
    selectedDeliveryOption[0]?.optionCode?.startsWith('DDR')
  ) || (hasDealerTag && !selectedDeliveryOption[0]?.optionCode?.startsWith('CNS'))

  return (
    <RequisitionLayout
      left={
        <VehicleInformation
          contract={selectedContract}
          options={addOptions}
          quantity={vehicleQuantity}
          standardItem={selectedStandardItem}
          selectedContractCostBreakdown={calculatedPriceBreakdown?.find(modelCost => modelCost.contractLineId === selectedContract?.contractLineId)}
          engine={selectedEngine}
        />
      }
      right={
        <div>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}
          >
            {showDealershipDelivery ? (
              <DealershipDelivery />
            ) : (
              <MarshallingAddress />
            )}
          </div>
        </div>
      }
    />
  );
};

export default DeliveryAddress;
