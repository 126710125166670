import React, { useContext } from 'react';
import './AreqOptions.scss';
import { useModal, connectModal, Button } from '@gsa/afp-component-library';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import AreqModal from '../AreqModal/AreqModal';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';

export default function AreqOptions({ colSpanCount, calculatePriceClick }) {
  const { isOpen, openModal, closeModal } = useModal();
  const DisplayModal = connectModal(AreqModal);
  const openAreqModal = () => {
    openModal();
  };
  const {
    state: {
      isAreq,
      currentStandardItem,
      addOptionsState,
      excludeAreqOption,
    },
    setIsAreq,
    dispatch,
  } = useContext(VehicleRequisitionContext);

  const includesNoAreqTag = (obj) => {
    return !!obj?.tags?.value?.includes('NOAREQ');
  };

  const standardItemContainsNoAreqTag = includesNoAreqTag(currentStandardItem);

  const includesNoAreqOption = addOptionsState.some((option) =>
    option.options.some((subOption) => {
      return subOption?.isChecked && includesNoAreqTag(subOption);
    }))

  if (standardItemContainsNoAreqTag) {
    return (
      <tr className="additional-options">
        <td colSpan={colSpanCount}>
          <div className="no-areq">
            <div className="no-areq-heading">
              Additional Requirements are not available for this vehicle type.
            </div>
            <div className="no-areq-text">
              If an AREQ is needed, please select another SIN to fulfill this
              need. If that solution would not work, please contact our Vehicle
              Buying Team at{' '}
              <a href="mailto:vehicle.buying@gsa.gov">vehicle.buying@gsa.gov</a>{' '}
              to discuss other courses of action.
            </div>
          </div>
        </td>
      </tr>
    );
  }

  // https://cm-jira.usa.gov/browse/AFP-103471
  // Hide the Additional Requirements option if the user has selected an option with the NOAREQ tag
  if (includesNoAreqOption) {
    return null;
  }

  const selectAreq = (checked) => {
    // When AREQ is selected, unselect previously selected contract (in case it was selected)
    if (checked) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SELECTED_CONTRACT_INFO,
        selectedContract: null,
        selectedContractCostBreakdown: null,
      });
    }
    calculatePriceClick();
    setIsAreq(checked);
  };

  return (
    <>
      <tr className="additional-options">
        <td className="option-cell" colSpan={colSpanCount}>
          <div className="option-description-wrapper">
            <span className="option-description-column">
              <input
                className="input-checkbox"
                id="additional-options-checkbox"
                type="checkbox"
                checked={isAreq}
                disabled={excludeAreqOption}
                onChange={(e) => selectAreq(e.target.checked)}
              />
              <label htmlFor="additional-options-checkbox">
                <strong>Additional Requirements</strong>
              </label>
            </span>
          </div>

          {excludeAreqOption ? (
            <div className="description">
              No available vehicles support the (AREQ) option code.
              <br />
              <br />
            </div>
          ) : (
            <div className="description">
              The Additional Requirements (AREQ) option code allows customers to
              request specific open market equipment not listed in the Compare &
              Select page. You will be required to provide information detailing
              your additional requirements before sending the requisition to GSA
              for their review.
              <br />
              <br />
              <Button
                variant="unstyled"
                label="Read More"
                onClick={openAreqModal}
              />
              <br />
            </div>
          )}
        </td>
      </tr>

      <DisplayModal isOpen={isOpen} onClose={closeModal} cancel={closeModal} />
    </>
  );
}