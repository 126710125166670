import { ReadonlyModifier, SelectControl } from '@gsa/afp-shared-form-utils';
import React, { useMemo, useState } from 'react';
import { Alert } from '@gsa/afp-component-library';
import { LeasingSteps } from '../../../leasing-consts';
import { emDashUnicode, ComboBoxControl } from '@gsa/afp-shared-form-utils';
import generatePointOfContactForm from '../../../../requisitions-and-orders/change-sets/DeliveryAddress/address-component/point-of-contact-form';
import { Link } from 'react-router-dom';
import useLeasingNavigation from '../../../../../hooks/useLeasingNavigation';


export const AddressModifier =
  (
    { includeTitle } = {
      includeTitle: false,
    }
  ) =>
    (value) => {
      const lines = [
        value?.address1,
        value?.address2,
        [[value?.city, value?.state].filter(Boolean).join(', '), value?.zip]
          .filter(Boolean)
          .join(' '),
        value?.phone,
      ].filter(Boolean);

      return (
        <div>
          {includeTitle && value?.title && <strong>{value.title}</strong>}
          {lines.length === 0
            ? emDashUnicode
            : lines.map((line, index) => <div key={index}>{line}</div>)}
        </div>
      );
    };

export function generateDealershipDeliveryForm({
  countryCodes,
  shipmentLocation,
  deliveryOption,
  standardItemSubCategory,
  deliveryAddress,
  licenseDeliveryAddress,
  optionCityData,
  stateId,
  defaultStates,
  selectedContract,
  getDealershipsByCityStateMake,
  fleetManagementCenters,
  formGeneratorRef,
  selectedCountry,
  shipmentState,
  getCitybyStateId,
  getStateList,
  selectedStandardItem,
}) {
  const hasDealerTag = selectedStandardItem?.vehicleTypeCode?.tags?.value?.includes('DEALER');
  defaultStates?.unshift({
    label: 'Select State',
    value: '-1',
  })
  const hasDeliveryOption = Boolean(deliveryOption[0]);

  const { getUrlForStep } = useLeasingNavigation();
  const defaultValues = useMemo(() => {
    return {
      selectedCountry,
      stateId,
      selectedStateId:shipmentState?.split('-')[1] || deliveryAddress?.selectedStateId ,
      deliveryDealership: {
        selectedCity: deliveryAddress?.deliveryDealership?.selectedCity || '-1',
        selectedDealerId: deliveryAddress?.deliveryDealership?.selectedDealerId || '-1',
      },
      deliveryPoC: {
        firstName: deliveryAddress?.deliveryPoC?.firstName || '',
        lastName: deliveryAddress?.deliveryPoC?.lastName || '',
        email: deliveryAddress?.deliveryPoC?.email || '',
        phone: {
          countryCode: deliveryAddress?.deliveryPoC?.phone?.countryCode || '',
          number: deliveryAddress?.deliveryPoC?.phone?.number || '',
          extension: deliveryAddress?.deliveryPoC?.phone?.extension || '',
        },
        fax: {
          countryCode: deliveryAddress?.deliveryPoC?.fax?.countryCode || '',
          number: deliveryAddress?.deliveryPoC?.fax?.number || '',
          extension: deliveryAddress?.deliveryPoC?.fax?.extension || '',
        },
      },
      fmcLocation: licenseDeliveryAddress?.fmcLocation?.toString(),
      fieldOffice: licenseDeliveryAddress?.fieldOffice?.toString() || '-1',
      fmcOfficeLocationDetails: {
        ...licenseDeliveryAddress?.fmcOfficeLocationDetails,
        fieldOffice: licenseDeliveryAddress?.fmcOfficeLocationDetails?.fieldOffice || '',
        fieldOfficeDeliveryAddress: {
          address1: licenseDeliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.address1 || '',
          address2: licenseDeliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.address2 || '',
          address3: licenseDeliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.address3 || '',
          city: licenseDeliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.city || '',
          state: licenseDeliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.state || '',
          zip: licenseDeliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress?.zip || '',
      },
      },
      licenseDeliveryPoC: {
        firstName: licenseDeliveryAddress?.licenseDeliveryPoC?.firstName || '',
        lastName: licenseDeliveryAddress?.licenseDeliveryPoC?.lastName || '',
        email: licenseDeliveryAddress?.licenseDeliveryPoC?.email || '',
        phone: {
          countryCode: licenseDeliveryAddress?.licenseDeliveryPoC?.phone?.countryCode || '',
          number: licenseDeliveryAddress?.licenseDeliveryPoC?.phone?.number || '',
          extension: licenseDeliveryAddress?.licenseDeliveryPoC?.phone?.extension || '',
        },
        fax: {
          countryCode: licenseDeliveryAddress?.licenseDeliveryPoC?.fax?.countryCode || '',
          number: licenseDeliveryAddress?.licenseDeliveryPoC?.fax?.number || '',
          extension: licenseDeliveryAddress?.licenseDeliveryPoC?.fax?.extension || '',
        },
      },
      selectedDealer: deliveryAddress?.selectedDealer
    };
  }, []);
  const content = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        gap: '40px',
        sections: [
          {
            header: 'Delivery Address',
            element: {
              header: {
                style: {
                  color: '#005EA2',
                  fontSize: '16px',
                  fontWeight: '900',
                  textTransform: 'uppercase',
                  paddingBottom: '5px',
                  borderBottom: '1px solid #DFE1E2',
                  marginBottom: '40px',
                },
              },
            },
            fields: [
              {
                required: true,
                component: () => {
                  return (
                    <Alert type="info">
                      <div className="delivery-banner-info">
                        {hasDeliveryOption ? (
                          <>
                            You've selected <strong>dealership delivery</strong> for this vehicle.
                          </>
                        ) : hasDealerTag ? (
                          <>
                            Dealership delivery is standard for this vehicle{' '}
                          </>
                        ): ''}
                        <span>
                          If no dealerships are available, return to the Compare and Select page to review alternate delivery options, or <strong>contact your Zonal Acquisition Coordinator for guidance.</strong>
                        </span>
                      </div>
                    </Alert>
                  );
                },
              },
            ]
          },
          {
            header: 'Select a state and city to view available dealerships',
            columnWidth: 'col-8-12',
            element: {
              header: {
                style: {
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '25px',
                  marginBottom: '25px',
                  paddingBottom: '0',
                  borderBottom: 'none',
                  
                },
              },
            },
            gap: '40px',
            sections: [{
              fieldLayout: 'horizontal',
              fields: [
              {
                id: 'selectedStateId',
                label: 'Select state/territory',
                type: 'select',
                required: true,
                options: defaultStates,
              },
              {
                id: 'deliveryDealership.selectedCity',
                label: 'Select city',
                type: 'select',
                required: true,
                component: (props, useFormContext) => {
                  const { watch, setError, setValue } = useFormContext();
                  const [updatedCityOptions, setUpdatedCityOptions] = useState(optionCityData);

                  const watchSelectedStateId = watch('selectedStateId');

                  useMemo(async () => {
                    if (!watchSelectedStateId) {
                      return;
                    }
                    setUpdatedCityOptions([{
                      label: 'Select City',
                      value: '-1',
                    }]);
                    getStateList({}).then(({data}) => {
                      const selectedStateId = data?.find(state => state.stateCode === watchSelectedStateId)?.id;
                      if(selectedStateId) {
                        setValue('stateId', selectedStateId);
                        getCitybyStateId({
                          variables: {
                            stateId: parseInt(selectedStateId, 10),
                            make: parseInt(selectedContract?.makeCode, 10),
                            acceptsEv: undefined,
                          },
                        })
                          .then(({ data }) => {
                            if (data) {
                              const cities = data?.reduce((uniqueCities, dealer) => {
                                if (!uniqueCities.has(dealer.city)) {
                                  uniqueCities.add(dealer.city);
                                }
                                return uniqueCities;
                              }, new Set());
                              const citiesData = Array.from(cities).map((city) => ({
                                label: city,
                                value: city,
                              }));
                              citiesData.unshift({
                                label: 'Select City',
                                value: '-1',
                              });
                              setUpdatedCityOptions(citiesData);
                            }
                          })
                          .catch((e) => {
                            console.error(e);
                            setError('deliveryDealership.selectedStateId', {
                              type: 'manual',
                              message: 'Error fetching cities',
                            });
                          });
                      }
                    })


                  }, [watchSelectedStateId]);

                  return SelectControl(
                    {
                      ...props,
                      className: [],
                    },
                    {
                      id: props.id,
                      options: updatedCityOptions,
                    },
                  )
                }
              },
            ] }]
          },
          {
            altStyle: 'alt-blue-border',
            columnWidth: 'col-8-12',
            fields: [
              {
                id: 'deliveryDealership.selectedDealerId',
                label: 'Choose dealership',
                type: 'select',
                required: true,
                component: (props, useFormContext) => {
                  const { watch, setError, setValue, getValues } = useFormContext();
                  const [dealerData, setDealerData] = useState([
                    {
                      label: 'Select Dealership',
                      value: '-1',
                    },
                  ]);
                  const [dealerList, dealerListTo] = useState(null);

                  const watchCity = watch('deliveryDealership.selectedCity');
                  const watchStateId = watch('stateId') || stateId;
                  useMemo(async () => {
                    // clearErrors();
                    if (!watchCity || !watchStateId) {
                      return;
                    }
                    getDealershipsByCityStateMake({
                      variables: {
                        state: parseInt(watchStateId, 10),
                        city: watchCity,
                        make: parseInt(selectedContract?.makeCode, 10),
                        acceptsEv: undefined,
                      },
                    })
                      .then(({ data }) => {
                        if (data) {
                          dealerListTo(data);
                          setDealerData([
                            { label: 'Select Dealership', value: '-1' },
                            ...data
                              .map(({ dealerName, id }) => ({
                                label: dealerName,
                                value: id,
                              }))
                              .sort((a, b) => a.label.localeCompare(b.label)),
                          ]);


                          const dealer = data?.find((x) => {
                            return x.id === parseInt(getValues('deliveryDealership.selectedDealerId'), 10);
                          });

                          if (!dealer) {
                            setValue('deliveryDealership.selectedDealerId', '-1');
                            setValue('selectedDealer', undefined);
                          }
                        }
                      })
                      .catch((e) => {
                        console.error(e);
                        setError('deliveryDealership.selectedDealerId', {
                          type: 'manual',
                          message: 'Error fetching states',
                        });
                      });
                  }, [watchCity]);

                  return SelectControl(
                    {
                      ...props,
                      className: [],
                      value: getValues('deliveryDealership.selectedDealerId'),
                    },
                    {
                      id: props.id,
                      options: dealerData,
                      element: {
                        control: {
                          onChange: (value) => {
                            const dealer = dealerList?.find((x) => {
                              return x.id === parseInt(value, 10);
                            });

                            if (!dealer) {
                              setValue('selectedDealer', undefined);
                            } else {
                              setValue('selectedDealer', {
                                address1: dealer.address1,
                                address2: dealer.address2,
                                city: dealer.city,
                                country: dealer.country.countryCode,
                                dealerName: dealer.dealerName,
                                title: dealer.dealerName,
                                dealershipDeliveryCode: dealer.dealershipDeliveryCode,
                                email: dealer.email,
                                id: dealer.id,
                                phone: dealer.phone,
                                zip: dealer.postalCode,
                                state: dealer.state.stateCode,
                                stateId: dealer.state.id,
                                countryId: dealer.country.id,
                              });
                            }
                          }
                        }
                      }
                    },
                  )
                }
              },
              {
                dependentOn: 'selectedDealer',
                dependentValue: (value) => {
                  return value !== undefined
                },
                dependentWaitType: 'hidden',
                type: 'readonly',
                id: 'selectedDealer',
                element: {
                  control: {
                    rawModifierValue: true,
                    modifier: AddressModifier({
                      includeTitle: true,
                    })
                  }
                }
              }
            ]
          }
        ],
      },
      generatePointOfContactForm({
        title: 'Delivery point of contact',
        countryCodes,
        subSection: true,
        idPrefix: 'deliveryPoC',
      }),
      {
        header: 'License Plate Delivery Address',
        dependentOn: 'selectedCountry',
        dependentValue: (value) => {
          return (value === 'US' || value === 'PR')
        },
        element: {
          header: {
            style: {
              color: '#005EA2',
              fontSize: '16px',
              fontWeight: '900',
              textTransform: 'uppercase',
              paddingBottom: '5px',
              borderBottom: '1px solid #DFE1E2',
            },
        },
        },
        gap: '40px',
        sections: [
          {
            gap: '40px',
            sections: [
              {
                gap: '40px',
                fields: [
                  {
                    label: 'FMC location',
                    id: 'fmcLocation',
                    type: 'combobox',
                    options: [
                      { label: '-select-', value: '-1' },
                      ...fleetManagementCenters
                        .map((fmc) => ({ label: fmc.name, value: fmc.id }))
                        .sort((a, b) => a.label.localeCompare(b.label)),
                    ],
                    element: {
                      control: {
                        className: 'full-width-combobox',
                        onChange: (value) => {
                          if (!value || value === '-1') {
                            return;
                          }

                          const fmc = fleetManagementCenters.find(
                            (fmc) => fmc.id === value,
                          );
                          formGeneratorRef.current.setValue('fieldOffices', fmc?.fieldOffices);
                          formGeneratorRef.current.setValue('fieldOffice', '-1');
                          formGeneratorRef.current.setValue('fmcOfficeLocationDetails', {
                            location: fmc.name,
                            deliveryAddress: {
                              address1: fmc.address1,
                              address2: fmc.address2,
                              address3: fmc.address3,
                              city: fmc.city,
                              state: fmc.stateCode,
                              zip: fmc.zipCode,
                            },
                          });
                        },
                      },
                    },
                  },
                  {
                    dependentOn: 'fmcLocation',
                    dependentValue: (value) => {
                        return fleetManagementCenters?.find(fmc => fmc.id === value)?.fieldOffices?.length > 1
                    },
                    dependentWaitType: 'hidden',
                    label: 'Field office',
                    id: 'fieldOffice',
                    type: 'select',
                    required: false,

                    component: (props, useFormContext) => {
                        const { watch } = useFormContext();
                        const [fieldOfficeData, setFieldOfficeData] = useState();
                        const watchFmcLocation = watch('fmcLocation');
                        const watchfmcOfficeLocationDetails = watch('fmcOfficeLocationDetails')
                        useMemo(async () => {
                            if (!watchFmcLocation && watchFmcLocation === '-1') {
                                return;
                            }

                            const fmc = fleetManagementCenters.find(fmc => fmc.id === watchFmcLocation);
                            setFieldOfficeData([
                                { label: '-select-', value: '-1' },
                                ...fmc?.fieldOffices?.map(fmc => ({ label: fmc.name, value: fmc.id }))
                                    .sort((a, b) => a.label.localeCompare(b.label))
                            ])
                        }, [watchFmcLocation]);

                        return ComboBoxControl(
                            {
                                ...props,
                                className: [],
                            },
                            {
                                ...props,
                                options: fieldOfficeData,
                                element: {
                                    control: {
                                        onChange: (value) => {
                                            if (!value || value === '-1') {
                                                return;
                                            }

                                            const fieldOffice = fleetManagementCenters.find(fmc => fmc.id === watchFmcLocation)?.fieldOffices?.find(fo => fo.id == value);
                                            formGeneratorRef.current.setValue('fmcOfficeLocationDetails', {
                                                location: watchfmcOfficeLocationDetails.location,
                                                fieldOffice: fieldOffice?.name,
                                                deliveryAddress: watchfmcOfficeLocationDetails.deliveryAddress,
                                                fieldOfficeDeliveryAddress: {
                                                    address1: fieldOffice.address1,
                                                    address2: fieldOffice.address2,
                                                    address3: fieldOffice.address3,
                                                    city: fieldOffice.city,
                                                    state: fieldOffice?.stateCode,
                                                    zip: fieldOffice.zipCode,
                                                }
                                            });
                                        }
                                    }
                                }
                            }
                        )
                    },
                }
                ],
              }
            ]
          },
          {
            dependentOn: 'fmcLocation',
            dependentValue: (value) =>  {
                return value && value !== '-1';
            },
            altStyle: 'alt-blue-border',
            gap: '30px',
            sections: [
                {
                    fieldLayout: 'horizontal',
                    fields: [
                        {
                            label: "FMC location",
                            id: 'fmcOfficeLocationDetails.location',
                            type: 'readonly',
                        },
                        {
                            label: 'Delivery address',
                            id: 'fmcOfficeLocationDetails.deliveryAddress',          
                            dependentOn: 'fieldOffice',
                            dependentWaitType: 'hidden',
                            dependentValue: (value) => {
                                return !value || value === '-1';
                            },
                            type: 'readonly',
                            element: {
                                control: {
                                    modifier: ReadonlyModifier.AddressModifier(),
                                    rawModifierValue: true,
                                }
                            }
                        },                                            
                        {
                            label: 'Delivery address',
                            id: 'fmcOfficeLocationDetails.fieldOfficeDeliveryAddress',          
                            dependentOn: 'fieldOffice',
                            dependentWaitType: 'hidden',
                            dependentValue: (value) => {
                                return value && value !== '-1';
                            },
                            type: 'readonly',
                            element: {
                                control: {
                                    modifier: ReadonlyModifier.AddressModifier(),
                                    rawModifierValue: true,
                                }
                            }
                        },  
                    ]
                },
                {
                  fieldLayout: 'horizontal',
                  fields: [
                      {
                          label: "Field Office",
                          dependentOn: 'fieldOffice',
                          dependentWaitType: 'hidden',
                          dependentValue: (value) => {
                              return value && value !== '-1';
                          },
                          id: 'fmcOfficeLocationDetails.fieldOffice',
                          type: 'readonly',
                      },]
              }
            ]
          },{
                ...generatePointOfContactForm({
                  title: 'Point of Contact',
                  subSection: true,
                  countryCodes,
                  idPrefix: 'licenseDeliveryPoC',
                }),
              },
        ],
      },
    ],
  };

  return [content, defaultValues];
}
