/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { AFPTableRowAction } from '@gsa/afp-component-library';
import moment from 'moment';
import { emDashUnicode } from '@gsa/afp-shared-form-utils';

const rowActions = [];

export const ActionColumn = (props) => {
  return (
    <AFPTableRowAction
      actions={rowActions}
      actionTitle="Standard Item Action"
      onSelectAction={() => {
        // do something with the label action
      }}
      {...props}
    />
  );
};

const getTempContractShipmentDate = (row) => {
  const daysOffset =
    (row.baseShipmentDays ? row.baseShipmentDays : 0) +
    (row.additionalShipmentDays ? row.additionalShipmentDays : 0);
  return moment(row.createdAt).add(daysOffset, 'days').format('MM/DD/YYYY');
};

export const getTableColumns = (row) => {
  return {
    orderId: row.orderId,
    case_number: row.caseNumber || emDashUnicode,
    friendlyName: row.friendlyName || emDashUnicode,
    order_status: row.orderStatus,
    createdAt: moment(row.createdAt).format('MM/DD/YYYY'),
    submitted_to_vendor_at: row.submittedToVendorAt
      ? moment(row.submittedToVendorAt).format('MM/DD/YYYY')
      : emDashUnicode,
    contractShipmentDate: row.shipmentDate
      ? moment(row.shipmentDate).format('MM/DD/YYYY')
      : getTempContractShipmentDate(row),
    vehicleTypeCode: row?.vehicleTypeCode,
    bureauCode: row.bureauCode || emDashUnicode,
    agencyCode: row.agencyCode || emDashUnicode,
    modelCode: row?.modelCode || emDashUnicode,
    quantity: row.quantity,
    totalSellingPrice: row.totalSellingPrice || emDashUnicode,
    standardItemCode: row.standardItemCode || emDashUnicode,
    createdByUser: row.createdUserInfoFirstName
      ? `${row.createdUserInfoFirstName} ${row.createdUserInfoLastName}`
      : emDashUnicode,
    createdByUserEmail: row.createdUserInfoEmail || emDashUnicode,
    bureau: row.bureauInfoName || emDashUnicode,
    office: row.officeInfoName || emDashUnicode,
    upiidNumber: row.upiidNumber || emDashUnicode,
    model: row.modelInfo?.modelName || emDashUnicode,
    approvedByUser: row.approvedByUserInfoFirstName
      ? `${row.approvedByUserInfoFirstName} ${row.approvedByUserInfoLastName}`
      : emDashUnicode,
    approvedByUserEmail: row.approvedByUserInfoEmail || emDashUnicode,
    agencyInfoName: row.agencyInfoName || emDashUnicode,
    mvdoFileMetadataId: row.mvdoAttachmentMetadataId,
    mvdoFileMetaData: row.mvdoAttachmentFileMetadata,
    agencyOrderNumber: row.customerAssignedNumber || emDashUnicode,
    requisitionNumber: row.requisitionNumber || emDashUnicode,
    requisitionType: row.requisitionType || 1,
    requisitionId: row.requisitionId,
    transactionType: row.transactionType || 'VEHICLE_SALE',
  };
};

ActionColumn.propTypes = {
  value: PropTypes.instanceOf(Array).isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
};
