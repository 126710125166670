const getRequisitionAddressAndContact = (addressStateContext) => {
  const {
    firstName,
    lastName,
    countryCode,
    entityName,
    addressLine1,
    addressLine2,
    city,
    stateCode,
    zipcode,
    zipcodePlusFour,
    email,
    phoneCountryCode,
    phoneNumber,
    phoneExtension,
    faxNumber,
    faxExtension,
    faxCountryCode,
    isValidated,
    isMilitary,
    rankAndFullName,
    militaryOrDiplomaticAddress,
    postOffice,
  } = addressStateContext;

  const address = {
    countryCode,
    entityName: isMilitary ? rankAndFullName : entityName,
    stateCode,
    addressLine1: isMilitary ? militaryOrDiplomaticAddress : addressLine1,
    addressLine2: isMilitary ? postOffice : addressLine2,
    zipcode,
    zipcodePlusFour,
    city,

    isUspsVerified: isValidated,
    isMilitary,
  };
  const contact = {
    firstName,
    lastName,
    email,
    faxCountryCode,
    faxNumber,
    faxExtension,
    phoneCountryCode,
    phoneNumber,
    phoneExtension,
  };

  return { address, contact };
};

export const isDealerSin = (currentStandardItem) => {
  if (
    !currentStandardItem ||
    !currentStandardItem.vehicleTypeCode ||
    !currentStandardItem.vehicleTypeCode.tags ||
    !currentStandardItem.vehicleTypeCode.tags.value
  )
    return false;
  return currentStandardItem.vehicleTypeCode.tags.value.some(
    (tag) => tag.toLowerCase() === 'dealer',
  );
};

/**
 * Utility method to create createDraft data given a state
 * @param {*} state
 * @returns
 */
export const createRequisition = (state, draftFriendlyName) => {
  const {
    vehicleColors,
    vehicleQuantity,
    selectedContract,
    selectedOptionsForPrice,
    nonLowBidJustification,
    currentStandardItem,
    currentStep,
    allActiveContracts,
    selectedContractAgencyInformation,
    requisitionStateContext,
    mailingStateContext,
    deliveryStateContext,
    dealershipDeliveryContext,
    submitComment,
    paintAndGraphicsOptions,
    totalUploadedFiles,
    taggedOptions,
    engineerTaggedOptions,
    isDomesticShipment,
    selectedContractCostBreakdown,
    areqJustification,
    masReqStepsProcessIndicator,
    masRequirementCurrentStep,
    //   additionalRequirements,
    agencyReferenceNumber,
    //   isAreq,
    //   specialDeliveryInstructions,
    //   deliveryDate,
    //   urgentRequirementJustification,
    //   nonSopData,
    urgentReqStepsProcessIndicator,
    urgentRequirementCurrentStep,
    urgentReqJustification,
    requisitionType,
    preDraftComments,
    selectedEngine,
    deliveryOptions,
    unavailableOptions,
  } = state;

  const deliveryAddressOption = deliveryOptions
  .flatMap((option) => option.options)
  .find((c) => c.isChecked === true);

  const isDealerDelivery = deliveryAddressOption?.optionCode?.startsWith('DDR') || !deliveryAddressOption?.optionCode?.startsWith('CNS') && isDealerSin(currentStandardItem);
  const selectedColorsForDraft = vehicleColors?.map(
    ({ color, quantity: colorQuantity }) => {
      return {
        makeColorCode: color?.value,
        makeColorName: color?.label,
        makeColorPriceToCustomer: color?.price,
        makeColorPriceToGsa: color?.vendorPrice,
        quantity: Number(colorQuantity),
      };
    },
  );

  const createInput = {
    friendlyName: draftFriendlyName,
    requisitionType,
    agencyCode: sessionStorage.getItem('agencyCode'),
    bureauCode: sessionStorage.getItem('bureauCode'),
    officeCode: sessionStorage.getItem('officeCode'),
    transactionType: 'VEHICLE_SALE',
    requisitionActivities: preDraftComments.map((comment) => {
      const { createdUserInfo, ...rest } = comment;
      return { ...rest };
    }),
  };

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    phoneCountryCallingCode,
    phoneExtension,
    faxCountryCallingCode,
    faxNumber,
    faxExtension,
  } = selectedContractAgencyInformation;

  //  if (firstName || lastName || email || phoneNumber) {
  const contactForDraft = {
    firstName,
    lastName,
    email,
    phoneCountryCode: phoneCountryCallingCode,
    phoneNumber,
    phoneExtension,
    faxCountryCode: faxCountryCallingCode,
    faxNumber,
    faxExtension,
  };

  const receivingAgency = sessionStorage.getItem('receivingAgency');
  const receivingBureau = sessionStorage.getItem('receivingBureau');
  const receivingOffice = sessionStorage.getItem('receivingOffice');

  let baseShipmentDays = 0;
  let additionalShipmentDays = 0;

  if (selectedContractCostBreakdown) {
    baseShipmentDays = selectedContractCostBreakdown.baseShippingDays;
    additionalShipmentDays =
      selectedContractCostBreakdown.additionalShippingDays
        ?.additionalShippingDays;
  }

  const isLowPrice = allActiveContracts && selectedContract?.contractLineId === allActiveContracts[0]?.contractLineId;
  const requisitionExtendedProps = {
    purchasingForAgencyCode:
      receivingAgency?.length > 0
        ? receivingAgency.substring(0, receivingAgency.indexOf('-'))
        : '',
    purchasingForBureauCode:
      receivingBureau?.length > 0
        ? receivingBureau.substring(0, receivingBureau.indexOf('-'))
        : '',
    purchasingForOfficeCode:
      receivingOffice?.length > 0
        ? receivingOffice.substring(0, receivingOffice.indexOf('-'))
        : '',
    agencyReferenceNumber,
    customerAssignedNumber:
      selectedContractAgencyInformation?.agencyOrderNumber,
    boac: selectedContractAgencyInformation.requisitionBOAC,
    standardItemId: parseInt(currentStandardItem?.id, 10),
    justification: nonLowBidJustification,
    julianDate: selectedContractAgencyInformation.requisitionJulian,
    serialNumber: selectedContractAgencyInformation.requisitionSerialNumber,
    additionalShipmentDays,
    baseShipmentDays,
    requisitionerAddress: getRequisitionAddressAndContact(
      requisitionStateContext,
    ).address,
    requisitionerContact: getRequisitionAddressAndContact(
      requisitionStateContext,
    ).contact,
    mailingAddress: mailingStateContext.isMailingSameAsRequisition
      ? getRequisitionAddressAndContact(requisitionStateContext).address
      : getRequisitionAddressAndContact(mailingStateContext).address,
    mailingContact: mailingStateContext.isMailingSameAsRequisition
      ? getRequisitionAddressAndContact(requisitionStateContext).contact
      : getRequisitionAddressAndContact(mailingStateContext).contact,
    deliveryAddress: deliveryStateContext.isDeliverySameAsRequisition
      ? getRequisitionAddressAndContact(requisitionStateContext).address
      : getRequisitionAddressAndContact(deliveryStateContext).address,
    deliveryContact: deliveryStateContext.isDeliverySameAsRequisition
      ? getRequisitionAddressAndContact(requisitionStateContext).contact
      : getRequisitionAddressAndContact(deliveryStateContext).contact,
    deliveryDealershipCode: dealershipDeliveryContext?.dealershipDeliveryCode,
    isDealerDelivery: isDealerDelivery,
    isLowPrice,
    contractLineId: selectedContract
      ? parseInt(selectedContract?.contractLineId, 10)
      : null,
    quantity: vehicleQuantity ? Number(vehicleQuantity) : null,
    agencyContact: contactForDraft,
    finSignalCode: selectedContractAgencyInformation.signalCode,
    finFundCode: selectedContractAgencyInformation.fundCode,
    finServiceCode: selectedContractAgencyInformation.serviceCode,
    finSupplementaryAddress:
      selectedContractAgencyInformation.signalSupplementaryAddress,
    markForInformation: selectedContractAgencyInformation.markForInformation,
    transportationControlNumber:
      selectedContractAgencyInformation.transportationControlNumber,
    nationalStockNumber: selectedContractAgencyInformation.nationalStockNumber,
    registrationNumber: selectedContractAgencyInformation.registrationNumber,
    treasuryAccountSymbol:
      selectedContractAgencyInformation.treasuryAccountSymbol,
    accountingClassificationReferenceNumber:
      selectedContractAgencyInformation.accountingClassificationReferenceNumber,
    finAdditionalInfo: selectedContractAgencyInformation.agencyFinancialData,
    vehicleTypeCode: currentStandardItem?.vehicleTypeCode?.parentCode,
    standardItemCode: currentStandardItem?.standardItemNumber,
    makeCode: selectedContract?.makeCode,
    modelCode: selectedContract?.modelCode,
    modelYear:
      selectedContract?.modelYear,
    isOverseasDeliveryOptionSelected: selectedOptionsForPrice.some(
      (opt) => opt.optionCode === '1611',
    ),
  };

  const selectedState = sessionStorage.getItem('selectedState');
  const isDomesticShipmentValue =
    isDomesticShipment || selectedState !== 'Select State';

  const clientData = {
    selectedOptions: {
      selectedOptions: selectedOptionsForPrice,
      supportingDocuments: totalUploadedFiles,
      customerInputs: {
        paintAndGraphicsOptions,
        taggedOptions,
        engineerTaggedOptions,
      },
    },
    clientState: {
      submitComment,
      currentStep,
      urgentRequirement: {
        urgentReqStepsProcessIndicator,
        urgentRequirementCurrentStep,
        urgentReqJustification,
      },
      areq: {
        areqJustification,
      },
      masRequirement: {
        masReqStepsProcessIndicator,
        masRequirementCurrentStep,
      },
      lowestBidContract: {
        contract: allActiveContracts && allActiveContracts[0].contract,
        contractNumber:
          allActiveContracts && allActiveContracts[0].contractNumber,
        scheduleLine: allActiveContracts && allActiveContracts[0].scheduleLine,
        contractLineId:
          allActiveContracts && allActiveContracts[0].contractLineId,
      },
      isDomesticShipment: isDomesticShipmentValue,
      selectedState,
      shipmentLocation: sessionStorage.getItem('shipmentLocation'),
      validatedTas: selectedContractAgencyInformation?.treasuryAccountSymbol,
      unavailableOptions: unavailableOptions || [],
    },
  };

  const updateInput = {
    extendedProps: requisitionExtendedProps,
    vehicle: {
      ...selectedColorsForDraft[0],
      ...(selectedEngine?.fuelType && {fuelType: selectedEngine?.fuelType}),
      ...(selectedEngine?.fuelTypeText && {fuelTypeText: selectedEngine?.fuelTypeText}),
      ...(selectedEngine?.convMpgCity && {convMpgCity: selectedEngine?.convMpgCity}),
      ...(selectedEngine?.convMpgHighway && {convMpgHighway: selectedEngine?.convMpgHighway}),
      ...(selectedEngine?.convMpgCombined && {convMpgCombined: selectedEngine?.convMpgCombined}),
      ...(selectedEngine?.convGpm && {convGpm: selectedEngine?.convGpm}),
      ...(selectedEngine?.altMpgCity && {altMpgCity: selectedEngine?.altMpgCity}),
      ...(selectedEngine?.altMpgHighway && {altMpgHighway: selectedEngine?.altMpgHighway}),
      ...(selectedEngine?.altMpgCombined && {altMpgCombined: selectedEngine?.altMpgCombined}),
      ...(selectedEngine?.altGpm && {altGpm: selectedEngine?.altGpm}),
      ...(selectedEngine?.isLowGreenhouseGas && {isLowGreenhouseGas: selectedEngine?.isLowGreenhouseGas}),
      ...(selectedEngine?.elecMpgCity && {elecMpgCity: selectedEngine?.elecMpgCity}),
      ...(selectedEngine?.elecMpgHighway && {elecMpgHighway: selectedEngine?.elecMpgHighway}),
      ...(selectedEngine?.elecMpgCombined && {elecMpgCombined: selectedEngine?.elecMpgCombined}),
    },
    clientData,
  };

  const createVariables = { createInput, updateInput };

  return createVariables;
};
