import z from 'zod';

export const agencyInformationSchema = z.object({
  agency: z.coerce.string(),
  bureau: z.coerce.string(),
  office: z.coerce.string(),

  agencyOrderNumber: z.coerce
    .string()
    .min(1, {
      message: 'Please fill out the agency order number field',
    })
    .max(15, {
      message:
        'The agency order number must be alphanumeric and 15 characters long',
    })
    .regex(/^[a-zA-Z0-9-_ ]+$/, {
      message:
        'The agency order number must be alphanumeric',
    })
    .refine((s) => !s.startsWith('-'), 'Invalid agency order number')
    .refine((s) => !s.startsWith('_'), 'Invalid agency order number'),

  // requisition number
  requisition: z.object({
    boac: z.coerce
      .string()
      .min(6, "You've entered an incorrect requisition number")
      .regex(/^[a-zA-Z0-9]+$/, {
        message: 'The BOAC must be alphanumeric',
      }),
    julian: z.coerce
      .string()
      .min(4, 'Invalid julian date')
      .regex(/^[0-9]+$/, {
        message: 'The julian date must be numeric',
      })
      .refine(
        (value) => {
          const numberValue = parseInt(value.substring(1), 10);

          return (
            !Number.isNaN(numberValue) &&
            !(numberValue < 1 || numberValue > 365)
          );
        },
        {
          message: 'Invalid julian date',
        },
      ),
    serialNumber: z.coerce
      .string()
      .min(4, 'Invalid serial number')
      .regex(/^[a-zA-Z0-9]+$/, {
        message: 'The serial number must be alphanumeric',
      }),
  }),

  treasuryAccountSymbol: z.coerce.string(),

  // Signal code information
  signalCode: z.coerce.string().refine((value) => value !== '-1', {
    message: 'Please select a signal code',
  }),
  serviceCode: z.coerce.string().nullish(),
  fundCode: z.coerce
    .string()
    .min(1, {
      message: 'Please fill out the fund code',
    })
    .nullish(),
  supplementaryAddressCode: z.coerce
    .string()
    .min(1, {
      message: 'Please fill out the supplementary address code',
    })
    .regex(/^[a-zA-Z0-9]+$/, {
      message: 'The supplementary address code must be alphanumeric',
    })
    .nullish(),

  // contact information
  contact: z.object({
    firstName: z.coerce
      .string()
      .min(1, {
        message: 'Please fill out the first name',
      })
      .regex(/^[A-Za-z]+$/, {
        message: 'Invalid first name',
      }),
    lastName: z.coerce
      .string()
      .min(1, {
        message: 'Please fill out the last name',
      })
      .regex(/^[A-Za-z]+$/, {
        message: 'Invalid last name',
      }),
    email: z.coerce
      .string()
      .min(1, {
        message: 'Please fill out the email',
      })
      // https://github.com/GSA/afp-vms-web/blob/d6161a74b642f4057cd53f28bdfb90cebc64fdc4/src/utilities/validation.js#L42
      .regex(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        {
          message: 'Email must be a valid email address',
        },
      ),
    phone: z
      .object({
        countryCode: z.coerce
          .string()
          .min(1, {
            message: 'Please fill out the country code',
          })
          .refine(
            (data) => data !== 'undefined',
            'Please fill out the country code',
          ),
        number: z.coerce
          .string()
          .min(1, {
            message: 'Please fill out the phone number',
          }),
        extension: z.coerce.string(),
      })
      .superRefine((val, ctx) => {
        // check if selected country is US
        if (val.countryCode === '1' || val.countryCode === '') {
          if (
            !new RegExp(/^[(]?[0-9]{3}[)-\s]?[0-9]{3}[-\s]?[0-9]{4}$/).test(
              val.number,
            ) 
            &&
            !(val.number.length === 10 )
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid phone number',
              path: ['number'],
              fatal: true,
            });
          }
        } else if (val.countryCode !== '1' && (val.number.length < 8)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid phone number',
            path: ['number'],
            fatal: true,
          });
        }
        if (`${val?.extension}`.length > 0) {
          if (!new RegExp(/^(0|[1-9][0-9]*)$/).test(val.extension)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid extension number',
              path: ['extension'],
              fatal: true,
            });
          }
        }
      }),
    fax: z
      .object({
        countryCode: z.coerce.string(),
        number: z.coerce.string(),
        extension: z.coerce.string(),
      })
      .superRefine((val, ctx) => {
        if (`${val?.extension}`.length > 0) {
          if (!new RegExp(/^(0|[1-9][0-9]*)$/).test(val.extension)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid extension number',
              path: ['extension'],
              fatal: true,
            });
          }
        }
      }),
  }),

  dod: z
    .object({
      transportationControlNumber: z.coerce.string().max(30, {
        message:
          'Transportation control number must be less than 30 characters',
      }),
      markForInformation: z.coerce.string().max(30, {
        message: 'Mark for information must be less than 30 characters',
      }),
      nationalStockNumber: z.coerce.string().max(16, {
        message: 'National stock number must be less than 16 characters',
      }),
      registrationNumber: z.coerce.string().max(20, {
        message: 'Registration number must be less than 20 characters',
      }),
      accountingClassificationReferenceNumber: z.coerce.string().max(2, {
        message:
          'Accounting classification reference number must be less than 2 characters',
      }),
    })
    .nullish(),

  additionalAreqFunds: z.coerce.number().nullish(),

  // agency financial data
  agencyFinancialData: z.coerce.string().max(100, {
    message: 'Agency financial data must be less than 100 characters',
  }),
});
