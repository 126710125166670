/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import {
  AFPTable,
  EmptyState,
  Tag,
  AFPTableRowAction,
  useModal,
  connectModal,
} from '@gsa/afp-component-library';
import { useLazyQuery, useMutation } from '@apollo/client';
import './RequisitionsAndOrders.scss';
import { OrderModSteps } from './BulkOrderModProvider';
import useBulkOrderModsState from './useBulkOrderModsState';
import CancelOrderModChangeSetModal from './CancelOrderModChangeSetModal';
import {
  CANCEL_ORDER_MOD_CHANGESET,
  GET_CHANGE_SET_BY_ID,
  GET_ORDER_MOD_CHANGESETS,
} from '../../services/data-layer';
import { OrderModStatus } from './BulkOrderModTableHelper';
import { ChangeSetSubComponent } from '../order-modification-detail/components/OrderModDetailsHelper';

const STATUS = {
  MODIFY: {
    LABEL: 'MODIFICATION',
    COLOR: 'Ready-Dark',
  },
  TERMINATE: {
    LABEL: 'TERMINATION',
    COLOR: 'Urgent-Dark',
  },
};
const ChangeSetModTable = () => {
  const [sort, setSort] = useState([['document', 'ASC']]);
  const [actionList, setActionList] = useState([
    {
      icon: 'edit',
      label: 'Edit',
    },
    {
      icon: 'cancel',
      label: 'Cancel',
    },
  ]);
  const {
    changeSets,
    setChangeSets,
    setCurrentStep,
    setCurrentChangeSet,
    terminatedChangeSets,
    setChangeSetId,
    currentChangeSet,
    setSelectedOrdersForMod,
    updatedDraft,
    setCurrentChangeSetType,
    setOrderModRows,
    setSelectedFilters,
    setAgencyContact,
    getOptionsData,
    setOrderChanges,
  } = useBulkOrderModsState();
  const setTableSort = (newSort) => {
    setSort([newSort.split(' ')]);
  };
  const cancelModal = useModal();
  const DisplayCancelModal = connectModal(CancelOrderModChangeSetModal);
  const [getChangeSetDetails] = useLazyQuery(GET_CHANGE_SET_BY_ID, {
    onCompleted: async ({ getChangeSetById }) => {
      if (getChangeSetById) {
        if (getChangeSetById.changeSetOrders.length === 0) {
          setCurrentStep(OrderModSteps.SELECT_ORDER_MOD);
          return;
        }

        setCurrentStep(OrderModSteps.UPDATE_ORDER_MOD);
        setChangeSetId(getChangeSetById?.orderChangeSetModId);
        setCurrentChangeSet((prev) => ({ ...prev, ...getChangeSetById }));
        setCurrentChangeSetType(getChangeSetById?.type);

        const selectedOrders = getChangeSetById.changeSetOrders?.map(
          (changeSetOrder) => {
            return {
              caseNumber: changeSetOrder?.order?.caseNumber,
              standardItemId: changeSetOrder?.order?.standardItemId,
              orderId: changeSetOrder?.orderId,
              quantity: changeSetOrder?.order?.quantity,
              unitPriceToCustomer: changeSetOrder?.order?.unitPriceToCustomer,
              scheduleLine: changeSetOrder?.order?.scheduleLine,
              contractLineId: changeSetOrder?.order?.contractLineId,
              requisitionType: changeSetOrder?.order?.requisitionType,
              transactionType: changeSetOrder?.order?.transactionType,
              requisitionNumber: changeSetOrder?.order?.requisitionNumber,
              orderVehicles: changeSetOrder?.order?.orderVehicles,
              requisitionerAddress: changeSetOrder?.order?.requisitionerAddress,
              requisitionerContact: changeSetOrder?.order?.requisitionerContact,
              mailingAddress: changeSetOrder?.order?.mailingAddress,
              mailingContact: changeSetOrder?.order?.mailingContact,
              deliveryAddress: changeSetOrder?.order?.deliveryAddress,
              deliveryContact: changeSetOrder?.order?.deliveryContact,
              entityName: changeSetOrder?.order?.bureauInfo?.name,
              message1: changeSetOrder?.order?.message1,
              message2: changeSetOrder?.order?.message2,
              message3: changeSetOrder?.order?.message3,
              message4: changeSetOrder?.order?.message4,
              message5: changeSetOrder?.order?.message5,
              rest: { ...changeSetOrder?.order },
            };
          },
        );

        setSelectedOrdersForMod(selectedOrders);

        // const orderIds = selectedOrders.map((order) => order.orderId);
        const firstContractLineId = selectedOrders[0]?.contractLineId;
        if (firstContractLineId) {
          getOptionsData({
            variables: {
              contractLineId: firstContractLineId,
            },
          });
        }

        setAgencyContact(getChangeSetById?.modifications?.contact);
        setOrderChanges((prev) => ({
          ...prev,
          ...getChangeSetById.modifications,
        }));
      }
    },
  });
  const onCancelModalClose = () => {
    cancelModal.closeModal();
  };
  const [getChangeSets] = useLazyQuery(GET_ORDER_MOD_CHANGESETS, {
    onError: (error) => {
      console.log(error);
      onCancelModalClose();
    },
    onCompleted: async (data) => {
      if (data?.getOrderModChangeSets?.length > 0) {
        setChangeSets(data?.getOrderModChangeSets);
      } else {
        setSelectedOrdersForMod([]);
        setChangeSets([]);
        setCurrentChangeSet({});
        setCurrentStep(2);
      }
    },
  });

  const [cancelOrderModCSMutation] = useMutation(CANCEL_ORDER_MOD_CHANGESET, {
    onError: (error) => {
      console.log(error);
      onCancelModalClose();
    },
    onCompleted: async (data) => {
      if (data) {
        if (changeSets?.length === 1) {
          setOrderModRows([]);
          setCurrentChangeSet({
            type: 'MODIFY',
          });
          setSelectedOrdersForMod([]);
          setCurrentChangeSetType('MODIFY');
          setSelectedFilters([]);
        }

        await getChangeSets({
          variables: {
            modificationId: updatedDraft?.orderModificationId,
          },
          fetchPolicy: 'network-only',
        });
        onCancelModalClose();
      }
    },
  });
  const onSubmit = () => {
    if (currentChangeSet) {
      cancelOrderModCSMutation({
        variables: {
          changeSetId: currentChangeSet?.orderChangeSetModId,
        },
      });
    }
  };
  const handleSelectedRow = async (event, row) => {
    setCurrentChangeSet({
      ...row.original,
      changeSetNumber: row.original.index + 1,
    });
    if (event === 'Cancel') {
      cancelModal.openModal();
    }
    if (event === 'Edit') {
      await getChangeSetDetails({
        variables: {
          changeSetId: row.original?.orderChangeSetModId,
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  const changeSetOrders = useMemo(
    () =>
      changeSets?.map((changeSet, index) => {
        return { ...changeSet, index };
      }),
    [changeSets],
  );

  useEffect(() => {
    if (
      updatedDraft &&
      updatedDraft?.orderModStatus === OrderModStatus.ATTACH_TO_MOD
    ) {
      setActionList([]);
    }
  }, [updatedDraft]);

  const columns = useMemo(
    () => [
      {
        Header: 'Change set',
        accessor: 'changeSet',
        sortable: false,
        Cell: ({ row: { original } }) => {
          return `change set ${original.index + 1}`;
        },
      },
      {
        Header: 'Number of orders',
        accessor: 'numberOfOrders',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            // eslint-disable-next-line react/prop-types
            original?.changeSetOrders?.length || (
              <span className="change-set-no-items">(no orders selected)</span>
            )
          );
        },
      },
      {
        Header: 'Modification',
        accessor: 'modification',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          let type = original?.type;
          // eslint-disable-next-line react/prop-types
          if (terminatedChangeSets.includes(original.orderChangeSetModId)) {
            type = 'TERMINATE';
          }
          return (
            <Tag variant={STATUS[type]?.COLOR}>
              {type ? (
                STATUS[type].LABEL
              ) : (
                <span className="change-set-no-items">(no mods applied)</span>
              )}
            </Tag>
          );
        },
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,

        // eslint-disable-next-line react/prop-types
        Cell: (props) => {
          const { row } = props;
          return (
            <AFPTableRowAction
              actions={actionList}
              onSelectAction={(event) => handleSelectedRow(event, row)}
              {...props}
            />
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <AFPTable
        testId="order-vehicle-table"
        columns={columns}
        data={changeSetOrders || []}
        onSort={setTableSort}
        defaultSort={sort}
        expandable
        renderRowSubComponent={ChangeSetSubComponent}
      />
      {(!changeSets || !changeSets?.length) && (
        <EmptyState
          hasBackground
          containerStyles="margin-top-neg-2 padding-y-10"
          topText="No change sets found for this order modification"
        />
      )}
      <DisplayCancelModal
        isOpen={cancelModal.isOpen}
        onSubmit={() => onSubmit()}
        onClose={() => onCancelModalClose()}
      />
    </>
  );
};

export default ChangeSetModTable;
