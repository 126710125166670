import React from 'react';
import PropTypes from 'prop-types';
import SectionEditButton from '../SectionEditButton/SectionEditButton';
import './Justification.scss';
import { STEPS } from '../../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';

const Justification = ({ title, justificationData, isEditable, onEdit }) => {
  return (
    <div role="tab" tabIndex="0" className="justification-section">
      <div className="title-section justification-title-section">
        <span>{title}</span>
        {isEditable && (
          <SectionEditButton
            showButton
            id="justification-review-edit"
            step={STEPS.NON_LOW_BID_REASON}
            editPage={onEdit}
          />
        )}
      </div>
      <div className="justification-desc-section">
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: justificationData }}
        />
      </div>
    </div>
  );
};

Justification.propTypes = {
  title: PropTypes.string.isRequired,
  justificationData: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
};

Justification.defaultProps = {
  isEditable: false,
};

export default Justification;
