import React, { useState, useMemo } from 'react';
import { GiCheckMark } from 'react-icons/gi';
import { formatCurrency } from '../../utilities/CompareVehicleUtils';
import CheckedSVG from '../../assets/images/conflict-checked.svg';
import './ConflictModal.scss';

const RequiredConflictOptions = ({
  item,
  radioHandler,
  selectedOptionItem,
  requiredOptions,
  allVendorExcludedOptions,
  allVendorIncludedOptions,
}) => {
  const [selectedRequiredOption, setSelectedRequiredOption] = useState(
    item.affectedRequiredOptions.length === 1
      ? item.affectedRequiredOptions[0]
      : null,
  );

    const handleRequiredSelection = (
        currentOption,
        requiredOption,
        newSelectedOptionItem,
        index,
    ) => {
        radioHandler(currentOption, requiredOption, newSelectedOptionItem);
        setSelectedRequiredOption(currentOption.affectedRequiredOptions[index]);
    };

  useMemo(() => {
    if(item.affectedRequiredOptions.length === 1) {
        handleRequiredSelection(item, item.affectedRequiredOptions[0], selectedOptionItem, 0);
    }
  }, [item])



  const collisionLabel = (requiredOption) => {
    let collidedOptionsLabel = '';
    requiredOption.collidedOptions.forEach((option, index, arr) => {
      collidedOptionsLabel = `${collidedOptionsLabel} ${option.optionCode} - ${
        option.optionDescription
      }${requiredOptions.indexOf(option.optionCode) > -1 ? '^' : ''} ${
        arr.length - 1 === index ? '' : '&'
      }`;
    });
    return collidedOptionsLabel;
  };

  return (
    <>
      <div className="required-options">
        <div className="required-section text-base-light">
          <div className="required-title">+ REQUIRED</div>
        </div>
        {item.affectedRequiredOptions.length > 1 && (
          <div className="required-sub-title">
            Choose between the following required options:
          </div>
        )}
        {item.affectedRequiredOptions.map((requiredOption, index) => {
          const collidedOptionsLabel = collisionLabel(requiredOption);
          const checkCondition =
            item?.affectedRequiredOptions?.length === 1 ||
            JSON.stringify(requiredOption) ===
              JSON.stringify(selectedRequiredOption);
          return (
            <>
              <div className="required-options-list">
                <span className="conflict-radio">
                  <input
                    data-testid="radio-conflict-input"
                    type="radio"
                    className="radio-button"
                    checked={checkCondition}
                    name={item.contractLineId}
                    onClick={() =>
                      handleRequiredSelection(
                        item,
                        requiredOption,
                        selectedOptionItem,
                        index,
                      )
                    }
                  />
                </span>

                <span className="conflict-option-list">
                  {collidedOptionsLabel}
                </span>
                <span>
                  {formatCurrency(requiredOption.requiredOptionPrice)}
                </span>
              </div>
            </>
          );
        })}
        {selectedRequiredOption &&
          (selectedRequiredOption.includedCollidedOptions.length > 0 ||
            selectedRequiredOption.excludedCollidedOptions.length > 0) && (
            <div className="modal-body-req-sub-title">
              Based on your selection of{' '}
              <span className="selected-option">
                {collisionLabel(selectedRequiredOption)}
              </span>
              , this vendor <span className="include">INCLUDES</span> and/or{' '}
              <span className="exclude">EXCLUDES</span> additional options
              below:
            </div>
          )}
        {selectedRequiredOption &&
          selectedRequiredOption.includedCollidedOptions &&
          selectedRequiredOption.includedCollidedOptions.length !== 0 && (
            <div className="included-options">
              <div className="includes-section">
                <div className="include-title">+ INCLUDES</div>
              </div>
              {selectedRequiredOption.includedCollidedOptions.map(
                (affectedInOption) => {
                  return (
                    <li className="option-description">
                      {affectedInOption.optionCode} -{' '}
                      {affectedInOption.optionDescription}
                      {allVendorIncludedOptions.indexOf(
                        affectedInOption.optionCode,
                      ) > -1
                        ? '^'
                        : ''}
                    </li>
                  );
                },
              )}
            </div>
          )}
        {selectedRequiredOption &&
          selectedRequiredOption.excludedCollidedOptions &&
          selectedRequiredOption.excludedCollidedOptions.length !== 0 && (
            <div className="excluded-options">
              <div className="excludes-section">
                <div className="exclude-title">– EXCLUDES</div>
              </div>
              {selectedRequiredOption.excludedCollidedOptions.map(
                (affectedExOption) => {
                  return (
                    <li className="option-description">
                      {affectedExOption.optionCode} -{' '}
                      {affectedExOption.optionDescription}
                      {allVendorExcludedOptions.indexOf(
                        affectedExOption.optionCode,
                      ) > -1
                        ? '*'
                        : ''}
                    </li>
                  );
                },
              )}
            </div>
          )}
      </div>
    </>
  );
};

const ConflictModalComponent = ({
  content,
  selectedOptionItem,
  conflictModalRadioHandler,
  resolvedOptions,
}) => {
  let isLowestCount = 0;
  return (
    <>
      <div className="modal-body-title">
        Based on your selection of the following option:{' '}
      </div>
      <h2 data-testid="selected-option-description">
        {selectedOptionItem.optionCode} - {selectedOptionItem.optionDescription}
      </h2>
      <div className="modal-body-sub-title">
        The following vendors <span className="include">INCLUDE</span>,{' '}
        <span className="exclude">EXCLUDE</span> and/or{' '}
        <span className="required">REQUIRE</span> additional options listed
        below:
      </div>

      {content.unselectOptions.length ? (
        <div className="unselected-desc">
          *This option is excluded by all vendors and therefore will no longer
          be selected within the underlying Compare page
        </div>
      ) : (
        ''
      )}

      {content.selectOptions.included.length > 0 ? (
        <div className="unselected-desc">
          ^This option is included by all vendors and therefore will be selected
          within the underlying Compare page.
        </div>
      ) : (
        ''
      )}

      {content.selectOptions.required.length > 0 ? (
        <div className="unselected-desc">
          ^This option is required by all vendors and therefore will be selected
          within the underlying Compare page.
        </div>
      ) : (
        ''
      )}

      <div className="conflict-modal-body">
        {content.vendorCollisionsPerOption &&
          content.vendorCollisionsPerOption.length &&
          content.vendorCollisionsPerOption.map((item) => {
            const isVendorResolved = resolvedOptions.find(
              (option) => option.contractLineId === item.contractLineId,
            );
            const checkIsConflictedVendor =
              (item.affectedExcludedOptions &&
                item.affectedExcludedOptions.length) ||
              (item.affectedIncludedOptions &&
                item.affectedIncludedOptions.length) ||
              (item.affectedRequiredOptions &&
                item.affectedRequiredOptions.length);
            if (!checkIsConflictedVendor) {
              return '';
            }
            isLowestCount += 1;
            return (
              <div className="affected-vendor-card">
                {isLowestCount === 1 ? (
                  <div className="lowest-price-block">
                    <div className="parallelogram">
                      <span className="lowest-price-title">
                        <GiCheckMark />
                        Current Lowest Price
                      </span>
                    </div>
                    <div className="triangle-bottomleft" />
                  </div>
                ) : (
                  ''
                )}
                {}
                <div className="vendor-card-inner">
                  <div className="collided-vendor-name">
                    {item.vendorName} - {item.modelName}
                    {isVendorResolved && (
                      <span className="conflict-resolved-check">
                        <img src={CheckedSVG} alt="conflict resolved" />
                      </span>
                    )}
                  </div>
                  <div className="options-section">
                    {item.affectedIncludedOptions &&
                      item.affectedIncludedOptions.length !== 0 && (
                        <div className="included-options">
                          <div className="includes-section text-base-light">
                            <div className="include-title">+ INCLUDES</div>
                          </div>
                          {item.affectedIncludedOptions.map(
                            (affectedInOption) => {
                              return (
                                <li className="option-description">
                                  {affectedInOption.optionCode} -{' '}
                                  {affectedInOption.optionDescription}
                                  {content.selectOptions.included.indexOf(
                                    affectedInOption.optionCode,
                                  ) > -1
                                    ? '^'
                                    : ''}
                                </li>
                              );
                            },
                          )}
                        </div>
                      )}

                    {item.affectedExcludedOptions &&
                      item.affectedExcludedOptions.length !== 0 && (
                        <div className="excluded-options">
                          <div className="excludes-section text-base-light">
                            <div className="exclude-title">– EXCLUDES</div>
                          </div>
                          {item.affectedExcludedOptions.map(
                            (affectedExOption) => {
                              return (
                                <li className="option-description">
                                  {affectedExOption.optionCode} -{' '}
                                  {affectedExOption.optionDescription}
                                  {affectedExOption.isAnUnselectOption
                                    ? '*'
                                    : ''}
                                </li>
                              );
                            },
                          )}
                        </div>
                      )}

                    {item.affectedRequiredOptions &&
                      item.affectedRequiredOptions.length !== 0 && (
                        <RequiredConflictOptions
                          item={item}
                          selectedOptionItem={selectedOptionItem}
                          radioHandler={conflictModalRadioHandler}
                          requiredOptions={content.selectOptions.required}
                          allVendorIncludedOptions={
                            content.selectOptions.included
                          }
                          allVendorExcludedOptions={content.unselectOptions}
                        />
                      )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="conflict-explanation-msg">
        When you have resolved conflicts with your vendors of interest, click on{' '}
        <strong>Apply changes </strong>
        to continue. <strong>PLEASE NOTE:</strong> Unresolved conflicts will
        result in nonbuildable models.
      </div>
    </>
  );
};

export default ConflictModalComponent;
