import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  TextInput,
  ComboBox,
  Label,
  RequiredFieldIndicator,
  Fieldset,
  useModal,
  connectModal,
  Button,
} from '@gsa/afp-component-library';
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from 'react-hook-form';
import { GET_STANDARD_CODES } from '../../../../services/data-layer';
import { GET_ACTIVE_VEHICLE_GROUPS } from '../../../../services/data-layer/standards';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import FormButtons from '../utils/FormButtonNav';
import { TOWING_TYPES } from '../../constants';
import { AdditionalReqForm } from '../../../../components/MultipleAdditionalRequirements/AdditionalReqForm/AdditionalReqForm';
import MultipleAdditionalRequirementsWarningModal from '../../../../components/MultipleAdditionalRequirements/MultipleAdditionalRequirementsWarningModal';
import DeleteRequirementModal from '../../../../components/MultipleAdditionalRequirements/DeleteRequirementModal/DeleteRequirementModal';
import { useSessionState } from '../../../../hooks/useSessionState';
import { resetFocusToFirstElement } from '../../../../utilities/commons';
import { VEHICLE_GROUP_TAG_STORE } from '../../../../constants/constants';

const VehicleSpecification = ({ handlePageNavigation }) => {
  const { dispatch, state } = useContext(VehicleRequisitionContext);
  const { nonSopData, additionalRequirements } = state;
  const options = [{ value: '-select-', label: '-select-' }];
  const [colorsData, setColorsData] = useState(options);
  const [fuelTypesData, setFuelTypesData] = useState();
  const [vehicleTypes, setVehicleTypes] = useState(options);
  const [enableVehTypeSubSection, setEnableVehTypeSubSection] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(-1);
  const DisplayDeleteRequirementModal = connectModal(DeleteRequirementModal);
  const [doNotShowAgainSession, setDoNotShowAgainSession] = useSessionState(
    'multipleAdditionalRequirementsWarningModalDoNotShow',
    false,
  );
  const displayDeleteRequirementModal = useModal();
  const multipleAdditionalRequirementsWarningModal = useModal();
  const DisplayMultipleAdditionalRequirementsWarningModal = connectModal(
    MultipleAdditionalRequirementsWarningModal,
  );

  const formProps = useForm({
    defaultValues: {
      ...nonSopData,
      additionalRequirements: additionalRequirements?.length
        ? additionalRequirements
        : [{ attachments: [], description: '' }],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = formProps;

  const {
    fields: additionalReqs,
    append,
    remove,
  } = useFieldArray({
    control: formProps.control,
    name: 'additionalRequirements',
  });

  const transformRespToOptions = (data) => {
    const list = [...options];
    data.forEach((i) => {
      list.push({
        label: i.title,
        value: i.title,
      });
    });
    return list;
  };
  const getPayload = (id) => {
    return {
      variables: {
        filters: {
          operator: 'EQ',
          key: 'code_metadata_id',
          value: id,
        },
      },
    };
  };
  const [_getVehicleTypes] = useLazyQuery(GET_ACTIVE_VEHICLE_GROUPS, {
    onCompleted: (data) => {
      if (data?.getActiveVehicleGroups) {
        setVehicleTypes(data?.getActiveVehicleGroups);
      }
    },
    fetchPolicy: 'no-cache',
    variables: {
      childTag: VEHICLE_GROUP_TAG_STORE,
    },
  });
  const { data: fetchColors } = useQuery(GET_STANDARD_CODES, getPayload(1));
  const { data: fetchVehicleTypes } = useQuery(
    GET_STANDARD_CODES,
    getPayload(15),
  );

  const openWarningModal = () => {
    if (doNotShowAgainSession) return;
    multipleAdditionalRequirementsWarningModal.openModal();
  };

  useEffect(() => {
    const colors = fetchColors?.getStandardCodes?.rows;
    if (colors?.length) {
      setColorsData(
        transformRespToOptions([
          { id: '-1', title: '<No preference>' },
          ...colors,
        ]),
      );
    }
  }, [fetchColors]);

  useEffect(() => {
    const vehicleFuelTypes = fetchVehicleTypes?.getStandardCodes?.rows;
    if (vehicleFuelTypes?.length > 0) {
      setFuelTypesData(transformRespToOptions(vehicleFuelTypes));
    }
  }, [fetchVehicleTypes]);

  useEffect(() => {
    _getVehicleTypes();
    setTimeout(() => openWarningModal());
    resetFocusToFirstElement();
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_URG_REQ_ERROR_BANNER,
        payload: false,
      });
    }
  }, [errors]);

  useEffect(() => {
    Object.entries(getValues()).forEach(([key, value]) => {
      if (value === '-select-') {
        setValue(key, null);
        setError(key, {
          type: 'required',
          message: 'This is a required field',
        });
      }
    });
  }, [errors, getValues()]);

  useEffect(() => {
    if (nonSopData) {
      Object.entries(nonSopData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [nonSopData]);

  const onSubmit = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (value === '-select-' || value === ''){
        setValue(key, null)
      }

      if (key === 'vehicleType' && value !== 'Other'){
        setValue('vehicleTypeOther', null);
      }
    });
    const {code} = vehicleTypes?.find((li) => li.title === getValues().vehicleType);
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_NON_SOP_DATA,
      payload: {...getValues(), vehicleTypeCode: code},
    });
    const updatedData = data.additionalRequirements.map((x) => ({
      attachments: x.attachments.map((attachment) => {
        const { id, ...rest } = attachment;
        return rest;
      }),
      description: x.description,
    }));
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_ADDITIONAL_REQUIREMENTS,
      payload: updatedData,
    });
    handlePageNavigation('continue');
  };

  const handleVehicleSubType = (value) => {
    if (value === 'Other') {
      setEnableVehTypeSubSection(true);
    } else {
      setEnableVehTypeSubSection(false);
    }
  };
  const onError = () => {
    const fuelValue = getValues().fuelType;
    if (fuelValue === '-select-') {
      setError('fuelType', {
        type: 'required',
        message: 'This is a required field',
      });
    }
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_URG_REQ_ERROR_BANNER,
      payload: true,
    });
  };

  const validateNumber = (value) => {
    if (!value?.trim()) return true;
    const matches = value.match(/^[0-9]*$/);
    return matches?.length > 0 || 'Please enter an integer value';
  };

  const validateQuantity = (value) => {
    return (
      (value >= 1 && value <= 999) || 'Quantity can only be between 1 and 999'
    );
  };

  return (
    <FormProvider {...formProps}>
      <form
        data-testid="veh-spec-form"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div role="tab" tabIndex="0">
          <h3>Provide vehicle specifications</h3>
          <div>
            Please provide the vehicle specifications and minimum requirements
            to fulfill the agency mission. Provide as much information as
            possible. Ordering submissions of ambiguous requirements will be
            cause GSA to reject or return the request.
          </div>
          {(vehicleTypes.length > 1 ||
            (vehicleTypes.length > 1 && nonSopData?.vehicleType)) && (
            /* eslint-disable-next-line */
            <div className="top-padding">
              <div
                className={
                  errors?.vehicleType?.message &&
                  'usa-form-group usa-form-group--error'
                }
              >
                <Label required>Vehicle type</Label>
                <div className="usa-error-message">
                  {errors?.vehicleType?.message}
                </div>
                <Controller
                  rules={{ required: 'This is a required field' }}
                  name="vehicleType"
                  render={({ field }) => (
                    <ComboBox
                      data-testid="select-veh-type"
                      id="vehicleType"
                      options={transformRespToOptions(vehicleTypes)}
                      value={field.value}
                      className={
                        errors?.vehicleType?.message && 'usa-input--error'
                      }
                      defaultValue={nonSopData?.vehicleType || '-select-'}
                      onChange={(value) => {
                        field.onChange(value);
                        handleVehicleSubType(value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {enableVehTypeSubSection && (
            <div className="vehicle-type-sub-section">
              <Controller
                name="vehicleTypeOther"
                render={({ field: { value, onChange, ref } }) => (
                  <>
                    <Fieldset legend="Other" className="field-other">
                      <TextInput
                        data-testid="vehicle-sub-section-field"
                        label="Enter other vehicle type"
                        containerClassName="vehicle-sub-section-field-input"
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                        name="vehicleTypeOther"
                      />
                    </Fieldset>
                  </>
                )}
              />
            </div>
          )}

          <div className="top-padding">
            <Controller
              rules={{ required: 'This is a required field' }}
              name="driveTrain"
              render={({ field: { value, onChange, ref } }) => (
                <TextInput
                  data-testid="drive-train"
                  label={
                    <>
                      Drive train <RequiredFieldIndicator />
                    </>
                  }
                  onChange={onChange}
                  value={value || ''}
                  inputRef={ref}
                  name="driveTrain"
                  errorMessage={errors?.driveTrain?.message}
                />
              )}
            />
          </div>

          <div className="top-padding">
            <Controller
              rules={{
                required: 'This is a required field',
                validate: validateQuantity,
              }}
              name="quantity"
              render={({ field: { value, onChange, ref } }) => (
                <TextInput  
                  type="number"
                  data-testid="veh-spec-quantity"
                  label={
                    <>
                      Quantity <RequiredFieldIndicator />
                    </>
                  }
                  onChange={onChange}
                  value={Math.floor(value)}
                  inputRef={ref}
                  name="quantity"
                  errorMessage={errors?.quantity?.message}
                />
              )}
            />
          </div>

          {(colorsData?.length > 1 ||
            (colorsData?.length > 1 && nonSopData?.color)) && (
            <div className="top-padding">
              <div
                className={
                  errors?.color?.message &&
                  'usa-form-group usa-form-group--error'
                }
              >
                <Label required>Color</Label>
                <div className="usa-error-message">
                  {errors?.color?.message}
                </div>
                <Controller
                  rules={{ required: 'This is a required field' }}
                  name="color"
                  render={({ field: { onChange } }) => (
                    <ComboBox
                      data-testid="select-color"
                      id="color"
                      name="color"
                      options={colorsData}
                      className={errors?.color?.message && 'usa-input--error'}
                      defaultValue={nonSopData?.color || '-select-'}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {fuelTypesData?.length > 0 && (
            <div className="top-padding">
              <div
                className={
                  errors?.fuelType?.message &&
                  'usa-form-group usa-form-group--error'
                }
              >
                <Label required>Fuel type</Label>
                <div className="usa-error-message">
                  {errors?.fuelType?.message}
                </div>
                <Controller
                  rules={{ required: 'This is a required field' }}
                  name="fuelType"
                  render={({ field: { onChange } }) => (
                    <ComboBox
                      data-testid="select-fuel-type"
                      id="fuelType"
                      name="fuelType"
                      onChange={onChange}
                      options={fuelTypesData}
                      defaultValue={nonSopData?.fuelType || '-select-'}
                      className={
                        errors?.fuelType?.message && 'usa-input--error'
                      }
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="top-padding">
            <Controller
              rules={{
                validate: validateNumber,
              }}
              name="grossVehicleWeightRating"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  data-testid="veh-gross-weight"
                  label="Gross vehicle weight rating"
                  onChange={onChange}
                  value={value || ''}
                  name="grossVehicleWeightRating"
                  errorMessage={errors?.grossVehicleWeightRating?.message}
                />
              )}
            />
          </div>
          <div className="top-padding">
            <Controller
              rules={{
                validate: validateNumber,
              }}
              name="grossCombinedWeightRating"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  data-testid="veh-combined-weight"
                  label="Gross combined weight rating"
                  onChange={onChange}
                  value={value || ''}
                  name="grossCombinedWeightRating"
                  errorMessage={errors?.grossCombinedWeightRating?.message}
                />
              )}
            />
          </div>
          <div className="small-box">
            <Controller
              rules={{
                validate: validateNumber,
              }}
              name="payloadCapacity"
              render={({ field: { value, onChange, ref } }) => (
                <TextInput
                  data-testid="veh-payload-capacity"
                  label={<>Payload capacity</>}
                  onChange={onChange}
                  value={value || ''}
                  inputRef={ref}
                  name="driveTrain"
                  errorMessage={errors?.payloadCapacity?.message}
                />
              )}
            />
          </div>
          <div className="small-box">
            <Controller
              rules={{
                validate: validateNumber,
              }}
              name="towingCapacity"
              render={({ field: { value, onChange, ref } }) => (
                <TextInput
                  data-testid="veh-towing-capacity"
                  label={<>Towing capacity</>}
                  onChange={onChange}
                  value={value || ''}
                  inputRef={ref}
                  name="towingCapacity"
                  errorMessage={errors?.towingCapacity?.message}
                />
              )}
            />
          </div>
          {(TOWING_TYPES.length > 0 || nonSopData?.towingType) && (
            <div className="top-padding">
              <div
                className={
                  errors?.towingType?.message &&
                  'usa-form-group usa-form-group--error'
                }
              >
                <Label required>Towing type</Label>
                <div className="usa-error-message">
                  {errors?.towingType?.message}
                </div>
                <Controller
                  rules={{ required: 'This is a required field' }}
                  name="towingType"
                  render={({ field: { onChange } }) => (
                    <ComboBox
                      data-testid="veh-towing-type"
                      id="towingType"
                      name="towingType"
                      className={
                        errors?.towingType?.message && 'usa-input--error'
                      }
                      options={TOWING_TYPES}
                      defaultValue={nonSopData?.towingType || '-select-'}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          )}

          <div className="top-padding-70">
            <div className="urgent-req-title-2">Additional Requirements</div>
            <div className="top-padding-10">
              Provide any additional requirements to support your urgent vehicle
              request (with optional supporting documentation). Please note that
              the vehicle will be built in accordance with the accepted
              contractor’s response to the request. It is the customer agency’s
              responsibility to ensure the contractor’s response meets the
              government’s needs at the time of selection.
            </div>
          </div>
          <div className="add-req-btn-container">
            <Button
              type="button"
              data-testid="add-req-btn"
              variant="primary"
              label="Add a requirement"
              leftIcon={{ name: 'add', className: 'plus-icon' }}
              onClick={() => {
                append({ description: '', attachments: [] });
                openWarningModal();
              }}
            />
          </div>

          <div className="additional-reqs-container">
            {additionalReqs.map((additionalReq, index) => (
              <AdditionalReqForm
                index={index}
                key={additionalReq.requisitionAdditionalRequirementId}
                setItemToDelete={setItemToDelete}
                displayDeleteRequirementModal={displayDeleteRequirementModal}
                requisitionType="NON_SOP"
              />
            ))}
          </div>

          <FormButtons handlePageNavigation={handlePageNavigation} />

          <DisplayDeleteRequirementModal
            isOpen={displayDeleteRequirementModal.isOpen}
            handleClose={displayDeleteRequirementModal.closeModal}
            handleDelete={remove}
            itemToDelete={itemToDelete}
          />

          <DisplayMultipleAdditionalRequirementsWarningModal
            isOpen={multipleAdditionalRequirementsWarningModal.isOpen}
            closeModal={multipleAdditionalRequirementsWarningModal.closeModal}
            doNotShowAgainSession={doNotShowAgainSession}
            setDoNotShowAgainSession={setDoNotShowAgainSession}
          />
        </div>
      </form>
    </FormProvider>
  );
};

VehicleSpecification.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
};

export default VehicleSpecification;
