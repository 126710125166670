export const orderLineMapping = [
  ['Vehicle line number', 'accountingLineNumber', 'string'],
  ['VON', 'von', 'string'],
  ['VIN', 'vin', 'string'],
  ['Contract', 'contractNumber', 'string'],
  ['Case Number', 'caseNumber', 'string'],
  ['Name', 'contactName', 'string'],
  ['Address', 'line1', 'string'],
  ['City', 'city', 'string'],
  ['STATE', 'stateCode', 'string'],
  ['Zip', 'zipcode', 'string'],
  ['STATUS', 'statusCode', 'string'],
  ['Status Date', 'statusDate', 'string'],
  ['Contact Person', 'contactPerson', 'string'],
  ['Telephone Number', 'contactTelephone', 'string'],
  ['GVWR', 'gvwr', 'string'],
  ['Fuel Type', 'fuelType', 'string'],
  ['GPM', 'convGpm', 'string'],
  ['MPG City', 'convMpgCity', 'string'],
  ['MPG Highway', 'convMpgHighway', 'string'],
  ['MPG Combined', 'convMpgCombined', 'string'],
  ['Flex GPM(alpha numeric 4)', 'altGpm', 'string'],
  ['Flex Fuel MPG City', 'altMpgCity', 'string'],
  ['Flex Fuel MPG Highway', 'altMpgHighway', 'string'],
  ['Flex Fuel MPG Combined', 'altMpgCombined', 'string'],
  ['Color', 'color', 'string'],
  ['Trim', 'trim', 'string'],
  ['Description', 'description', 'string'],
];
