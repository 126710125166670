import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, TextInput } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import './SubmitRequisitionModal.scss';

import {
  SUBMIT_FOR_ENGINEERING_REVIEW,
  SUBMIT_REQUISITION,
  SUBMIT_TO_GSA,
  SUBMIT_FOR_CONTRACTING_REVIEW,
} from '../../services/data-layer';

import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import SubmitBtnLabel from '../../pages/VehicleRequisition/components/PageNavigationButtons/SubmitBtnLabel';
import { canApproveRequisitionCart } from '../../utilities/authorization';
import { ENGINEERING_REVIEW_STATUS } from '../../pages/ReviewDetails/SubmitToEngineerButton/SubmitToEngineerButton';
import useRequisitionReview from '../../utilities/useRequisitionReview';
import { CREATE_REQUISITION_DRAFT_NEW } from '../../services/create-requisition';
import { createRequisition } from '../../utilities/create-requisition-util';

const CONTRACTING_REVIEW_STATUS = 'contracting-review';

const SubmitRequisitionModal = ({ sin, setPreventNavigation }) => {
  const navigate = useNavigate();
  const { isEngineerReviewRequired, isVendorDirect } = useRequisitionReview();
  const [draftNameHasError, setDraftNameHasError] = useState(false);
  const [draftNameErrorText, setDraftNameErrorText] = useState('');
  const [draftFriendlyName, setDraftFriendlyName] = useState('');

  const ability = useAppAbility();
  const canApproveRequisition = canApproveRequisitionCart(ability);

  const { state, dispatch } = useContext(VehicleRequisitionContext);

  const { submitComment } = state;

  useEffect(() => {
    setPreventNavigation(false);
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    const defaultFriendlyName = `${sin}_${month}${date.getDate()}_${date.getHours()}${date.getMinutes()}`;
    setDraftFriendlyName(defaultFriendlyName);
  }, []);

  const [submitToGSAMutation] = useMutation(SUBMIT_TO_GSA);
  const [submitForEngineerReviewMutation] = useMutation(
    SUBMIT_FOR_ENGINEERING_REVIEW,
  );

  const [submitReqToContracting] = useMutation(SUBMIT_FOR_CONTRACTING_REVIEW);

  const [submitRequisitionMutation] = useMutation(SUBMIT_REQUISITION, {
    fetchPolicy: 'no-cache',
  });

  const [createRequisitionDraft, { loading }] = useMutation(CREATE_REQUISITION_DRAFT_NEW);

  const handleHideSubmitModal = () => {
    setPreventNavigation(true);
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_MODAL,
      payload: false,
    });
  };

  const submitToGSAOrEngineer = async (requisitionId, friendlyName) => {
    // Submit to Engineer
    if (isEngineerReviewRequired) {
      await submitForEngineerReviewMutation({
        variables: {
          requisitionId,
        },
      });

      // After modifying the requisition, flash the banner with an update
      navigate(
        `/my-requisitions?requisitionId=${requisitionId}&reqName=${friendlyName}&reqStatus=${ENGINEERING_REVIEW_STATUS}`,
      );

      return true;
    }

    if (!isVendorDirect) {
      await submitReqToContracting({
        variables: {
          requisitionId,
        },
      });

      // After modifying the requisition, flash the banner with an update
      navigate(
        `/my-requisitions?requisitionId=${requisitionId}&reqName=${friendlyName}&reqStatus=${CONTRACTING_REVIEW_STATUS}`,
      );

      return true;
    }

    // Submit to GSA
    const {
      data: { submitToGSA },
    } = await submitToGSAMutation({
      variables: {
        requisitionId,
        approvalComment: submitComment,
      },
    });

    if (submitToGSA.requisitionCartValidations) {
      dispatch({
        type: VehicleRequisitionContextActions.VALIDATIONS_ERROR_STATE,
        payload: submitToGSA.requisitionCartValidations,
      });
      dispatch({
        type: VehicleRequisitionContextActions.SET_VALIDATIONS_MODAL_OPEN,
        payload: true,
      });

      return false;
    }

    // After modifying the requisition, flash the banner with an update
    navigate(`/my-requisitions?requisitionId=${requisitionId}&draftName=${friendlyName}&operationStatus=${submitToGSA.operationStatus}`);

    return true;

  };

  const setSpinnerLoading = (val) => {
    return dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
      payload: val,
    });
  };

  const onSubmit = async () => {
    if (draftNameHasError) {
      return;
    }

    const draftObject = createRequisition(state, draftFriendlyName);
    try {
      // setSpinnerLoading(true);

      const { data } = await createRequisitionDraft({ variables: draftObject });
      // After Save Draft
      const createRequisitionData = data.createRequisitionDraft;
      
      // Something was wrong with creating requisition
      if (createRequisitionData.validations) {
        dispatch({
          type: VehicleRequisitionContextActions.VALIDATIONS_ERROR_STATE,
          payload: data.requisitionCartValidations,
        });
        return handleHideSubmitModal();
      }
      if (canApproveRequisition) {
        const hasNavigated = await submitToGSAOrEngineer(
          createRequisitionData.requisitionId,
          createRequisitionData.friendlyName,
        );
        
        if (!hasNavigated) {
          navigate(`/my-requisitions?requisitionId=${createRequisitionData.requisitionId}&draftName=${createRequisitionData.friendlyName}`);
        }

        setSpinnerLoading(false);
        // submit errors and next navigation are handled in above method
        return;
      }

      const {
        data: { submitRequisition },
      } = await submitRequisitionMutation({
        variables: {
          requisitionId: createRequisitionData.requisitionId,
          submitComment,
          isVendorDirect,
        },
      });

      setSpinnerLoading(false);

      // Something was wrong with submitting requisition
      if (submitRequisition?.requisitionCartValidations !== null) {
        dispatch({
          type: VehicleRequisitionContextActions.VALIDATIONS_ERROR_STATE,
          payload: submitRequisition.requisitionCartValidations,
        });
        dispatch({
          type: VehicleRequisitionContextActions.SET_VALIDATIONS_MODAL_OPEN,
          payload: true,
        });
        navigate(
          `/vehicle-requisition?requisitionId=${createRequisitionData.requisitionId}&allowToCancel=1&allowToEdit=1`,
        );
        return;
      }

      // submit requisition push
      navigate(
        `/my-requisitions?requisitionId=${createRequisitionData.requisitionId}&draftName=${createRequisitionData.friendlyName}`,
      );
    } catch (err) {
      setDraftNameErrorText(err.message);
      setSpinnerLoading(false);
    }
  };

  const updateDraftName = (name) => {
    if (name.length > 50) {
      setDraftNameHasError(true);
      setDraftNameErrorText('Your name exceeds permitted 50 character limit.');
    } else if (name.length === 0) {
      setDraftNameHasError(true);
      setDraftNameErrorText('Please enter a name for your draft');
    } else {
      setDraftNameHasError(false);
      setDraftNameErrorText('');
    }
    setDraftFriendlyName(name);
  };

  return (
    <div className="afp-modal-overlay modalContainer draft-modal">
      <Modal
        title={
          <h1 className="modal-title">
            Would you like to name your requisition?
          </h1>
        }
        className="save-draft-modal"
        onClose={() => {
          setDraftNameHasError(false);
          setDraftNameErrorText('');
          handleHideSubmitModal();
        }}
        actions={
          <div className="save-draft-requisition-button-row">
            <Button
              type="button"
              variant="unstyled"
              className="save-draft-requisition-action-button"
              data-testid="save-draft-modal-cancel-button"
              onClick={handleHideSubmitModal}
              label="Cancel"
              disabled={loading}
            />
            <Button
              type="button"
              variant="default"
              className="save-draft-requisition-action-button"
              data-testid="submit-requisition-modal-save-button"
              onClick={onSubmit}
              disabled={loading}
              label={<SubmitBtnLabel />}
            />
          </div>
        }
      >
        <p>
          Please enter a name for your requisition. If you do not enter a
          preferred name, the requisition will be saved as the autogenerated
          name shown below.
        </p>
        <form className="save-draft-form">
          <TextInput
            label="Enter the preferred name of your draft"
            onChange={(event) => updateDraftName(event.target.value)}
            value={draftFriendlyName}
            errorMessage={draftNameErrorText}
            data-testid="submit-requisition-name-input"
          />
        </form>
      </Modal>
    </div>
  );
};

SubmitRequisitionModal.propTypes = {
  sin: PropTypes.string.isRequired,
  notifyUserRef: PropTypes.shape(Object).isRequired,
};

export default SubmitRequisitionModal;
