import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import fileDownload from 'js-file-download';
import { Button, Spinner } from '@gsa/afp-component-library';
import { retryFetch } from '../../../../utilities/commonUtils';

import {
  GENERATE_READ_SIGNED_URL,
  GET_DOC_METADATA,
} from '../../../../services/data-layer';

export default function AttachmentDownload({ name, metadataId }) {
  const [docMimeType, setDocMimeType] = useState('');
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [getGeneratedSignedUrl] = useLazyQuery(GENERATE_READ_SIGNED_URL, {
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      if (!data.generateReadSignedURL) {
        throw new Error('generateReadSignedURL is not defined');
      }
  
      const options = {
        method: 'GET',
        headers: { 'Content-Type': docMimeType },
      };
  
      try {
        const blob = await retryFetch(
          data.generateReadSignedURL,
          options,
          3,
          5,
        );
        fileDownload(blob, fileName);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Failed to download file after retries:', error);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      console.error('An error occurred:', error);
    },
  });

  const [getDocMetaData] = useLazyQuery(GET_DOC_METADATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setDocMimeType(data?.getDocMetadata?.fileMimeType);
      setFileName(data?.getDocMetadata?.name);
      getGeneratedSignedUrl({
        variables: {
          fileType: data?.getDocMetadata?.fileMimeType,
          fileKey: data?.getDocMetadata?.fileLocation,
        },
      });
    },
  });

  const download = () => {
    setIsLoading(true);
    getDocMetaData({
      variables: {
        id: metadataId,
      },
    });
  };

  if (!metadataId) {
    return <>Invalid Metadata ID</>;
  }

  return (
    <>
      {isLoading && <Spinner size="small" className="margin-y-8" />}
      {!isLoading && (
        <Button
          type="button"
          data-testid="download-button"
          variant="unstyled"
          onClick={() => download()}
          label={name}
          aria-label={name}
        />
      )}
    </>
  );
}

AttachmentDownload.propTypes = {
  name: PropTypes.string.isRequired,
  metadataId: PropTypes.string.isRequired,
};
