import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEqual, sortBy } from 'lodash';
import { useQuery } from '@apollo/client';
import Moment from 'moment';
import {
  AFPTable,
  AFPTableRowAction,
  EmptyState,
  Spinner,
  useModal,
} from '@gsa/afp-component-library';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';

import {
  GET_ATTACHMENT_USER_INFO, GET_REQUISITION_ATTACHMENTS_WITHOUT_USER,
  GET_REQUISITION_ATTACHMENT_TYPES
} from '../../../../services/data-layer';
import AttachmentUploadModal from '../AttatchmentUploadModal/AttachmentUploadModal';
import AttachmentDownload from '../AttachmentDownload/AttachmentDownload';
import AttachmentDeleteModal from '../AttachmentDeleteModal/AttachmentDeleteModal';

import './AttachmentTable.scss';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import AttachmentUploadEditModal from '../AttachmentEditModal/AttachmentEditModal';
import { useQueryParam } from '../../../../hooks/useQueryParam';
import { RequisitionStatus } from '../../RequisitionDetailsUtils';
import { REQUISITION_TYPE } from '../../../non-standard-purchase/constants';
import { UserTypes } from '../../../../constants';
import { StoreOperations, StoreSubjects } from '../../../../constants/constants';

export default function AttachmentTable({ uploadAttachment, status, reqType }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isRefetched, setIsRefetched] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const deleteFileModal = useModal();
  const editFileModal = useModal();
  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const ability = useAppAbility();
  const { currentUser } = useCurrentUser();
  const isEngineer = ability?.can(StoreOperations.Create, StoreSubjects.CatalogCodes);
  const isContractingOfficer = ability?.can(StoreOperations.Create, StoreSubjects.Solicitation);
  const isOrderingAdmin = ability?.can(StoreOperations.OrderAdminUpdate, StoreSubjects.Contract);
  const isCustomer = ability?.can(StoreOperations.Create, StoreSubjects.Requisition);

  const query = useQueryParam();
  const requisitionId =
    query.get('requisitionId') || state?.draftRequisition?.requisitionId;
  const { additionalRequirements } = state;

  const { data: requisitionAttachmentTypes } = useQuery(
    GET_REQUISITION_ATTACHMENT_TYPES,
  );

  let requiredAttachments = [];
  const { data, loading, refetch } = useQuery(GET_REQUISITION_ATTACHMENTS_WITHOUT_USER, {
    fetchPolicy: 'no-cache',
    variables: {
      requisitionId,
    },
  });

  const { data: userData, loading: loadingUserData, refetch: refetchUserInfo } = useQuery(GET_ATTACHMENT_USER_INFO, {
    skip: loading,
    fetchPolicy: 'no-cache',
    variables: {
      ids: data?.getAllRequisitionAttachments?.map((attachment) => attachment.createdBy),
    }
  })

  // stitch the user info to the attachment
  if (data && userData) {
    data.getAllRequisitionAttachments = data.getAllRequisitionAttachments.map((attachment) => {
      const user = userData.getUsersByIds.find((user) => user.id === attachment.createdBy);
      return {
        ...attachment,
        createdUserInfo: user
      }
    })
  }

  const mapNewAttachments = (attachments, updatedAttachments) => {
    const newAttachmentArray = [];
    updatedAttachments.forEach((updatedAttachment) => {
      const foundMatch = attachments.find(
        (attachment) => attachment.metadataId === updatedAttachment.metadataId,
      );
      if (foundMatch) {
        const obj = {
          attachmentTypeId: foundMatch.attachmentTypeId,
          description: updatedAttachment.description,
          metadataId: updatedAttachment.metadataId,
          signedUrl: updatedAttachment.signedUrl,
          name: updatedAttachment.name,
        };
        newAttachmentArray.push(obj);
      }
    });
    return newAttachmentArray;
  };

  useEffect(() => {
    if (!initialData && data) {
      setInitialData(data);
    }

    if (
      initialData &&
      !isEqual(
        sortBy(initialData?.getAllRequisitionAttachments, 'metadataId'),
        sortBy(data?.getAllRequisitionAttachments, 'metadataId'),
      ) &&
      isRefetched
    ) {
      const newAdditionalRequirements = additionalRequirements.map(
        (requirement) => ({
          ...requirement,
          attachments: mapNewAttachments(
            requirement.attachments,
            data?.getAllRequisitionAttachments,
          ),
        }),
      );

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ADDITIONAL_REQUIREMENTS,
        payload: newAdditionalRequirements,
      });
      setIsRefetched(false);
      setInitialData(data?.getAllRequisitionAttachments);
    }
  }, [initialData, data, isRefetched]);

  const handleSelectedRow = (event, row) => {
    if (event === 'Delete') {
      setSelectedFile(row.original);
      deleteFileModal.openModal();
    }
    if (event === 'Edit') {
      setSelectedFile(row.original);
      editFileModal.openModal();
    }
  };

  useEffect(() => {
    if (requisitionAttachmentTypes) {
      if (reqType === REQUISITION_TYPE.URGENT_REQUIREMENT) {
        requiredAttachments =
          requisitionAttachmentTypes?.getAllRequisitionAttachmentTypes?.filter(
            (att) =>
              ['req_urgent_requirement', 'req_funding'].includes(
                att.attachmentType,
              ),
          );
      }

      if (reqType === REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES) {
        requiredAttachments =
          requisitionAttachmentTypes?.getAllRequisitionAttachmentTypes?.filter(
            (att) =>
              [
                'req_technical_specifications',
                'req_request_for_quote',
                'req_quotes_received',
                'req_funding',
              ].includes(att.attachmentType),
          );
      }
    }
  }, [requisitionAttachmentTypes?.getAllRequisitionAttachmentTypes]);

  const getAllowedActions = (row) => {
    const actionButtons = [{ icon: 'edit', label: 'Edit' }];
    actionButtons.push({ icon: 'delete', label: 'Delete' });
    return actionButtons;
  };

  /* eslint-disable react/prop-types */
  const columns = useMemo(() => {
    const filteredColumns = [
      {
        Header: 'Files',
        accessor: 'type',
        sortable: false,
        Cell: ({ row: { original } }) => {
          return (
            <AttachmentDownload
              name={original?.name}
              metadataId={original?.metadataId}
            />
          );
        },
      },
      {
        Header: 'Uploaded by',
        accessor: 'createdUserInfo.fullName',
        Cell: ({ row: { original } }) => {
          return <>{original.createdUserInfo?.fullName}</>;
        },
      },
      {
        Header: 'Date / Time',
        accessor: 'createdAt',
        Cell: ({ row: { original } }) => {
          return (
            <>
              {Moment(original.createdAt).format('MM/DD/YYYY')} &#8226;{' '}
              {Moment(original.createdAt).format('hh:mm A')} ET
            </>
          );
        },
      },
      {
        Header: 'Comments',
        accessor: 'description',
        sortable: false,
        cellClassName: 'attachment-note',
      },
    ];

    if (uploadAttachment) {
      filteredColumns.push({
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => {
          const { row } = props;
          if (row.original.createdUserInfo?.userType?.id === UserTypes.GSA_EMPLOYEE &&
            (isCustomer && !isEngineer && !isContractingOfficer && !isOrderingAdmin)
          && row.original.createdBy !== currentUser?.id) {
            return null;
          }
          return (
            <AFPTableRowAction
              actions={getAllowedActions(row)}
              onSelectAction={(event) => handleSelectedRow(event, row)}
              {...props}
            />
          );
        },
      });
    }

    return filteredColumns;
  }, [
    status,
    RequisitionStatus,
    requiredAttachments,
    requisitionAttachmentTypes,
  ]);
  /* eslint-enable react/prop-types */

  return (
    <div>
      <div className="attachments-container">
        <div className="attachments-container-header">
          <div className="attachments-container-header-text">
            Below are all files associated with this requisition. You may see
            who uploaded the attachment, when, what type of requisition event
            category the attachment falls within, and optional details.
          </div>
          {uploadAttachment && (
            <AttachmentUploadModal
              requisitionId={requisitionId}
              onCompleted={async () => {
                await refetch()
                await refetchUserInfo();
              }}
            />
          )}
        </div>

        <AFPTable
          testId="requisition-attachment-table"
          columns={columns}
          data={data?.getAllRequisitionAttachments || []}
        />

        {(loading || loadingUserData) && <Spinner aria-busy="true" size="medium" />}

        {(!loading && !loadingUserData) && !data?.getAllRequisitionAttachments?.length && (
          <EmptyState
            hasBackground
            containerStyles="margin-top-neg-2 padding-y-10"
            topText="No attachments found for this requisition"
          />
        )}
      </div>

      {selectedFile && (
        <>
          <AttachmentDeleteModal
            isOpen={deleteFileModal.isOpen}
            handleClose={deleteFileModal.closeModal}
            fileToDelete={selectedFile}
            onCompleted={async () => {
              await refetch();
              await refetchUserInfo();
              setIsRefetched(true);
            }}
          />

          <AttachmentUploadEditModal
            isOpen={editFileModal.isOpen}
            handleClose={editFileModal.closeModal}
            selectedAttachment={selectedFile}
            onCompleted={async () => {
              await refetch();
              await refetchUserInfo();
              setIsRefetched(true);
            }}
          />
        </>
      )}
    </div>
  );
}