/* eslint-disable react/jsx-curly-newline */
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import {
  extractOptionDescription,
  optionTypeCheck,
} from '../../AddOptionsUtils';
import { InfoIcon } from '../../../../assets/images/info-icon';
import Clarifications from '../../Clarifications';
import { generateEcClarification } from '../../../VendorClarifications/vendorClarificationUtil';
import OptionDescriptionTooltip from '../OptionDescriptionTooltip/OptionDescriptionTooltip';
import { OPTION_OPTION_TYPE } from '../../../../utilities/CompareVehicleUtils';

const RadioButtonGroup = ({
  optionData,
  handleRadioSelection,
  lowestBidData,
  displayCheckedOptionsOnly = false,
}) => {
  const isOptionUnAvailable = (option) => {
    return option.vendorValues.length > 0
      ? option.vendorValues.every(
          (item) =>
            item.optionType !== OPTION_OPTION_TYPE.STANDALONE ||
            item.equipmentCode === undefined ||
            item.unitPrice === 'N/A' ||
            item.unitPrice === undefined,
        )
      : true;
  };

  return optionData.options.map((option) => (
    <tr key={uuidv4()}>
      <td data-testid={`${optionData.optionCategoryCode}-${option.optionCode}`}>
        <div className="option-description-wrapper">
          <span className="option-description-column">
            {displayCheckedOptionsOnly || (
              <input
                data-testid={option.optionCode}
                id={`add-option-${option.optionCode}`}
                className="input-radio"
                type="radio"
                disabled={isOptionUnAvailable(option)}
                onClick={(e) =>
                  handleRadioSelection(
                    e.target.value,
                    optionData.optionCategoryCode,
                    option.isChecked || false,
                  )
                }
                defaultChecked={option.isChecked || false}
                value={option.optionCode}
              />
            )}
            <label htmlFor={`add-option-${option.optionCode}`}>
              {option.optionCode} - {option.optionDescription}
              <OptionDescriptionTooltip
                optionDescription={extractOptionDescription(option)}
              />
            </label>
          </span>
        </div>
      </td>
      {option.vendorValues.map((optionValue, index) => {
        const activateLowestBid =
          optionValue.contractLineId === lowestBidData.contractLineId
            ? 'lowest-bid'
            : '';

        const {
          clarificationClarifications,
          clarificationExceptions,
          clarificationsExist,
        } = generateEcClarification(optionValue?.clarifications);

        const tooltipId = `${
          option?.optionCode
        }${optionValue?.modelName?.replace(/\s+/g, '')}${index}`;
        return (
          <td className={`option-cell ${activateLowestBid}`} key={uuidv4()}>
            <div role="tab" tabIndex="0">
              {optionTypeCheck(
                optionValue.inputType,
                optionValue.optionType,
                optionValue.unitPrice,
                optionValue.isIncludeExist,
                optionValue.isExcludeExist,
                optionValue.isRequiredExist,
                optionValue.unResolvedExist,
              )}

              {clarificationsExist && (
                <span className="ec-tooltip">
                  <span
                    tabIndex="0"
                    aria-describedby={tooltipId}
                    aria-label="info"
                  >
                    <InfoIcon role="img" aria-label="tooltip info" />
                  </span>
                  <div id={tooltipId} role="tooltip" className="tooltip-info">
                    <Clarifications
                      title="Equipment clarifications: "
                      clarifications={clarificationClarifications}
                    />
                    <Clarifications
                      title="Technical exceptions: "
                      clarifications={clarificationExceptions}
                    />
                    <i />
                  </div>
                </span>
              )}
            </div>
          </td>
        );
      })}
    </tr>
  ));
};

export default RadioButtonGroup;
