/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  AFPTable,
  Button,
  EmptyState,
  Modal,
  Pagination,
  Spinner,
  TextInput,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useRecoilState } from 'recoil';
import useMyRequisitionsState from './useMyRequisitionsState';
import InfoSVG from '../../assets/images/info.svg';
import {
  getTableColumns,
  REQUISITION_TABLE_COLUMN_HEADERS,
} from './tableHelpers';
import { formatCurrency, TRANSACTION_TYPES } from '../../utilities/CompareVehicleUtils';
import './RequisitionsTable.scss';
// import WarningRed from '../../assets/images/warning-red.svg';
import { GET_REQUISITIONS_EXTENDED } from './queries';
import { OrderByMap } from './filterHelpers';
import { filterSelector } from './storeHelper';
import InfoTooltip from '../../components/InfoTooltip/InfoTooltip';
import {
  requisitionTypeDescriptor,
  requisitionTypeValues,
  REQUISITION_TYPE,
  isAreqType,
} from '../non-standard-purchase/constants';
import PutOnHoldModal from './PutOnHoldModal/PutOnHoldModal';
import { concatStrings } from '../../utilities/formatUtil';
import { initializeDependencies } from '../../reducers/dependency';
import { useGetLeasingRequisitionTableDetailsLazily, useGetVehicleByFilterWithZones, useGetZones } from '../../requests/leasing';
import { emDashUnicode } from '@gsa/afp-shared-form-utils';
import { setZoneData } from '../../reducers/leasing';
import Moment from "moment";

const SubComponent = ({ row: { original } }) => {
  const { onHold = false } = original;
  const [leasingDetails, setLeasingDetails] = useState(null);
  const isMas =
    // eslint-disable-next-line no-bitwise
    (original?.requisitionType & REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES) > 0;

  useGetZones().then(({ dispatch, data: zones }) => {
    dispatch(setZoneData(zones));
  });
  const [getLeasingRequisition, { loading: loadingLeasingDetail }] = useGetLeasingRequisitionTableDetailsLazily();
  const [getVehicleByFilter, { loading: loadingAccount }] =
    useGetVehicleByFilterWithZones();

  const remainingDays = original?.remainingDays
    ? parseInt(original?.remainingDays, 10) + 1
    : 0;
  const daysRemainingInfoTxt = `This incomplete requisition draft has ${remainingDays} days until it will be canceled.`;
  const requisitionTypes = requisitionTypeValues(original?.requisitionType);

  const assignedEngineerName = concatStrings(
    original?.assignedEngineer?.firstName,
    original?.assignedEngineer?.lastName,
  );
  const vehicleMap = sessionStorage.getItem('VEHICLE_TYPE_MAP')
    ? JSON.parse(sessionStorage.getItem('VEHICLE_TYPE_MAP'))
    : [];

  useEffect(() => {
    getLeasingRequisition({
      variables: {
        requisitionId: original.requisitionId,
      }
    })
      .then(({ data: requisition }) => {
        setLeasingDetails(requisition);

        if (requisition.requisitionLeasingCustomerAccount.vehicleReplacements?.length > 0) {
          // get leasing account
          getVehicleByFilter({
            variables: {
              filters: [
                {
                  operator: '$and',
                  conditions: [
                    {
                      key: 'id',
                      operator: '$exact',
                      value: requisition.requisitionLeasingCustomerAccount.vehicleReplacements[0].assetId,
                    },
                  ],
                },
              ],
            },
          })
            .then(({ data: account }) => {
              setLeasingDetails({
                ...requisition,
                account
              });
            })
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const requisitionLabel = requisitionTypes?.map((requisitionType) => {
    return requisitionTypeDescriptor[requisitionType]?.replace(/_/g, ' ');
  });
  const isNonStandard = [
    'URGENT REQUIREMENT',
    'MULTIPLE AWARD SCHEDULES',
  ].includes(requisitionLabel[0]);

  return (
    <>
      <div className="requisition-number-container">
        <span className="requisition-number">{(original?.number).toUpperCase()}</span>
      </div>
        <span className="requisition-type">
         {requisitionLabel[0]}
        </span>
      <div className="sub-component">
        <div className="tablet:grid-col margin-bottom-3 margin-right-4">
          {!original?.isSINexpired && (
            <>
              <div className="sub-row">
                <span className="text-bold">Standard Item Number</span>
                <span>{original?.standardItemCode || emDashUnicode}</span>
              </div>
              <div className="sub-row">
                <span className="text-bold">Vehicle type</span>
                <span>
                  {isNonStandard ? (original?.nonSopVehicleType || emDashUnicode) : vehicleMap.map((group) => {
                    return leasingDetails?.vehicleTypeCode === group.code
                      ? group.title
                      : '';
                  }).filter(Boolean).toString() || emDashUnicode}
                </span>
              </div>
              <div className="sub-row">
                <span className="text-bold">Model</span>
                <span>{leasingDetails?.modelInfo?.modelName || leasingDetails?.modelCode || emDashUnicode}</span>
              </div>
              <div className="sub-row">
                <span className="text-bold">Quantity</span>
                <span>{isNonStandard ? original?.nonSopQuantity : (original?.quantity || emDashUnicode)}</span>
              </div>
              <div className="sub-row">
                <span className="text-bold">Total price</span>
                <span>
                  {original?.totalSellingPrice
                    ? formatCurrency(original?.totalSellingPrice)
                    : emDashUnicode}
                </span>
              </div>
              {isAreqType(original.requisitionType) && (
                <div className="sub-row">
                  <span className="text-bold">Assigned engineer</span>
                  <span>{assignedEngineerName}</span>
                </div>
              )}
            </>
          )}
        </div>
        <div className="tablet:grid-col margin-bottom-3">
          {/* <div className="text-bold sub-row">Description</div>
        <p className="text-bold">Description</p> */}
          <div className="sub-row">
            <span className="text-bold">Bureau</span>
            <span>{original.bereauName || emDashUnicode}</span>
          </div>
          <div className="sub-row">
            <span className="text-bold">Group</span>
            <span>{original.officeName || emDashUnicode}</span>
          </div>
          <div className="sub-row">
            <span className="text-bold">Created by</span>
            <span>{original.createdUser}</span>
          </div>
          <div className="sub-row">
            <span className="text-bold">Edited by</span>
            <span>{original.modifiedUser}</span>
          </div>
          <div className="sub-row">
            <span className="text-bold">Date created</span>
            <span>{original.createdAt}</span>
          </div>
          <div className="sub-row">
            <span className="text-bold">Closeout date</span>
            <span>{leasingDetails?.contractLine?.orderEndDate ? Moment(leasingDetails?.contractLine?.orderEndDate).format('MM/DD/YYYY') : emDashUnicode}</span>
          </div>
          {!isMas && (remainingDays > 0 || onHold) && (
            <div
              data-testId="daysRemaining"
              className={`days-remaining sub-row ${remainingDays <= 10 ? 'text-red' : ''
                }`}
            >
              <span className="text-bold">
                Days remaining
                <InfoTooltip
                  label="Days remaining"
                  title="Days remaining"
                  text={daysRemainingInfoTxt}
                />
              </span>
              <span className={cn('text-bold', { 'text-red': onHold })}>
                {onHold ? 'On hold' : remainingDays}
              </span>
            </div>
          )}
        </div>
      </div>

      {original.transactionType === TRANSACTION_TYPES.LEASING && (
        <>
          <div className='leasing-details'>
            <div className='leasing-details-header'>Leasing details</div>

            {(loadingLeasingDetail || loadingAccount) ? (
              <Spinner className='margin-y-5' />
            ) : leasingDetails && (
              <div className='sub-component'>
                <div className="tablet:grid-col margin-bottom-3 margin-right-4">
                  <div className="sub-row">
                    <span className="text-bold">Leasing agency</span>
                    <span>
                      {[leasingDetails?.account?.customer?.customerAgency?.id, leasingDetails?.account?.customer?.customerAgency?.name].filter(Boolean).join(' - ') || emDashUnicode}
                    </span>
                  </div>

                  <div className="sub-row">
                    <span className="text-bold">Leasing bureau</span>
                    <span>
                      {[leasingDetails?.account?.customer?.customerBureau?.id, leasingDetails?.account?.customer?.customerBureau?.name].filter(Boolean).join(' - ') || emDashUnicode}
                    </span>
                  </div>

                  <div className="sub-row">
                    <span className="text-bold">Leasing office</span>
                    <span>
                      {[leasingDetails?.account?.customer?.customerPhysicalOffice?.officeCode, leasingDetails?.account?.customer?.customerPhysicalOffice?.officeName].filter(Boolean).join(' - ') || emDashUnicode}
                    </span>
                  </div>

                  <div className="sub-row">
                    <span className="text-bold">Customer BOAC</span>
                    <span>
                      {leasingDetails.account?.customer?.boac || emDashUnicode}
                    </span>
                  </div>
                </div>

                <div className="tablet:grid-col margin-bottom-3">
                  <div className="sub-row">
                    <span className="text-bold">Customer account name</span>
                    <span>
                      {leasingDetails.account?.customer?.accountName || emDashUnicode}
                    </span>
                  </div>

                  <div className="sub-row">
                    <span className="text-bold">Customer account number</span>
                    <span>
                      {leasingDetails.account?.customer?.customerId || emDashUnicode}
                    </span>
                  </div>

                  <div className="sub-row">
                    <span className="text-bold">Legacy customer number</span>
                    <span>
                      {leasingDetails.account?.customer?.legacyCustomerNumber || emDashUnicode}
                    </span>
                  </div>

                  <div className="sub-row">
                    <span className="text-bold">Zone</span>
                    <span>
                      {leasingDetails.account?.fleetManagementCenter?.zone?.name || emDashUnicode}
                    </span>
                  </div>

                  <div className="sub-row">
                    <span className="text-bold">FMC Name</span>
                    <span>
                      {leasingDetails.account?.fleetManagementCenter?.name || emDashUnicode}
                    </span>
                  </div>

                  <div className="sub-row">
                    <span className="text-bold">FSR Name</span>
                    <span>
                      {[
                        leasingDetails.account?.fsrUserDetails?.firstName,
                        leasingDetails.account?.fsrUserDetails?.lastName
                      ].filter(Boolean).join(' ') || emDashUnicode}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

SubComponent.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
};

const MyRequisitionsTable = () => {
  const {
    showPutOnHoldModal,
    showEditModal,
    toggleShowEditModal,
    showCancelModal,
    toggleShowCancelModal,
    showReopenModal,
    toggleShowReopenModal,
    renameRequisition,
    newRequisitionName,
    setNewRequisitionName,
    requisitionNameError,
    selectedRequisition,
    cancelRequisition,
    cancelRequisitionComments,
    setCancelRequisitionComments,
    cancelRequisitionCommentError,
    reopenRequisition,
  } = useMyRequisitionsState();

  const dispatch = useDispatch();

  // requisitions
  const [requisitionsCnt, setRequisitionsCnt] = useState(0);
  const [requisitionsRows, setRequisitionsRows] = useState([]);
  const [order, setOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('updatedAt');

  // pagination
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReset, setResetPagination] = useState(false);
  const [filters] = useRecoilState(filterSelector);
  const [
    getRequisitions,
    {
      data: requisitionsResponseData,
      loading: onRequisitionsLoading,
      // error: onRequisitionsError,
    },
  ] = useLazyQuery(GET_REQUISITIONS_EXTENDED, {
    fetchPolicy: 'network-only',
  });

  // update filter & order
  useEffect(() => {
    const newFilterVariables = {
      order: [[`${OrderByMap[orderBy]}`, `${order}`]],
      offset: (currentPage - 1) * perPage,
      limit: perPage,
      filters
    };
    getRequisitions({
      variables: newFilterVariables,
    });
  }, [order, orderBy, filters, currentPage, perPage]);

  const handlePaginationChange = (newCurPage, newPerPage) => {
    if (newPerPage !== perPage) {
      setCurrentPage(1);
      setPerPage(newPerPage);
      setResetPagination(true);
      setTimeout(() => {
        setResetPagination(false);
      }, 1000);
    } else {
      setCurrentPage(newCurPage);
      setPerPage(newPerPage);
    }
  };

  // update requisitions data
  useEffect(() => {
    if (!requisitionsResponseData?.searchRequisitionExtended) {
      return;
    }
    const { searchRequisitionExtended } = requisitionsResponseData;
    setRequisitionsCnt(searchRequisitionExtended.count);
    setRequisitionsRows(searchRequisitionExtended.rows);
  }, [requisitionsResponseData]);

  const data = useMemo(() => {
    if (!requisitionsRows || !requisitionsRows.length) {
      return [];
    }
    return requisitionsRows.map((row) => getTableColumns(row));
  }, [requisitionsRows]);
  
  const columns = useMemo(() => REQUISITION_TABLE_COLUMN_HEADERS, []);

  useEffect(() => {
    const triggerInitialLoad = async () => {
      // console.log('triggerInitialLoad');
      dispatch(initializeDependencies(['getOrders', 'getRequisitions']));
    };
    triggerInitialLoad();
  }, []);

  return (
    <>
      <div className="table-main-container">
        <AFPTable
          testId="my-requisitions-table"
          columns={columns}
          data={onRequisitionsLoading ? [] : data}
          expandable
          renderRowSubComponent={
            (row) =>
              <SubComponent {...row} />
          }
          onSort={(sortOrder) => {
            const [newOrderBy, newOrder] = sortOrder.split(' ');

            setOrderBy(newOrderBy.replace(/`/g, ''));
            setOrder(newOrder);
          }}
          defaultSort="updated_at DESC"
        />
        {showEditModal && (
          <div
            className="afp-modal-overlay modalContainer modal-center"
            data-testid="requisition-rename-modal"
          >
            <Modal
              title={`Rename ${selectedRequisition.values.name}`}
              className="rename-requisition-modal"
              onClose={() => toggleShowEditModal(false)}
              actions={
                <>
                  <Button
                    type="button"
                    className="cancel-button margin-right-3"
                    variant="unstyled"
                    data-testid="cancel-rename-requisition"
                    onClick={() => toggleShowEditModal(false)}
                    label="Cancel"
                  />
                  <Button
                    type="button"
                    data-testid="confirm-rename-requisition"
                    onClick={renameRequisition}
                    label=" Rename"
                  />
                </>
              }
            >
              <TextInput
                label="Enter a new name for this draft requisition"
                onChange={(e) => setNewRequisitionName(e.target.value)}
                value={newRequisitionName}
                errorMessage={requisitionNameError}
                data-testid="rename-requisition-name-input"
              />
            </Modal>
          </div>
        )}
        {showCancelModal && (
          <div
            className="afp-modal-overlay modalContainer modal-center"
            data-testid="requisition-cancel-modal"
          >
            <Modal
              title="Are you sure you would like to cancel the following requisitions?"
              className="cancel-requisition-modal"
              onClose={() => {
                toggleShowCancelModal(false);
              }}
              actions={
                <>
                  <Button
                    type="button"
                    className="cancel-button margin-right-3"
                    variant="unstyled"
                    data-testid="cancel-requisition-cancel"
                    onClick={() => toggleShowCancelModal(false)}
                    label="Cancel"
                  />
                  <Button
                    type="button"
                    data-testid="save-requisition-cancel"
                    onClick={cancelRequisition}
                    label="Save"
                  />
                </>
              }
            >
              <div className="modal-body">
                <div className="modal-body-title">
                  <span>{selectedRequisition.values.name}</span>
                </div>
                <br />

                <div className="modal-body-sub-title">
                  Provide comments for this cancellation
                </div>
                <TextInput
                  type="textarea"
                  maxLength={500}
                  placeholder="Type comment here..."
                  errorMessage={cancelRequisitionCommentError}
                  value={cancelRequisitionComments}
                  onChange={(e) => setCancelRequisitionComments(e.target.value)}
                  hint="500 characters allowed"
                  data-testid="save-requisition-comments"
                  containerClassName="modal-textarea"
                />
                <div className="modal-info-box">
                  <span className="modal-info-space" />
                  <img
                    src={InfoSVG}
                    className="modal-info-icon"
                    alt="info icon"
                  />
                  <span className="modal-info-text">
                    Canceled requisitions can be reopened through the Actions
                    column. Canceled requisitions will be deleted after 365
                    days.
                  </span>
                </div>
              </div>
            </Modal>
          </div>
        )}
        {showReopenModal && (
          <div
            className="afp-modal-overlay modalContainer modal-center"
            data-testid="requisition-reopen-modal"
          >
            <Modal
              title="Are you sure you would like to reopen the following requisitions?"
              className="reopen-requisition-modal"
              onClose={() => toggleShowReopenModal(false)}
              actions={
                <>
                  <Button
                    type="button"
                    className="cancel-button margin-right-3"
                    variant="unstyled"
                    data-testid="cancel-requisition-reopen"
                    onClick={() => toggleShowReopenModal(false)}
                    label="Cancel"
                  />
                  <Button
                    type="button"
                    onClick={() => {
                      reopenRequisition();
                    }}
                    data-testid="save-requisition-reopen"
                    label="Save"
                  />
                </>
              }
            >
              <div className="modal-body">
                <div className="modal-body-title">
                  <span>{selectedRequisition.values.name}</span>
                </div>
                <br />
              </div>
            </Modal>
          </div>
        )}
        {showPutOnHoldModal && (
          <PutOnHoldModal requisition={selectedRequisition?.original} />
        )}
        {onRequisitionsLoading && <Spinner className="margin-y-9" />}
        {!onRequisitionsLoading && (
          <Pagination
            buttonVariant="primary"
            boundaryCount={1}
            variant="advanced"
            itemsCount={requisitionsCnt}
            currentPage={currentPage}
            itemsPerPage={perPage}
            siblingCount={1}
            isReset={isReset}
            onPageChange={handlePaginationChange}
            defaultSort={`${orderBy}, ${order}`}
          />
        )}

        {!requisitionsRows?.length && !onRequisitionsLoading && (
          <EmptyState
            data-testid="table-no-items"
            containerStyles="table-no-items"
            topText="No Requisitions Available"
            topTextStyles="title"
            bottomText="There are no matches for the filtered values at left."
            bottomTextStyles="description"
          />
        )}
      </div>
    </>
  );
};

export default MyRequisitionsTable;
