import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../../utilities/CompareVehicleUtils';
import './AgencyFinancialInformation.scss';
import { STEPS } from '../../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';
import SectionEditButton from '../SectionEditButton/SectionEditButton';
import { isDodUser } from '../../../pages/VehicleRequisition/utils/VehicleRequisitionUtils';
import { formatPhoneNumber } from '../../../pages/ReviewDetails/RequisitionDetailsUtils';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';

const AgencyFinanceInfo = ({
  selectedContractAgencyInformation,
  standardItemData = {},
  isEditable,
  onEdit,
  isAreq,
  requisitionNumber,
}) => {
  const { state = {} } = useContext(VehicleRequisitionContext);
  const { requisitionStateContext } = state;

  const isDod = isDodUser();

  const displayReqNumber = useMemo(
    () =>
      (requisitionNumber)?.toUpperCase() ||
      `${selectedContractAgencyInformation.requisitionBOAC}-
    ${selectedContractAgencyInformation.requisitionJulian}-
    ${selectedContractAgencyInformation.requisitionSerialNumber}`,
    [requisitionNumber, selectedContractAgencyInformation],
  );

  return (
    <div tabIndex="0" className="agency-financial-info-section">
      <div className="agency-info-section">
        <div className="agency-title-section title-section">
          <span>Agency information</span>
          <SectionEditButton
            showButton={isEditable}
            id="agency-information-edit"
            step={STEPS.AGENCY_INFORMATION}
            editPage={onEdit}
          />
        </div>
        <div className="row-titles">
          <div className="row-column">
            <div className="row-title">Agency</div>
            <div className="title-desc">
              {selectedContractAgencyInformation.agency}
            </div>
          </div>
          <div className="row-column">
            <div className="row-title">Bureau name</div>
            <div className="title-desc">
              {selectedContractAgencyInformation.bureau}
            </div>
          </div>
          <div className="row-column">
            <div className="row-title">Agency order number</div>
            <div className="title-desc title-number">
              {selectedContractAgencyInformation.agencyOrderNumber}
            </div>
          </div>
        </div>

        <div className="row-titles">
          <div className="row-column">
            <div className="row-title">Requisition number</div>
            <div className="title-desc title-number">{displayReqNumber}</div>
          </div>
        </div>

        <div className="row-titles">
          <div className="row-column">
            <div className="row-title">Group assignment</div>
            <div className="">
              {selectedContractAgencyInformation.groupAssignment}
            </div>
          </div>
          {isDod && (
            <>
              <div className="row-column">
                <div className="row-title">
                  Accounting classification reference number
                </div>
                <div className="title-desc">
                  {
                    selectedContractAgencyInformation.accountingClassificationReferenceNumber
                  }
                </div>
              </div>
              <div className="row-column">
                <div className="row-title">Transportation control number</div>
                <div className="title-desc">
                  {
                    selectedContractAgencyInformation.transportationControlNumber
                  }
                </div>
              </div>
            </>
          )}
          {!isDod && (
            <>
              <div className="row-column">
                <div className="row-title">Description</div>
                <div className="title-desc">
                  {standardItemData?.standardItemNumber}{' '}
                  {standardItemData?.title}
                </div>
              </div>
              <div className="row-column">
                <div className="row-title">Treasury account symbol</div>
                <div className="title-desc">
                  {selectedContractAgencyInformation?.treasuryAccountSymbol}
                </div>
              </div>
            </>
          )}
        </div>
        {isDod && (
          <div className="row-titles">
            <div className="row-column">
              <div className="row-title">Mark for information</div>
              <div className="title-desc">
                {selectedContractAgencyInformation.markForInformation}
              </div>
            </div>
            <div className="row-column">
              <div className="row-title">National stock number</div>
              <div className="title-desc">
                {selectedContractAgencyInformation.nationalStockNumber}
              </div>
            </div>
            <div className="row-column">
              <div className="row-title">Registration number</div>
              <div className="title-desc">
                {selectedContractAgencyInformation.registrationNumber}
              </div>
            </div>
          </div>
        )}
        <div className="row-titles">
          {isDod && (
            <div className="row-column">
              <div className="row-title">Description</div>
              <div className="title-desc">
                {standardItemData?.standardItemNumber} {standardItemData?.title}
              </div>
            </div>
          )}

          <div className="row-column">
            <div className="row-title">Agency point of contact</div>
            <div className="title-desc">
              <div>
                {selectedContractAgencyInformation.firstName}{' '}
                {selectedContractAgencyInformation.lastName}
              </div>
              <div>{selectedContractAgencyInformation.email}</div>
              <div>
                {formatPhoneNumber(
                  selectedContractAgencyInformation.phoneCountryCallingCode,
                  selectedContractAgencyInformation.phoneNumber,
                  requisitionStateContext?.phoneExtension ||
                    selectedContractAgencyInformation?.phoneExtension,
                )}
              </div>
            </div>
          </div>
          {isDod && <div className="row-column" />}
        </div>
        <div className="financial-info-section">
          <div className="row-titles">
            <div className="row-column">
              <div className="row-title">Signal code</div>
              <div className="title-desc title-number">
                {selectedContractAgencyInformation.signalCode}
              </div>
            </div>
            <div className="row-column">
              <div className="row-title">Service code</div>
              <div className="title-desc title-number">
                {selectedContractAgencyInformation.serviceCode}
              </div>
            </div>
            <div className="row-column">
              <div className="row-title">Fund code</div>
              <div className="title-desc title-number">
                {selectedContractAgencyInformation.fundCode}
              </div>
            </div>
          </div>
          {selectedContractAgencyInformation?.signalSupplementaryAddress && (
            <>
              <div className="row-titles">
                <div className="row-column">
                  <div className="row-title">Supplementary address</div>
                  <div className="title-desc title-number">
                    {
                      selectedContractAgencyInformation?.signalSupplementaryAddress
                    }
                  </div>
                </div>
                {isAreq && (
                  <div className="row-column">
                    <div className="row-title">
                      Additional funds per unit <br />
                      for AREQs
                    </div>
                    <div className="title-desc title-number">
                      {formatCurrency(
                        selectedContractAgencyInformation?.finAdditionalFundsPerUnit ||
                          selectedContractAgencyInformation?.additionalAreqFunds,
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="row-column" />
              <div className="row-column" />
            </>
          )}
          {!selectedContractAgencyInformation?.signalSupplementaryAddress && (
            <div className="row-titles">
              {isAreq && (
                <>
                  <div className="row-column">
                    <div className="row-title">
                      Additional funds per unit <br />
                      for AREQs
                    </div>
                    <div className="title-desc title-number">
                      {formatCurrency(
                        selectedContractAgencyInformation?.finAdditionalFundsPerUnit ||
                          selectedContractAgencyInformation?.additionalAreqFunds,
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          <div className="financial-single-row">
            <div tabIndex="0">
              <div className="row-title">Agency financial data</div>
              <div className="title-desc title-number">
                {selectedContractAgencyInformation.agencyFinancialData}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AgencyFinanceInfo.propTypes = {
  selectedContractAgencyInformation: PropTypes.instanceOf(Object).isRequired,
  standardItemData: PropTypes.instanceOf(Object),
  isEditable: PropTypes.bool,
  isAreq: PropTypes.bool,
  requisitionNumber: PropTypes.string,
};

AgencyFinanceInfo.defaultProps = {
  isEditable: false,
  isAreq: false,
  standardItemData: {},
  requisitionNumber: '',
};

export default AgencyFinanceInfo;
