import React, { useMemo } from 'react';
import AddressComponent from './address-component/AddressComponent';
import useBulkOrderModsState from '../../useBulkOrderModsState';
import './DeliveryAddress.scss';
import { TRANSACTION_TYPES } from '../../../../utilities/CompareVehicleUtils.jsx';
import { formatPhoneNumber } from '../../../../components/ReviewComponents/AgencyFinancial/agencyFinancialUtils.jsx';

export const requisitionSection = {
  id: 'requisitionAddress',
  addressKey: 'requisitionAddress',
  contactKey: 'requisitionContact',
  isChecked: false,
  title: 'Requisitioning address',
  subTitle:
    'Enter the complete address of the office requisitioning the vehicle.',
  canBeSameAsRequisition: false,
};

export const mailingSection = {
  id: 'mailingAddress',
  addressKey: 'mailingAddress',
  contactKey: 'mailingContact',
  isChecked: false,
  title: 'Mailing address',
  subTitle:
    "Certificates of Origin are made out to the agency and address provided in the mailing address. Parts and service manuals are also sent to the mailing address. Do not provide a person's name in the mailing address.",
  canBeSameAsRequisition: true,
};

export const deliverySection = {
  id: 'deliveryAddress',
  addressKey: 'deliveryAddress',
  contactKey: 'deliveryContact',
  isChecked: false,
  title: 'Delivery address',
  subTitle: '',
  canBeSameAsRequisition: true,
};
export const stateCodeFormatted = (stateCode) => {
  const statePart = stateCode?.split('-')[1];
  if (statePart) {
    return `${statePart},`;
  }
  return stateCode ? `${stateCode},` : '';
};
export const getAddressCountryCode = (addressInfo) => {
  if (
    addressInfo?.address?.isMilitary ||
    addressInfo?.address?.isDomestic ||
    addressInfo?.address?.countryCode === 'US'
  ) {
    return null;
  }
  return addressInfo?.address?.countryCode;
};
function getDefaultValuesForAddress(order, addressKey, contactKey) {
  return {
    address: {
      addressLine1: order[addressKey]?.addressLine1,
      addressLine2: order[addressKey]?.addressLine2,
      city: order[addressKey]?.city,
      stateCode: order[addressKey]?.stateCode,
      zipcode: order[addressKey]?.zipcode,
      countryCode: order[addressKey]?.countryCode,
      entityName: order[addressKey]?.entityName || order?.entityName,
    },
    contact: {
      firstName: order[contactKey]?.firstName,
      lastName: order[contactKey]?.lastName,
      email: order[contactKey]?.email,
      phone: {
        countryCode: order[contactKey]?.phoneCountryCode,
        number: order[contactKey]?.phoneNumber,
        extension: order[contactKey]?.phoneExtension,
      },
      fax: {
        countryCode: order[contactKey]?.faxCountryCode,
        number: order[contactKey]?.faxNumber,
        extension: order[contactKey]?.faxExtension,
      },
    },
  };
}
const getReadOnlyAddress = (addressInfo) => {
    return (
        <div className="title-desc">
          {addressInfo?.isMilitary && (
            <>
              <div>{addressInfo?.rankAndFullName}</div>
              <div>{addressInfo?.postOffice}</div>
            </>
          )}
          <div>
            {addressInfo?.entityName}
          </div>
          <div>
            {addressInfo?.addressLine1}
            {', '}
            {addressInfo?.addressLine2}
          </div>
          <div>
            {addressInfo?.city}
            {', '} {stateCodeFormatted(addressInfo?.stateCode)}{' '}
            {addressInfo?.zipcode}
          </div>
          <div>{getAddressCountryCode(addressInfo)}</div>
        </div>
    );
};
const getReadOnlyContact = (contactInfo) => {
  return (
    <div className="title-desc">
      <div>
        {contactInfo?.firstName} {contactInfo?.lastName}
      </div>
      <div>{contactInfo?.email}</div>
      <div>
        {contactInfo?.phone?.countryCode &&
          `Phone: ${contactInfo?.phone?.countryCode}`}
        {contactInfo?.phone?.number &&
          `-${formatPhoneNumber(contactInfo?.phone?.number)}`}
        {contactInfo?.phone?.extension &&
          ` Ext. ${contactInfo.phone.extension}`}
      </div>
      <div>
        {contactInfo?.fax?.countryCode &&
          `Fax: ${contactInfo?.fax?.countryCode}`}
        {contactInfo?.fax?.number && `-${contactInfo?.fax?.number}`}
        {contactInfo?.fax?.extension &&
          ` Ext. ${contactInfo.fax.extension}`}
      </div>
    </div>

  );
};
const DeliveryAddress = () => {
  const {
    mailingSameAsRequisition,
    deliverySameAsRequisition,
    setMailingSameAsRequisition,
    setDeliverySameAsRequisition,
    selectedOrdersForMod,
  } = useBulkOrderModsState();

  const {shouldUseOrderAddresses, transactionType }= useMemo(() => {
    // if all selectedOrdersForMod have the same requisitionNumber
    return ({
      shouldUseOrderAddresses: (selectedOrdersForMod.length > 0 &&
        selectedOrdersForMod.every(
          (order, _index, orders) =>
            order.requisitionNumber === orders[0].requisitionNumber,
        )),
      transactionType: selectedOrdersForMod[0]?.transactionType
    });
  }, [selectedOrdersForMod]);

  let requisitionAddressDefaults = null;
  let mailingAddressDefaults = null;
  let deliveryAddressDefaults = null;
  if (shouldUseOrderAddresses) {
    requisitionAddressDefaults = getDefaultValuesForAddress(
      selectedOrdersForMod[0],
      'requisitionerAddress',
      'requisitionerContact',
    );
    mailingAddressDefaults = getDefaultValuesForAddress(
      selectedOrdersForMod[0],
      'mailingAddress',
      'mailingContact',
    );
    deliveryAddressDefaults = getDefaultValuesForAddress(
      selectedOrdersForMod[0],
      'deliveryAddress',
      'deliveryContact',
    );
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
      {transactionType === TRANSACTION_TYPES.LEASING ? (
        <>
        <div className="order-mod-delivery-info-column">
          <div>
            <p className="order-mod-acc-sub-title">Requisition Address</p>
          </div>
          {getReadOnlyAddress(requisitionAddressDefaults?.address)}
          <div>
            <div className="order-mod-acc-sub-title">Requisition point of contact</div>
          </div>
          {getReadOnlyContact(requisitionAddressDefaults?.contact)}
        </div>
        <div className="order-mod-delivery-info-column">
          <div>
            <p className="order-mod-acc-sub-title">Mailing Address</p>
          </div>
          {getReadOnlyAddress(mailingAddressDefaults?.address)}
          <div>
            <div className="order-mod-acc-sub-title">Mailing point of contact</div>
          </div>
          {getReadOnlyContact(mailingAddressDefaults?.contact)}
        </div>
        </>
      ) : (
        <>
        <AddressComponent
          section={requisitionSection}
          addressDefaults={requisitionAddressDefaults}
        />
        <AddressComponent
          section={ mailingSection}
          sameAsRequisition={mailingSameAsRequisition}
          setSameAsRequisition={setMailingSameAsRequisition}
          addressDefaults={mailingAddressDefaults}
        />
        </>
      )}

      <AddressComponent
        section={deliverySection}
        sameAsRequisition={deliverySameAsRequisition}
        setSameAsRequisition={setDeliverySameAsRequisition}
        addressDefaults={deliveryAddressDefaults}
      />
    </div>
  );
};

export default DeliveryAddress;
