import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Modal, Button } from '@gsa/afp-component-library';
import ViewImageSvg from '../../../../assets/images/view-image.svg';
import DefaultImageSvg from '../../../../assets/images/default-image.svg';
import { PhotosModalComp } from '../../../../components/PhotosModal/PhotosModalComp';
import './VehicleInformation.scss';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import AREQSidebar from './AREQSidebar';
import { getSortedClarifications } from '../../../../components/VendorClarifications/vendorClarificationUtil';
import { EXCLUDED_FUEL_TYPES } from '../../../../utilities/CompareVehicleUtils';
import { emDashUnicode } from '@gsa/afp-shared-form-utils';

const { appURLs } = window.AFP_CONFIG;

const VehicleInformation = ({ contract, options, quantity, standardItem, selectedContractCostBreakdown, isAreq, engine, unavailableOptions = [] }) => {
/*  const { state } = useContext(VehicleRequisitionContext);
  const { selectedContractCostBreakdown, isAreq } = state;*/
  const [previewClicked, setPreviewClicked] = useState(false);
  const [heroicImageState, setHeroicImageState] = useState();
  const [vehicleBodyIcon, setVehicleBodyIcon] = useState();
  const imageData = contract?.offerLineArtifact;
  const defaultImg = DefaultImageSvg;
  const [vendorClrfClicked, setVendorClrfClicked] = useState(false);
  const [allImages, setAllImages] = useState([]);

  // NOTE: Keeping parse for backward compatibility
  const localStandardItem = standardItem?.standardItemNumber
    ? standardItem
    : JSON.parse(sessionStorage.getItem('standardItem'));

  useEffect(() => {
    if (previewClicked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [previewClicked]);

  const heroicImage =
    imageData?.length &&
    imageData?.filter((item) => {
      return item.offerLineArtifactSequenceNo === '1';
    });

  useEffect(() => {
    const svgCode = localStandardItem?.vehicleTypeCode?.code.concat('_black');
    const iconURL = `${appURLs.cdnUrl}/icons/sin-sub-category/${svgCode}.svg`;
    if (imageData?.length) {
      const heroicImageURL = `${appURLs.cdnUrl}/assets/offer/${heroicImage[0]?.offerLineArtifactName}`;
      setHeroicImageState(heroicImageURL);
    }
    setVehicleBodyIcon(iconURL);
  }, []);

  const handlePreviewModal = () => {
    setPreviewClicked(true);
  };

  const handleImageError = () => {
    setHeroicImageState('');
  };

  const handleIconError = () => {
    const iconURL = `${appURLs.cdnUrl}/icons/sin-sub-category/02.svg`;
    setVehicleBodyIcon(iconURL);
  };

  const getSelectedNotAvailableOptions = () => {
    const contractsToReturn = [];
    if (selectedContractCostBreakdown) {
      selectedContractCostBreakdown.perOrderOptions?.forEach((option) => {
        if (option.optionType === 'NA') {
          contractsToReturn.push(option);
        }
      });
      selectedContractCostBreakdown.perVehicleOptions?.forEach((option) => {
        if (option.optionType === 'NA') {
          contractsToReturn.push(option);
        }
      });
    }
    if (options && options.length > 0) {
      options.forEach((option) => {
        if (option.options[0].optionType === 'NA') {
          contractsToReturn.push(option.options[0]);
        }
      });
    }
    return contractsToReturn;
  };

  useEffect(() => {
    if (contract?.attachments) {
      const allImagesURL = contract.attachments.map((image) => {
        return `${image?.documentMetadata?.docStoreUri}/${image?.documentMetadata?.name}`;
      });
      setAllImages(allImagesURL);
      if (allImagesURL && allImagesURL.length > 0)
        setHeroicImageState(allImagesURL[0]);
    } else {
      setAllImages([]);
    }
  }, [contract?.attachments]);

  const handleVendorClarificationModal = () => {
    setVendorClrfClicked(true);
  };
  return (
    <div className="vehicle-information">
      <div className="usa-card vehicle-information-card">
        <div className="usa-card__container vehicle-information-card__container">
          <div className="usa-card__media usa-card__media--inset vehicle-information-card__container__media" />
          <div className="usa-card__body vehicle-information-card__container__body">
            {isAreq && <AREQSidebar />}
            {isAreq || (
              <div className="veh-information-image">
                {heroicImageState !== '' &&
                contract?.attachments &&
                contract?.attachments?.length ? (
                  <img
                    data-testid="veh-info-preview-img"
                    src={heroicImageState}
                    className="vehinfo-section-image"
                    alt=""
                    onError={() => handleImageError()}
                  />
                ) : (
                  <div className="veh-info-default-image-section">
                    <img
                      src={defaultImg}
                      className="vehinfo-default-image"
                      alt=""
                    />
                  </div>
                )}

                {heroicImage?.length && heroicImageState !== '' && (
                  <div
                    data-testid="image-hover-click-id"
                    className="veh-info-hover-section"
                    onClick={handlePreviewModal}
                    onKeyDown={() => {
                      // do nothing
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <div className="veh-info-preview-details-container">
                      <img
                        data-testid="sampleVehicle-vehicle-card-image-alt"
                        className="veh-info-preview-icon"
                        src={ViewImageSvg}
                        alt="ViewImageSvg"
                      />
                      <div className="preview-image-label">View Photos</div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="grid-row">
              <div className="grid-col-4">
                <img
                  data-testid="veh-req-icon"
                  className="veh-standard-icon"
                  src={vehicleBodyIcon}
                  alt="ambulance icon"
                  onError={() => handleIconError()}
                />
              </div>
              <div className="grid-col-8">
                <div
                  className="vehicle-information-card__container__body__description"
                  data-testid="vehicle-information-card__container__body__description"
                >
                  {localStandardItem?.standardItemNumber} -{' '}
                  {localStandardItem?.title}
                </div>
              </div>
            </div>
            {isAreq || (
              <>
                <hr />
                <div className="grid-row vehicle-information-card__container__body__description__info">
                  <div
                    className="grid-col-6 vehicle-information-card__container__body__description__info__title"
                    data-testid="vehicle-information-card__vendor__title"
                  >
                    Vendor:
                  </div>
                  <div
                    className="grid-col-6 vehicle-information-card__container__body__description__info__desc"
                    data-testid="vehicle-information-card__vendor__name"
                  >
                    {contract?.vendorName || emDashUnicode}
                  </div>
                  <div className="grid-col-6 vehicle-information-card__container__body__description__info__title">
                    Model year:
                  </div>
                  <div
                    className="grid-col-6 vehicle-information-card__container__body__description__info__desc"
                    data-testid="vehicle-information-card__model__year"
                  >
                    {contract?.modelYear || emDashUnicode}
                  </div>
                  <div className="grid-col-6 vehicle-information-card__container__body__description__info__title">
                    Make:
                  </div>
                  <div
                    className="grid-col-6 vehicle-information-card__container__body__description__info__desc"
                    data-testid="vehicle-information-card__model__name"
                  >
                    {contract?.makeName || emDashUnicode}
                  </div>
                  <div className="grid-col-6 vehicle-information-card__container__body__description__info__title">
                    Model:
                  </div>
                  <div
                    className="grid-col-6 vehicle-information-card__container__body__description__info__desc"
                    data-testid="vehicle-information-card__model__name"
                  >
                    {contract?.modelName || emDashUnicode}
                  </div>
                  <div className="grid-col-6 vehicle-information-card__container__body__description__info__title">
                    Estimated delivery days:
                  </div>
                  <div
                    className="grid-col-6 vehicle-information-card__container__body__description__info__desc"
                    data-testid="vehicle-information-card__shipment__days"
                  >
                    {contract?.shipmentDays || emDashUnicode}
                  </div>
                  <div className="grid-col-6 vehicle-information-card__container__body__description__info__title">
                    MPG City/Hwy/Combo:
                  </div>
                  <div
                    className="grid-col-6 vehicle-information-card__container__body__description__info__desc"
                    data-testid="vehicle-information-card__MPG"
                  >
                    {EXCLUDED_FUEL_TYPES.includes(engine?.fuelType) ? (
                      'N/A'
                    ) : (
                      <>
                        {engine?.convMpgCity || emDashUnicode}
                        /
                        {engine?.convMpgHighway || emDashUnicode}
                        /
                        {engine?.convMpgCombined || emDashUnicode}
                      </>
                    )}
                  </div>
                  <div className="grid-col-6 vehicle-information-card__container__body__description__info__title">
                    gCO2/mile:
                  </div>
                  <div
                    className="grid-col-6 vehicle-information-card__container__body__description__info__desc"
                    data-testid="vehicle-information-card__GCO2"
                  >
                    {EXCLUDED_FUEL_TYPES.includes(engine?.fuelType) 
                      ? 'N/A'
                      : engine?.convGpm || emDashUnicode}
                  </div>
                  <div className="grid-col-6 vehicle-information-card__container__body__description__info__title">
                    Selected quantity:
                  </div>
                  <div
                    className="grid-col-6 vehicle-information-card__container__body__description__info__desc"
                    data-testid="vehicle-information-card__quantity"
                  >
                    {quantity}
                  </div>
                </div>
                {contract?.clarifications && (
                  <>
                    <hr />
                    <div className="grid-row vehicle-information-card__container__body__description__info">
                      <h3>Vendor clarifications</h3>
                        <Button
                          type="button"
                          className="cancel-button margin-right-3"
                          variant="unstyled"
                          data-testid="vendor-clarifications-view"
                          onClick={() => handleVendorClarificationModal()}
                          label="View vendor clarifications"
                        />
                    </div>
                  </>
                )}
              </>
            )}

            {unavailableOptions?.length > 0 && (
              <>
                <hr />
                <div className="grid-row vehicle-information-card__container__body__description__info">
                  <h3>Options not available</h3>
                  <ul>
                    {unavailableOptions?.map((option, index) => (
                      <li data-testid={`option-not-available-${index}`}>
                        {option.optionCode} - {option.optionDescription}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {previewClicked && (
        <div className="afp-modal-overlay modalContainer veh-req-photos-modal-wrapper">
          <Modal
            title={
              <div className="modal-title">
                <span className="model-name">
                  {contract.modelName || contract?.model?.modelCode}
                </span>
                <span className="vendor-name">{contract.vendorName}</span>
              </div>
            }
            onClose={() => {
              setPreviewClicked(false);
            }}
            className="photos-preview-modal"
            actions={
              <>
                <Button
                  data-testid="veh-req-photos-modal"
                  type="button"
                  className="modal-close-button"
                  onClick={() => {
                    setPreviewClicked(false);
                  }}
                  label="Close"
                />
              </>
            }
          >
            <PhotosModalComp imageData={allImages} />
          </Modal>
        </div>
      )}
      {vendorClrfClicked && (
        <div className="afp-modal-overlay modalContainer req-vendor-clrf-modal-wrapper">
          <Modal
            title={
              <div className="modal-title">
                <span className="model-name">
                        {(contract.modelName || contract?.modelCode) &&
                            `${contract.modelName || contract.modelCode} :`} Vendor Clarifications
                </span>
              </div>
            }
            onClose={() => {
              setVendorClrfClicked(false);
            }}
            className="main-modal"
            actions={
              <>
                <Button
                  data-testid="veh-req-vendor-clrf-modal-close"
                  type="button"
                  className="modal-close-button"
                  onClick={() => {
                    setVendorClrfClicked(false);
                  }}
                  label="Close"
                />
              </>
            }
          >
            {contract?.clarifications && (
                <div className="vendor-clarifications-list">

                <ul className="vendor-modal-list">
                {getSortedClarifications(
                  contract?.clarifications,
                )?.map(({ clarification }, index) => (
                  <li
                    key={`contract_clarification_${uuidv4()}`}
                    data-testid={`contract_clarification_${index}`}
                  >
                    {clarification}
                  </li>
                ))}
              </ul>
                </div>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};

VehicleInformation.defaultProps = {
  contract: {},
};

VehicleInformation.propTypes = {
  contract: PropTypes.oneOfType([PropTypes.object]),
  options: PropTypes.oneOfType([PropTypes.object]),
  quantity: PropTypes.number.isRequired,
  standardItem: PropTypes.shape({
    standardItemNumber: PropTypes.string,
    title: PropTypes.string,
    vehicleTypeCode: PropTypes.shape({
      code: PropTypes.string,
    }),
  }),
  selectedContractCostBreakdown: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isAreq: PropTypes.bool.isRequired,
  engine: PropTypes.oneOfType([PropTypes.object]),
  unavailableOptions: PropTypes.oneOfType([PropTypes.object]),

};

VehicleInformation.defaultProps = {
  options: null,
  standardItem: {},
  unavailableOptions:[]
};

export default React.memo(VehicleInformation);
