import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { useDispatch, useSelector } from 'react-redux';
import CommentActivityCard, { ORDER_ACTIVITY_TYPES } from '../../comments-activity/CommentActivityCard';
import CommentModal from '../../../components/CommentModal/CommentModal';
import { getOrderActivityDetails, getRequisitionActivityDetails, orderCommentsMutation } from '../../../requests/order';
import { setOrdersTab } from '../../../reducers/order';
import './OrderActivity.scss';

const OrderActivity = () => {

  const dispatch = useDispatch();
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [orderActivities, setOrderActivities] = useState([]);
  const {
    orderDetails,
    orderActivity,
  } = useSelector((state) => state.orderReducer);

  useEffect(() => {
    if (orderDetails?.orderId?.length > 0) {
      dispatch(getOrderActivityDetails(orderDetails?.orderId));
    }
    if(orderDetails?.requisitionId){
      dispatch(getRequisitionActivityDetails(orderDetails?.requisitionId));
    }
    dispatch(setOrdersTab('orderActivity'))
  }, []);

  useEffect(() => {
    if (orderActivity?.orderActivitiesData?.rows) {
      const rows = JSON.parse(JSON.stringify(orderActivity?.orderActivitiesData?.rows)) || [];
      const requisitionActivities = orderActivity?.requisitionActivitiesData?.rows || [];

      const combineActivities = rows
        .concat(requisitionActivities)
        .sort((hansel, gretel) => {
          const hanselTime = new Date(hansel.createdDateTime).getTime();
          const gretelTime = new Date(gretel.createdDateTime).getTime();
          return gretelTime - hanselTime;
        });
      setOrderActivities(combineActivities);
    }
  }, [orderActivity]);

  const handleAddComment = (comment) => {
    setShowAddCommentModal(false);
    dispatch(orderCommentsMutation({ orderId: orderDetails?.orderId,  comment }))
  };

  const handleCancelComment = () => {
    setShowAddCommentModal(false);
  };

  return (
    <div className="order-activities-container">
      <div className="display-flex flex-align-end">
        <Button
          type="button"
          variant="outline"
          data-testid="add-comment-button"
          className="margin-left-auto"
          onClick={() => {
            setShowAddCommentModal(true);
          }}
          leftIcon={{ name: 'edit' }}
          label="Add comment"
        />
      </div>
      <CommentModal
        data-testid="add-comment-modal"
        isOpen={showAddCommentModal}
        onSubmit={handleAddComment}
        onCancel={handleCancelComment}
      />

      <div className="contain_activity-trackr">
        {orderActivities?.length > 0 &&
          orderActivities?.map((orderActivity) => (
            <CommentActivityCard
              key={orderActivity?.id}
              activity={orderActivity}
              requisitionType="order"
            />
          ))}
      </div>
    </div>
  );
};


export default OrderActivity;
