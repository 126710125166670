import z from 'zod';
import { pointOfContactShema } from './point-of-contact-schema';

const USA_TERRITORIES = ['US', 'PR', 'VI', 'GU', 'MP', 'AS', 'FM', 'MH', 'PW'];

export function isInternationalCountry(country) {
  return country && !USA_TERRITORIES.includes(country.toUpperCase().trim());
}

const addressComponentSchema = z
  .object({
    isMilitary: z.boolean(),
    militaryAddress: z.object({
      rankAndFullName: z.string(),
      militaryOrDiplomaticAddress: z.string(),
      postOffice: z.string(),
    }),
    address: z.object({
      addressLine1: z.string(),
      addressLine2: z.string(),
      city: z.string(),
      stateCode: z.string(),
      countryCode: z.string(),
      entityName: z.string(),
    }),
    zipcode: z.string(),
    contact: pointOfContactShema,
  })
  .superRefine(({ address, militaryAddress, isMilitary, zipcode }, ctx) => {
    // US and territories use 5-digit or ZIP+4 format
    if (!address.countryCode || !isInternationalCountry(address.countryCode) || address.countryCode === 'Select Country') {
      const usZipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
      if (!usZipRegex.test(zipcode)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid zip code',
          path: [`zipcode`],
        });
      }
    } else {
      // International postal codes allow alphanumeric characters and common separators
      const internationalPostalRegex = /^[A-Za-z0-9\s\-]{1,12}$/;
      if (!internationalPostalRegex.test(zipcode)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a valid zip code',
          path: [`zipcode`],
        });
      }
    }

    if (isMilitary) {
      if (!militaryAddress.rankAndFullName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the rank and full name',
          path: ['militaryAddress', 'rankAndFullName'],
          fatal: true,
        });
      }
      if (!militaryAddress.militaryOrDiplomaticAddress) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the military or diplomatic address',
          path: ['militaryAddress', 'militaryOrDiplomaticAddress'],
          fatal: true,
        });
      }
      if (!militaryAddress.postOffice || militaryAddress.postOffice === '-1') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the post office',
          path: ['militaryAddress', 'postOffice'],
          fatal: true,
        });
      }
    } else {
      if (!address.addressLine1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the address line 1',
          path: ['address', 'addressLine1'],
          fatal: true,
        });
      }
      if (!address.city) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the city',
          path: ['address', 'city'],
          fatal: true,
        });
      }
      if (!address.stateCode || address.stateCode === '-1') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the state',
          path: ['address', 'stateCode'],
          fatal: true,
        });
      }
      if (!address.countryCode) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please fill out the country',
          path: ['address', 'countryCode'],
          fatal: true,
        });
      }
    }
  });

export default addressComponentSchema;
