import z from 'zod';
import { pointOfContactShema } from '../../../../requisitions-and-orders/change-sets/DeliveryAddress/address-component/point-of-contact-schema';

const dealershipDeliverySchema = z.object({
  selectedCountry: z.string(),
  selectedStateId: z.string().optional(),
  deliveryDealership: z.object({
    selectedCity: z.string().refine((val) => val && val !== '-1', {
      message: 'Please select a city',
    }),
    selectedDealerId: z.coerce.number().refine((val) => val && val > -1, {
      message: 'Please select a dealer',
    }),
  }).optional(),
  deliveryPoC: pointOfContactShema,
  fmcLocation: z.coerce.number().optional(),
  fieldOffice: z.string().optional(),
  fmcOfficeLocationDetails: z.object({
      location: z.string().optional(),
      deliveryAddress: z.object({
          address1: z.string().nullish(),
          address2: z.string().nullish(),
          address3: z.string().nullish(),
          street: z.string().nullish(),
          city: z.string().nullish(),
          state: z.string().nullish(),
          zip: z.string().nullish(),
          country: z.string().nullish(),
      }).optional(),
      fieldOffice: z.string().optional(),
      fieldOfficeDeliveryAddress: z.object({
          address1: z.string().nullish(),
          address2: z.string().nullish(),
          address3: z.string().nullish(),
          city: z.string().nullish(),
          state: z.string().nullish(),
          zip: z.string().nullish(),
      }).optional(),
  }).optional(),
  licenseDeliveryPoC: z.object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional(),
    phone: z.object({
      countryCode: z.string().optional(),
      number: z.string().optional(),
      extension: z.string().optional(),
    }).optional(),
    fax: z.object({
      countryCode: z.string().optional(),
      number: z.string().optional(),
      extension: z.string().optional(),
    }).optional(),
  }).optional(),
  selectedDealer: z.object({
    address1: z.coerce.string().nullish(),
    address2: z.coerce.string().nullish(),
    city: z.coerce.string().nullish(),
    country: z.coerce.string().nullish(),
    dealerName: z.coerce.string().nullish(),
    title: z.coerce.string().nullish(),
    dealershipDeliveryCode: z.coerce.string().nullish(),
    email: z.coerce.string().nullish(),
    id: z.coerce.string().nullish(),
    phone: z.coerce.string().nullish(),
    zip: z.coerce.string().nullish(),
    state: z.coerce.string().nullish(),
    stateId: z.coerce.number().nullish(),
    countryId: z.coerce.number().nullish(),
  }).nullish(),
}).superRefine((data, ctx) => {
  if (data.selectedCountry === 'US' || data.selectedCountry === 'PR') {
    if (!data.licenseDeliveryPoC.firstName) {
        ctx.addIssue({
            message: 'First name is required',
            code: z.ZodIssueCode.custom,
            path: ['licenseDeliveryPoC', 'firstName']
        })
    }

    if (!data.licenseDeliveryPoC.lastName) {
        ctx.addIssue({
            message: 'Last name is required',
            code: z.ZodIssueCode.custom,
            path: ['licenseDeliveryPoC', 'lastName']
        })
    }

    if (!data.licenseDeliveryPoC.email) {
        ctx.addIssue({
            message: 'Email is required',
            code: z.ZodIssueCode.custom,
            path: ['licenseDeliveryPoC', 'email']
        })
    } else if (!data.licenseDeliveryPoC.email.match(
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )) {
        ctx.addIssue({
            message: 'Email must be a valid email address',
            code: z.ZodIssueCode.custom,
            path: ['licenseDeliveryPoC', 'email']
        })
    }

    if (!data.licenseDeliveryPoC.phone.countryCode) {
        ctx.addIssue({
            message: 'Country code is required',
            code: z.ZodIssueCode.custom,
            path: ['licenseDeliveryPoC', 'phone', 'countryCode']
        });
    }

    if (!data.licenseDeliveryPoC.phone.number) {
        ctx.addIssue({
            message: 'Phone number is required',
            code: z.ZodIssueCode.custom,
            path: ['licenseDeliveryPoC', 'phone', 'number']
        });
    }
  }
})

export default dealershipDeliverySchema;
