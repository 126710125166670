import React, { useEffect } from 'react';
import { PageTitle, Button } from '@gsa/afp-component-library';
import { useNavigate } from 'react-router-dom';
import './LeasingPreFilter.scss';
import { useAppAbility, useTitle } from '@gsa/afp-shared-ui-utils';
import Breadcrumbs from './components/Breadcrumbs';
import { LeasingSteps } from './leasing-consts';
import {
  canCreateLease,
  canCreateNonStdLease,
  canRequestReplacementVehicle,
} from './authorization';
import { resetLeasingState } from '../../reducers/leasing';
import { useDispatch } from 'react-redux';

const LeasingPreFilter = () => {
  useTitle('Leasing');
  const navigate = useNavigate();
  const ability = useAppAbility();
  const dispatch = useDispatch();
  window.scrollTo(0, 0);

  useEffect(() => {
    if (!canCreateLease(ability) && !canRequestReplacementVehicle(ability)) {
      navigate('/');
    }
  }, [ability]);

  const PurchaseLandingPage = () => {
    return (
      <>
        <PageTitle title="Lease Vehicles" />
        <div className="leasing-main-section">
          <div>
            {canRequestReplacementVehicle(ability) && (
              <div className="leasing-left-section">
                <div className="leasing-title">
                  Request a replacement vehicle
                </div>
                <p className="sin-title-desc">
                  Use this feature to view vehicles referred for replacement,
                  request a replacement, and review and approve pending
                  requests.
                </p>

                <Button
                  label="Start here"
                  data-testid="vehicle-referral-button"
                  type="button"
                  variant="standard"
                  rightIcon={{ name: 'arrow_forward' }}
                  onClick={() => {
                    dispatch(resetLeasingState());
                    navigate(LeasingSteps.VEHICLE_REFERRAL);
                  }}
                />
              </div>
            )}
            {canCreateLease(ability) && (
              <div className="leasing-left-section">
                <div className="leasing-title">Build a requisition</div>
                <p className="sin-title-desc">
                  Use this feature to take the request approved by a GSA leasing
                  customer to create a requisition that becomes a vehicle order.
                </p>

                <Button
                  label="Start here"
                  data-testid="sop-button"
                  type="button"
                  variant="standard"
                  rightIcon={{ name: 'arrow_forward' }}
                  onClick={() => {
                    dispatch(resetLeasingState());
                    navigate(LeasingSteps.REPLACEMENT_VEHICLES);
                  }}
                />
              </div>
            )}
          </div>
          {canCreateNonStdLease(ability) && (
            <div className="leasing-right-section">
              <div className="non-sop-module">
                <div className="leasing-right-title">
                  Create a Non-Standard Order
                </div>
                <p className="sin-title-desc">
                  Use this feature to create and manage an order outside of the
                  GSA Fleet Standard Order Program. The non-standard order
                  process includes the following methods:
                </p>
                <ul className="non-sop-list">
                  <li>Multiple Award Schedules</li>
                  <li>Non-Standard Vehicles</li>
                  <li>Urgent Vehicle Order</li>
                </ul>
                <Button
                  label="Start here"
                  data-testid="non-sop-button"
                  type="button"
                  variant="outline"
                  rightIcon={{ name: 'arrow_forward' }}
                  onClick={() => {}}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="main-container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Breadcrumbs />
      </div>

      <div className="main-title">
        <PurchaseLandingPage />
      </div>
    </div>
  );
};

export default LeasingPreFilter;
