import React from 'react';
import PropTypes from 'prop-types';
import SectionEditButton from '../../../../components/ReviewComponents/SectionEditButton/SectionEditButton';
import { LeasingSteps } from '../../leasing-consts';

const ReviewDeliveryAddress = ({ addressInfo, isEditable, onEdit }) => {
  const selectedCountry = addressInfo?.deliveryAddress?.selectedCountry;
  const marshallingAddressObj = addressInfo?.deliveryAddress?.marshallingAddress?.deliveryAddress;
  const dealershipAddressObj = addressInfo?.deliveryAddress?.selectedDealer;
  const licensePlateAddressObj = addressInfo?.deliveryAddress?.fieldOffice && addressInfo?.deliveryAddress?.fieldOffice !== "-1" ? addressInfo?.deliveryAddress?.fmcOfficeLocationDetails?.fieldOfficeDeliveryAddress : addressInfo?.deliveryAddress?.fmcOfficeLocationDetails?.deliveryAddress;
  
  const marshallingAddress = {
    title: marshallingAddressObj?.title || '',
    address1: marshallingAddressObj?.address1 || '',
    address2: marshallingAddressObj?.address2 || '',
    address3: marshallingAddressObj?.address3 || '',
    city: marshallingAddressObj?.city || '',
    state: marshallingAddressObj?.state || '',
    zip: marshallingAddressObj?.zip || '',
    pointOfContact: { ...addressInfo?.deliveryAddress?.marshallingAddress?.pointOfContact },
  };

  const marshallingLicensePlateAddress =
    addressInfo?.deliveryAddress?.licenseDelivery !== 'same'
      ? {
          title: addressInfo?.deliveryAddress?.fieldOffice && addressInfo?.deliveryAddress?.fieldOffice !== "-1" ? addressInfo?.deliveryAddress?.fmcOfficeLocationDetails?.fieldOffice:  addressInfo?.deliveryAddress?.fmcOfficeLocationDetails?.location || '',
          address1: licensePlateAddressObj?.address1 || '',
          address2: licensePlateAddressObj?.address2 || '',
          address3: licensePlateAddressObj?.address3 || '',
          city: licensePlateAddressObj?.city || '',
          state: licensePlateAddressObj?.state || '',
          zip: licensePlateAddressObj?.zip || '',
          pointOfContact: {
            ...addressInfo?.deliveryAddress?.licenseDeliverPoC,
          },
        }
      : { ...marshallingAddress };

  const dealershipAddress = {
    title: dealershipAddressObj?.title || '',
    address1: dealershipAddressObj?.address1 || '',
    address2: dealershipAddressObj?.address2 || '',
    city: dealershipAddressObj?.city || '',
    state: dealershipAddressObj?.state || '',
    zip: dealershipAddressObj?.zip || '',
    pointOfContact: { ...addressInfo?.deliveryAddress?.deliveryPoC },
  };

  const dealerShipLicensePlateAddress =
    selectedCountry === 'US' || selectedCountry === 'PR'
      ? 
      {
        title:addressInfo?.deliveryAddress?.fieldOffice && addressInfo?.deliveryAddress?.fieldOffice !== "-1" ? addressInfo?.deliveryAddress?.fmcOfficeLocationDetails?.fieldOffice:  addressInfo?.deliveryAddress?.fmcOfficeLocationDetails?.location || '',
        address1: licensePlateAddressObj?.address1 || '',
        address2: licensePlateAddressObj?.address2 || '',
        address3: licensePlateAddressObj?.address3 || '',
        city: licensePlateAddressObj?.city || '',
        state: licensePlateAddressObj?.state || '',
        zip: licensePlateAddressObj?.zip || '',
        pointOfContact: {
          ...addressInfo?.deliveryAddress?.licenseDeliveryPoC,
        }
      }
      : {};

  const deliveryAddressData = addressInfo?.addressType === "DEALERSHIP" ? dealershipAddress : marshallingAddress;
  const licensePlateData = addressInfo?.addressType === "DEALERSHIP" ? dealerShipLicensePlateAddress : marshallingLicensePlateAddress;
   
  const addressContent = (formattedAddressData) => {
    return (
      <div
        role='region'
        tabIndex="0"
        className="address-section"
        onClick={(e) =>  e.stopPropagation()}
      >
        <div className="address-review-section">
          <div className="address-review-first-title">
            <div className="address-first-section">
              <div className="">{formattedAddressData?.title}</div>
              <div className="">{formattedAddressData?.address1}</div>
              <div className="">{formattedAddressData?.address2}</div>
              <div className="">{formattedAddressData?.address3}</div>
              <div className="">
                {formattedAddressData?.city} {formattedAddressData?.state} {' '}
                {formattedAddressData?.zip}
              </div>
            </div>

            <div className="address-review-second-title">
              <div>Point of contact</div>
              <div className="title-desc">
                <div>
                  {formattedAddressData?.pointOfContact?.firstName}{' '}
                  {formattedAddressData?.pointOfContact?.lastName}
                </div>
                <div>{formattedAddressData?.pointOfContact?.email}</div>
                <div> {formattedAddressData?.pointOfContact?.phone?.number}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="title-section">
        {' '}
        Delivery address
        {isEditable && (
          <SectionEditButton
            showButton={isEditable}
            id="delivery-address-vehicle-edit"
            step={LeasingSteps.DELIVERY_ADDRESS}
            editPage={onEdit}
          />
        )}
      </div>
      {addressContent(deliveryAddressData)}

      {licensePlateData && (
        <>
          <div className="title-section"> License plate delivery address</div>
          {addressContent(licensePlateData)}
        </>
      )}
    </>
  );
};

ReviewDeliveryAddress.propTypes = {
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func.isRequired
};

ReviewDeliveryAddress.defaultProps = {
  isEditable: false,
};

export default ReviewDeliveryAddress;
