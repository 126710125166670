import React, { useState } from 'react';
import { Button, useModal } from '@gsa/afp-component-library';
import DefaultImageSvg from '@/assets/images/default-image.svg';
import './vehicle-information.scss';
import AREQSidebar from '../areq-sidebar/areq-sidebar';
import { emDashUnicode } from '../../../../constants/constants';
import VendorClarificationModal from './vendor-clarification-modal/vendor-clarification-modal';
import VehicleImagePreview from './vehicle-image-preview/vehicle-image-preview';
import { EXCLUDED_FUEL_TYPES, OPTION_OPTION_TYPE } from '../../../../utilities/CompareVehicleUtils';

const { appURLs } = window.AFP_CONFIG;

export default function VehicleInformation({ contract, options, quantity, selectedContractCostBreakdown, standardItem, engine }) {
    const { isOpen, openModal, closeModal } = useModal();
    const imageData = contract?.offerLineArtifact;
    const isAreq = false;

    let heroicImageStateDefault = '';
    let allImages = [];
    if (contract?.attachments) {
        allImages = contract.attachments.map((image) => {
            return `${image?.documentMetadata?.docStoreUri}/${image?.documentMetadata?.name}`;
        });

        heroicImageStateDefault = allImages.length > 0 ? allImages[0] : '';
    }

    // NOTE: Keeping parse for backward compatibility
    const localStandardItem = standardItem?.standardItemNumber
        ? standardItem
        : JSON.parse(sessionStorage.getItem('standardItem'));

    const heroicImage = 
        imageData?.length &&
        imageData?.filter((item) => {
            return item.offerLineArtifactSequenceNo === '1';
        });
    
    const svgCode = localStandardItem?.vehicleTypeCode?.code?.concat('_black');
    const iconURL = `${appURLs.cdnUrl}/icons/sin-sub-category/${svgCode}.svg`;

    if (heroicImage && heroicImage.length > 0 && heroicImage[0]?.offerLineArtifactName) {
        heroicImageStateDefault = `${appURLs.cdnUrl}/assets/offer/${heroicImage[0]?.offerLineArtifactName}`;
    }
    
    
    const [heroicImageState, setHeroicImageState] = useState(heroicImageStateDefault);
    const [vehicleBodyIcon, setVehicleBodyIcon] = useState(iconURL);

    const handleImageError = () => {
        setHeroicImageState('');
    };

    const handleIconError = () => {
        const iconURL = `${appURLs.cdnUrl}/icons/sin-sub-category/02.svg`;
        setVehicleBodyIcon(iconURL);
    };

    const getSelectedNotAvailableOptions = () => {
        const contractsToReturn = [];
        if (selectedContractCostBreakdown) {
            selectedContractCostBreakdown.perOrderOptions.forEach((option) => {
                if (option.optionType === OPTION_OPTION_TYPE.NOT_AVAILABLE) {
                    contractsToReturn.push(option);
                }
            });
            selectedContractCostBreakdown.perVehicleOptions.forEach((option) => {
                if (option.optionType === OPTION_OPTION_TYPE.NOT_AVAILABLE) {
                    contractsToReturn.push(option);
                }
            });
        }
        return contractsToReturn;
    };

    return (
        <div className="requisition-vehicle-information">
            <div className="vehicle-information-card">
                {isAreq && <AREQSidebar />}
                {isAreq || (
                    <div className="vehicle-image">
                        {heroicImageState !== '' &&
                            contract?.attachments &&
                            contract?.attachments?.length ? (
                            <img
                                data-testid="veh-info-preview-img"
                                src={heroicImageState}
                                className="vehinfo-section-image"
                                alt=""
                                onError={() => handleImageError()}
                            />
                        ) : (
                            <img
                                src={DefaultImageSvg}
                                className="default-image"
                                alt=""
                            />
                        )}

                        <VehicleImagePreview
                            heroicImage={heroicImage}
                            contract={contract}
                            allImages={allImages}
                        />
                    </div>
                )}

                <div className="vehicle-type">
                    <img
                        data-testid="veh-req-icon"
                        className="vehicle-type-icon"
                        src={vehicleBodyIcon}
                        alt="ambulance icon"
                        onError={() => handleIconError()}
                    />
                    <span>
                        {localStandardItem?.standardItemNumber} -{' '}
                        {localStandardItem?.title}
                    </span>
                </div>
                {isAreq || (
                    <>
                        <hr />
                        <div className="requisition-vehicle-details">
                            <div className='vehicle-details-row'>
                                <div
                                    className="vehicle-details-title"
                                    data-testid="vehicle-information-card__vendor__title"
                                >
                                    Vendor:
                                </div>
                                <div
                                    className="vehicle-details-value"
                                    data-testid="vehicle-information-card__vendor__name"
                                >
                                    {contract?.vendorName || emDashUnicode}
                                </div>
                            </div>


                            <div className='vehicle-details-row'>
                                <div className="vehicle-details-title">
                                    Model year:
                                </div>
                                <div
                                    className="vehicle-details-value"
                                    data-testid="vehicle-information-card__model__year"
                                >
                                    {contract?.modelYear || emDashUnicode}
                                </div>
                            </div>


                            <div className='vehicle-details-row'>
                                <div className="vehicle-details-title">
                                    Make:
                                </div>
                                <div
                                    className="vehicle-details-value"
                                    data-testid="vehicle-information-card__make__name"
                                >
                                    {contract?.makeName || emDashUnicode}
                                </div>
                            </div>

                            <div className='vehicle-details-row'>
                                <div className="vehicle-details-title">
                                    Model:
                                </div>
                                <div
                                    className="vehicle-details-value"
                                    data-testid="vehicle-information-card__model__name"
                                >
                                    {contract?.modelName || emDashUnicode}
                                </div>
                            </div>

                            <div className='vehicle-details-row'>
                                <div className="vehicle-details-title">
                                    Estimated delivery days:
                                </div>
                                <div
                                    className="vehicle-details-value"
                                    data-testid="vehicle-information-card__shipment__days"
                                >
                                    {contract?.shipmentDays || emDashUnicode}
                                </div>
                            </div>

                            <div className='vehicle-details-row'>
                                <div className="vehicle-details-title">
                                    MPG:
                                </div>
                                <div
                                    className="vehicle-details-value"
                                    data-testid="vehicle-information-card__MPG"
                                >
                                    {EXCLUDED_FUEL_TYPES.includes(engine?.fuelType) ? (
                                        'N/A'
                                    ) : (
                                        <>
                                            {engine?.convMpgCity || emDashUnicode}/
                                            {engine?.convMpgHighway || emDashUnicode}/
                                            {engine?.convMpgCombined || emDashUnicode}
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className='vehicle-details-row'>
                                <div className="vehicle-details-title">
                                    gCO2/mile:
                                </div>
                                <div
                                    className="vehicle-details-value"
                                    data-testid="vehicle-information-card__GCO2"
                                >
                                    {EXCLUDED_FUEL_TYPES.includes(engine?.fuelType) 
                                        ? 'N/A'
                                        : engine?.convGpm || emDashUnicode}
                                </div>
                            </div>

                            <div className='vehicle-details-row'>
                                <div className="vehicle-details-title">
                                    Selected quantity:
                                </div>
                                <div
                                    className="vehicle-details-value"
                                    data-testid="vehicle-information-card__quantity"
                                >
                                    {quantity}
                                </div>
                            </div>
                        </div>

                        {contract?.clarifications?.length > 0 && (
                            <>
                                <hr />
                                <div className="requisition-vehicle-details">
                                    <h3>Vendor clarifications</h3>
                                    <Button
                                        type="button"
                                        className="cancel-button margin-right-3"
                                        variant="unstyled"
                                        data-testid="vendor-clarifications-view"
                                        onClick={() => openModal()}
                                        label="View vendor clarifications"
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}

                {getSelectedNotAvailableOptions().length > 0 && (
                    <>
                        <hr />
                        <div className="requisition-vehicle-details">
                            <h3>Options not available</h3>
                            <ul className="requisition-vehicle-list">
                                {getSelectedNotAvailableOptions().map((option, index) => (
                                    <li data-testid={`option-not-available-${index}`}>
                                        {option.optionCode} - {option.optionDescription}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                )}
            </div>

            <VendorClarificationModal
                contract={contract}
                isOpen={isOpen}
                closeModal={closeModal}
            />
        </div>
    );
};