import { REQUISITION_TYPE } from '../../pages/non-standard-purchase/constants';

export const AreqSections = {
  AllSections: 'All Sections',
  AreqInfo: 'AREQ details',
  OptionsPerVehicle: 'Options per vehicle',
  PaintAndGraphics: 'Paint and graphics',
  DeliveryInformation: 'Delivery information',
  AgencyInformation: 'Agency information',
  OptionsDetails: 'Options details',
  CostSummary: 'Cost summary',
};

export const MasSections = {
  AllSections: 'All Sections',
  VehicleReqs: 'MAS vehicle requirements',
  // ContractNum: 'Contract number and vehicle details',
  AgencyInformation: 'Agency information',
  DeliveryInformation: 'Delivery information',
  ContractLineData: 'Contract line data',
};

export const UrgReqSections = {
  AllSections: 'All Sections',
  VehicleReqs: 'Vehicle Specifications and other requirements',
  UrgentReqsJustification: 'Urgent requirement justifications',
  AgencyInformation: 'Agency information',
  DeliveryInformation: 'Delivery information',
  SelectedVendors: 'Selected vendor and funding confirmation',
  VendorAcceptance: 'Vendor acceptance',
  ContractLineData: 'Contract line data',
};

export const vehicleRequisitionInitialState = {
  requisitionType: REQUISITION_TYPE.STANDARD_ORDER,
  compareVehiclesCurrentPage: 0,
  allActiveContracts: null,
  activeEngineInfo: [],
  vehicleDataFlattened: null,
  displayingActiveContracts: null,
  minimumRequirementsFlattened: null,
  allMinimumRequirements: null,
  displayingMinRequirements: null,
  allOptionalRequirements: null,
  displayingOptionalRequirements: null,
  allVehicleOverview: null,
  displayingVehicleOverview: null,
  allVehicleInformation: null,
  displayingVehicleInformation: null,
  allCostBreakdown: null,
  displayingCostBreakdown: null,
  currentStandardItem: null,
  quantityHasError: false,
  draftRequisition: null,
  selectedContract: null,
  selectedEngine: null,
  selectedContractAgencyInformation: {},
  isInactiveContract: false,
  selectedOptionsForPrice: [],
  vehicleQuantity: '1',
  availableColors: [],
  vehicleColors: [],
  paintAndGraphicsOptions: [],
  customPaintOptionsContext: [],
  engineerTaggedOptions: [],
  taggedOptions: [],
  nonLowBidJustification: '',
  draftSelectedColors: [],
  addOptionsState: [],
  addOptionsDataWithCollisions: [],
  deliveryOptions: [],
  selectedItemsForPurchaseState: [],
  selectedContractCostBreakdown: null,
  requisitionHasError: [],
  sin: null,
  getPurchaseCollisionDataOnYes: null,
  getPurchaseCollisionInfo: null,
  draftId: null,
  updateDraftRequisition: null,
  requiredOptionSessionState: [],
  isCalculatePriceDisabled: true,
  isCalculatePriceError: false,
  calculatedPriceData: null,
  selectedCountryContext: [],
  selectedCountryStatesContext: [],
  countryCallingCodesContext: [],
  requisitionDeliveryAddress: [],
  requisitionSameAddressState: [],
  reqAddressStateContext: {},
  statesRequisitionFetchedFrmContext: [],
  statesMailingFetchedFrmContext: [],
  statesDeliveryFetchedFrmContext: [],
  uspsAddressVerificationModalState: false,
  requisitionStateContext: {
    id: 'Requisition Address',
    isValidated: false,
    country: 'US',
    selectedCountryDescription: '',
    entityName: 'Entity Name',
    addressLine1: '',
    address2: '',
    city: '',
    stateCode: '',
    zipcode: '',
    zip2: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phoneNumber: '',
    phoneExtension: '',
    faxNumber1: '',
    faxNumber2: '',
    faxNumber3: '',
    faxExtension: '',
    isMilitary: false,
    militaryOrDiplomaticAddress: '',
    rankAndFullName: '',
    postOffice: '',
  },
  reqValidatedAddress: {},
  mailingStateContext: {
    id: 'Mailing Address',
    isValidated: false,
    country: 'US',
    selectedCountryDescription: '',
    entityName: 'Entity Name',
    addressLine1: '',
    address2: '',
    city: '',
    stateCode: '',
    zipcode: '',
    zip2: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phoneNumber: '',
    phoneExtension: '',
    faxNumber1: '',
    faxNumber2: '',
    faxNumber3: '',
    faxExtension: '',
    isMailingSameAsRequisition: false,
    isMilitary: false,
    rankAndFullName: '',
    militaryOrDiplomaticAddress: '',
    postOffice: '',
  },
  mailingValidatedAddress: {},
  deliveryStateContext: {
    id: 'Delivery address',
    isValidated: false,
    country: '',
    entityName: 'Entity Name',
    selectedCountryDescription: '',
    addressLine1: '',
    address2: '',
    city: '',
    stateCode: '',
    zipcode: '',
    zip2: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phoneNumber: '',
    phoneExtension: '',
    faxNumber1: '',
    faxNumber2: '',
    faxNumber3: '',
    faxExtension: '',
    isDeliverySameAsRequisition: false,
    isMilitary: false,
  },
  deliveryValidatedAddress: {},
  validatedAddressNotFound: [],
  deliveryAddressFormSubmitted: false,
  requisitionAddressErrorContext: [],
  mailingAddressErrorContext: [],
  deliveryAddressErrorContext: [],
  addressVerificationModal: false,
  currentStep: {
    key: 'COMPARE_SELECT',
    current: 1,
    text: 'Compare and select',
  },
  submitRequisitionModal: false,
  submitRequisitionLoading: false,
  cancelRequisitionModal: false,
  optionalReqData: [],
  formErrorFocus: {
    vehicleQuantity: false,
  },
  uspsModalOptionsSelectedState: [],
  submitComment: '',
  addOptionErrors: 0,
  deliveryOptionErrors: 0,
  fileUpload: false,
  totalUploadedFiles: null,
  requisitionValidationState: null,
  isValidationErrorExist: false,
  isReqNumberModalOpen: false,
  isNotifyModal: false,
  selectedAgencyOrganizationCode: null,
  isDomesticShipment: true,
  isAreq: false,
  excludeAreqOption: false,
  agencyReferenceNumber: null,
  additionalRequirements: [],
  nonSopSelectedOption: '',
  specialDeliveryInstructions: null, // String
  deliveryDate: null, // Date
  urgentRequirementJustification: null, // String
  nonSopData: {
    vehicleType: null,
    vehicleTypeOther: null,
    driveTrain: null,
    quantity: null,
    color: null,
    fuelType: null,
    grossVehicleWeightRating: null,
    grossCombinedWeightRating: null,
    payloadCapacity: null,
    towingCapacity: null,
    towingType: null,
  },
  urgentReqErrorBanner: false,
  masReqErrorBanner: false,
  urgentReqJustification: {
    justification: '',
    certifyCheckBox: false,
    uploadedDocumentation: [],
  },
  areqVendorQuoteDetails: [
    {
      vendorId: null,
      contractLineId: null,
      vendorQuote: [],
      description: '',
      supportingDocs: [],
      priceEstimate: '',
      attachments: [],
      extendedShippingDays: 0,
    },
  ],
  areqPendingCustomerResponse: {
    lowBidJustification: '',
    certifyCheckBox: false,
    fundingDocs: [],
    areqVehicleColor: '',
  },
  areqJustification: {
    justification: '',
    certifyCheckBox: false,
    uploadedDocumentation: [],
  },
  urgentReqStepsProcessIndicator: [
    {
      key: 'VEHICLE_SPECIFICATIONS',
      label: 'Vehicle specifications',
      status: 'current',
      stepNumber: 1,
    },
    {
      key: 'URGENT_REQUIREMENT_JUSTIFICATION',
      label: 'Urgent requirement justification',
      status: 'not completed',
      stepNumber: 2,
    },
    {
      key: 'AGENCY_INFORMATION',
      label: 'Agency information',
      status: 'not completed',
      stepNumber: 3,
    },
    {
      key: 'DELIVERY_ADDRESS',
      label: 'Delivery address',
      status: 'not completed',
      stepNumber: 4,
    },
    {
      key: 'REVIEW_SUBMIT',
      label: 'Review and submit',
      status: 'not completed',
      stepNumber: 5,
    },
  ],
  urgentRequirementCurrentStep: {
    key: 'VEHICLE_SPECIFICATIONS',
    current: 1,
    text: 'Vehicle specifications',
  },
  masRequirementCurrentStep: {
    key: 'MAS_VEHICLE_REQUIREMENTS',
    current: 1,
    stepNumber: 1,
    text: 'Multiple Award Schedules (MAS) Vehicle Requirements',
  },
  masReqStepsProcessIndicator: [
    {
      key: 'MAS_VEHICLE_REQUIREMENTS',
      label: 'MAS Vehicle Requirements',
      status: 'current',
      stepNumber: 1,
    },
    {
      key: 'AGENCY_INFORMATION',
      label: 'Agency information',
      status: 'not completed',
      stepNumber: 2,
    },
    {
      key: 'DELIVERY_ADDRESS',
      label: 'Delivery address',
      status: 'not completed',
      stepNumber: 3,
    },
    {
      key: 'REVIEW_SUBMIT',
      label: 'Review and submit',
      status: 'not completed',
      stepNumber: 4,
    },
  ],
  reviewSubmitTab: 'Review summary',
  sopPrintPreview: {
    [AreqSections.OptionsPerVehicle]: true,
    [AreqSections.PaintAndGraphics]: true,
    [AreqSections.OptionsDetails]: true,
    [AreqSections.CostSummary]: true,
    [AreqSections.AgencyInformation]: true,
    [AreqSections.DeliveryInformation]: true,
  },
  areqPrintPreview: {
    [AreqSections.AreqInfo]: true,
    [AreqSections.OptionsPerVehicle]: true,
    [AreqSections.PaintAndGraphics]: true,
    [AreqSections.OptionsDetails]: true,
    [AreqSections.CostSummary]: true,
    [AreqSections.AgencyInformation]: true,
    [AreqSections.DeliveryInformation]: true,
  },
  masPrintPreview: {
    [MasSections.VehicleReqs]: true,
    // [MasSections.ContractNum]: true,
    [MasSections.AgencyInformation]: true,
    [MasSections.DeliveryInformation]: true,
    [MasSections.ContractLineData]: true,
  },
  urgReqPrintPreview: {
    [UrgReqSections.VehicleReqs]: true,
    [UrgReqSections.UrgentReqsJustification]: true,
    [UrgReqSections.AgencyInformation]: true,
    [UrgReqSections.DeliveryInformation]: true,
    [UrgReqSections.SelectedVendors]: true,
    [UrgReqSections.VendorAcceptance]: true,
    [MasSections.ContractLineData]: true,
  },
  filesUploadedList: [],
  isLeasingAdditionalRequirementsPreview: false,
  requisitionAttachmentTypes: [],
  preDraftComments: [],
  urgentRequirementPlaceOrderInitialState: {
    vendorAcceptanceAttachments: [],
    certifyCheckBox: false,
    contractNumber: '',
    scheduleLine: '',
    color: '',
    quantity: '',
  },
  masPlaceOrderInitialState: {
    contractNumber: '',
    scheduleLine: '',
    color: '',
    quantity: '',
  },
};

export default vehicleRequisitionInitialState;
